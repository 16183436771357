import "./styles.scss";

const StatsDisplay = ({ stats }) => {
  return (
    <div className="stats-container">
      {stats?.map((stat, index) => (
        <div key={index} className="stat-item">
          <div className="stat-value">{stat.value}</div>
          <div className="stat-label">{stat.label}</div>
        </div>
      ))}
    </div>
  );
};

export default StatsDisplay;
