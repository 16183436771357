import React from 'react'

const Facilities = ({ Field, ErrorMessage ,isReadOnly,organisationList }) => {
    return (
        <>
            <h6 className="text-primary"> Facility Details:</h6>
            <div className="row">
                <div className="col-lg-2 mb-2">
                    <div className="form-group mb-3">
                        <label className="text-label">Sports Supported <span className="text-danger">*</span></label>
                        <Field
                            name="sport_support"
                            className="form-control"
                            placeholder="Sports Supported"
                            disabled={isReadOnly('sport_support')}
                        />
                        {(!isReadOnly('sport_support') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                        <ErrorMessage name="sport_support" component="div" className="text-danger" />
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group mb-3">
                        <label className="text-label">Capacity</label>
                        <Field
                            name="capacity"
                            className="form-control"
                            placeholder="Capacity"
                            disabled={isReadOnly('capacity')}
                        />
                        {(!isReadOnly('capacity') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                        <ErrorMessage name="capacity" component="div" className="text-danger" />
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group mb-3">
                        <label className="text-label">Available Equipment</label>
                        <Field
                            name="available_equipment"
                            className="form-control"
                            placeholder="Available Equipment"
                            disabled={isReadOnly('available_equipment')}
                        />
                        {(!isReadOnly('available_equipment') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                        <ErrorMessage name="available_equipment" component="div" className="text-danger" />
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group mb-3">
                        <label className="text-label">Operating Hours</label>
                        <Field
                            name="operation_hour"
                            className="form-control"
                            placeholder="Operating Hours"
                            disabled={isReadOnly('operation_hour')}
                        />
                        {(!isReadOnly('operation_hour') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                        <ErrorMessage name="operation_hour" component="div" className="text-danger" />
                    </div>
                </div>



                <div className="col-lg-3 mb-3">
                    <div className="form-group mb-3">
                        <label className="text-label">Additional Services</label>
                        <Field
                            name="additional_information"
                            className="form-control"
                            placeholder="Additional Services"
                            disabled={isReadOnly('additional_services')}
                        />
                        {(!isReadOnly('additional_services') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                        <ErrorMessage name="additional_information" component="div" className="text-danger" />
                    </div>
                </div>

            </div>


            <h6 className="text-primary"> Affiliations and Certifications:</h6>
            <div className="row">
            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label">Affiliated Organizations</label>
                    <Field
                     name="affiliated_sport_body"
                     className="form-control"
                     placeholder="Affiliated Organizations"
                     disabled={isReadOnly('affiliated_sport_body')}
                  />
                  {(!isReadOnly('affiliated_sport_body') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="affiliated_sport_body" component="div" className="text-danger" />
                </div>
            </div>
                <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label">Achievements</label>
                    <Field
                     name="achievements"
                     className="form-control"
                     placeholder="Achievements"
                     disabled={isReadOnly('achievements')}
                  />
                  {(!isReadOnly('achievements') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="achievements" component="div" className="text-danger" />
                </div>
            </div>























            </div>
        </>
    )
}

export default Facilities