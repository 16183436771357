import "./styles.scss";
import GridImages from "../../components/GridImages";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  return (
    <div>
      <div className="banner-video ">
        <video
          autoPlay
          loop
          muted
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        >
          <source src="/assets/video/Jharkhand_video.mp4" type="video/mp4" />
        </video>

        <div className="home-banner">
          <div className="container  center_title home_banner_height">
            <div className="row justify-content-center align-items-center mt-5 ">
              <div className="col-sm-12 col-md-7 col-lg-7 ">
                <h2
                  className="banner-header text-white "
                  style={{ fontFamily: "Koulen" }}
                >
                  Sports Authority
                  <br /> of Jharkhand
                </h2>
                <div className="header-btn d-flex justify-content-center align-items-center py-3">
                  {/* <ActionButton label="WATCH NOW" color="#ffffff" /> */}
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 banner-right">
                <h2 className="form_home_banner_text ">
                  From Roots to <br /> Records!
                </h2>
                <p className="form_home_banner_para">
                  SAJHA is responsible for formulating sports policies,
                  developing infrastructure, conducting training programs,
                  hosting events, identifying talent, supporting athletes
                  financially and medically, and promoting a culture of sports
                  participation across Jharkhand to raise the level of sports in
                  the state.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GridImages />
    </div>
  );
};

export default Home;
