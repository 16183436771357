import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import RegisterAs from "../../../../components/VerifyDetails/RegisterAs";
import { updateDetailsAtEveryStep } from "../../../../config/registrationConfigUrl";
import LeftRegistration from "../../../../components/LeftRegistration";
import Atom from "../../AtomComponent/ATOM/index";
import AadharVerification from "../../RegisterWithAadhar/index";
import VerifyContact from "../../VerifyContactDetails/index";
import BankDetails from "../../BankComponent/BankDetails/index";
import EducationDetails from "../../EducationComponent/EducationDetails/index";
import SportDetails from "../../sportDetailsComponent/SportDetails/index";
import PersonalDetails from "../../PersonalDetailsComponent/PersonalDetails/index";
import ViewFormDetails from "../../ViewFormDetails";
import { Col, Container, Row } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { get_Registration_Listing } from "../../../../config/registrationConfigUrl";

// console.log("component called");

function RegisterAsAthlete() {
  let navigate = useNavigate();
  let [viewFormPage, setViewFormPage] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [showSubmit, seShowSubmit] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = async () => {
    setShow(true);
  };

  const handleSubmit = async () => {
    await handleNext();
    localStorage.setItem("status", true);
    navigate("/final-submit_individual_registration", { replace: true });
  };

  //
  // const navigate = useNavigate();

  const {
    currentStep,
    setCurrentStep,
    userData,
    setUserData,
    userDocuments,
    setUploadedDocuments,
    uploadedDocuments,
    handleNext,
    handleBack,
    db_step_regestration,
    isPasswordSet,
  } = useContext(VerticalStepperContext);

  const user = JSON.parse(localStorage.getItem("user")) || {};
  let currentStp = JSON.parse(localStorage.getItem("currentStep")) || {};

  const [athleteData, setAthleteData] = useState({
    user_category_main: 0,
  });

  const handleSave = async () => {
    try {
      let response = await updateDetailsAtEveryStep({
        id: user?.id,
        account_holder_name: userData.account_holder_name,
        bank_account_number: userData.bank_account_number,
        bank_name: userData.bank_name,
        branch_name: userData.branch_name,
        ifsc_code: userData.ifsc_code,
        steps: 6,
      });
      if (response?.data?.status === true) {
        // console.log(response, "---")
        seShowSubmit(false);
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
      } else {
        if (response?.data?.message) {
          console.log("error<<>>", response?.data?.error.message);
          Swal.fire("Oops", `${response?.data?.error.message[0]}`, "error");
        } else {
          console.log("error", response?.data?.error.message);
          Swal.fire("Oops", `${response?.data?.error.message[0]}`, "error");
        }
      }
    } catch (error) {
      console.log("error<<>>", error?.data?.error?.message);
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    }
  };

  useEffect(() => {
    console.log(db_step_regestration, "userDatafromUseeffect==>");
    const checkIfButtonShouldBeDisabled = () => {
      switch (currentStep.level) {
        case 3:
          return !userData?.orgnization_id;
        case 4:
          return (
            !userData?.first_name ||
            !userData?.gender ||
            !userData?.country ||
            !userDocuments?.profile_image ||
            !userData?.landmark ||
            // !(userData.password || Number(db_step_regestration) >= 4) ||
            (userData?.status == "ongoing" &&
              !(
                userData?.password == userData?.confirmPassword ||
                Number(db_step_regestration) >= 4
              )) ||
            (userData?.status == "ongoing" && !isPasswordSet &&
              !(userData?.password == userData?.confirmPassword)) ||
            (userData?.status == "ongoing" && !isPasswordSet && !userData?.password) ||
            (userData?.status == "ongoing" &&
              !(
                userData?.password == userData?.confirmPassword ||
                Number(db_step_regestration) >= 4
              )) ||
            !userData.dob ||
            !userData.address
          );
        case 5:
          return (
            !userData?.sport_id ||
            !userData?.kit_size ||
            !userData?.athlete_level
          );
        case 6:
          // console.log("case 6 not updating ", userDocuments)
          return !(
            userDocuments.other ||
            userDocuments.tenth ||
            userDocuments.twelth ||
            userDocuments.ug ||
            userDocuments.pg ||
            userDocuments.phd
          );
        case 7:
          return (
            !userData?.bank_name ||
            !userData?.account_holder_name ||
            !userData?.bank_account_number ||
            !userData?.ifsc_code ||
            !userData?.branch_name ||
            !userDocuments?.cancelled_passbook_cheque_upload
          );

        default:
          return false;
      }
    };

    const disabled = checkIfButtonShouldBeDisabled();
    setIsDisabled(disabled);
  }, [
    currentStep,
    userData,
    userData.address,
    userDocuments,
    db_step_regestration,
    isPasswordSet
  ]);

  const buttonText = currentStep.level !== 7 ? "Continue" : "Submit";
  const buttonOnClick = currentStep.level !== 7 ? handleNext : onSubmit;

  return (
    <Container fluid>
      {show && (
        <>
          {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you really want to submit the Details ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal> */}

          <Modal show={show}>
            <Modal.Body className="modalBox">
              <div className="modalText">Do you really want to submit the Details ?</div>
              <div className="buttonDiv">
                <Button className="noBtn" onClick={handleClose}>
                  No
                </Button>
                <Button className="yesBtn" onClick={handleSubmit}>
                  Yes
                </Button>
              </div>
            </Modal.Body>

          </Modal>

        </>
      )}
      <Row>
        <Col lg={3} className=" registration-container border">
          <LeftRegistration />
        </Col>

        <Col lg={9} className="register-as-page-right-container">
          <div>
            {currentStep.level === 0 && <AadharVerification />}
            {currentStep.level === 1 && <VerifyContact />}
            {currentStep.level === 2 && <RegisterAs />}
            {currentStep.level === 3 && <Atom />}
            {currentStep.level === 4 && <PersonalDetails />}
            {currentStep.level === 5 && <SportDetails />}
            {currentStep.level === 6 && <EducationDetails />}
            {currentStep.level === 7 && !viewFormPage && <BankDetails />}
            {currentStep.level === 7 && viewFormPage && <ViewFormDetails />}
          </div>
          <div className="backAndnext-Btn">
            <div className="back-btn">
              {currentStep.level !== 0 && currentStep.level !== 2 && (
                <button
                  className="previous_btn"
                  onClick={
                    viewFormPage ? () => setViewFormPage(false) : handleBack
                  }
                  disabled={
                    userData.status === "ask_for_resubmit" &&
                    currentStep.level === 3
                  }
                >
                  <span>
                    <FiChevronLeft />
                  </span>
                  Previous{" "}
                </button>
              )}
              {currentStep.level === 7 && !viewFormPage && (
                <button
                  className="previous_btn viewForm-btn"
                  onClick={() => {
                    console.log("setviewfor to True", viewFormPage);
                    setViewFormPage(true);
                  }}
                  disabled={showSubmit}
                >
                  {/* <span>
                    <FiChevronLeft />
                  </span> */}
                  View Form{" "}
                </button>
              )}
            </div>

            <div className="next-btn">
              {currentStep.level == 7 && (
                <button
                  className="Continue_btn save-btn"
                  onClick={handleSave}
                  disabled={isDisabled}
                >
                  Save
                  {/* <span>
                    <FiChevronRight />
                  </span> */}
                </button>
              )}
              <>
                {currentStep.level !== 7 ? (
                  <button
                    className="Continue_btn"
                    onClick={handleNext}
                    disabled={isDisabled}
                  >
                    Continue
                    <span>
                      <FiChevronRight />
                    </span>
                  </button>
                ) : (
                  <button
                    className="Continue_btn"
                    onClick={onSubmit}
                    disabled={showSubmit}
                  >
                    Submit
                    {/* <span>
                      <FiChevronRight />
                    </span> */}
                  </button>
                )}
              </>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterAsAthlete;
