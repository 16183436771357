import Aboutus from "../../asserts/Home/grid_images/m1.png";
import mascot_bg from "../../asserts/Home/grid_images/m2.png";
import Infrastructure from "../../asserts/Home/grid_images/Infrastructure.svg";
import latest from "../../asserts/Home/grid_images/latest.png";
import ThirdGroup2 from "../../asserts/Home/grid_images/mandir1.svg";
import AthleteGirl from "../../asserts/Home/grid_images/AthleteGirl.png";
import Person from "../../asserts/Home/grid_images/iiimage 1.png";
import calenderBg from "../../asserts/Home/grid_images/calender-bg.svg";
import Contactus from "../../asserts/Home/grid_images/contact-us.svg";
import budgetBg from "../../asserts/Home/grid_images/budget-bg.png";
import grievanceBg from "../../asserts/Home/grid_images/grievance_bg.svg";
import ArrowIcon from "../../asserts/Arrow-Icon.webp";
import ActionButton from "../ActionButton";
import "../GridImages/styles.scss";
import { useNavigate } from "react-router-dom";
import VideoButton from "../../asserts/video/Group 1236.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { getAllWebsiteNotification } from "../../services/notificationForWebsite/notificationForWebsite";

interface DataType {
  id: number; // Assuming you have an id or unique key
  title: string;
  message: string;
  attachment_url: string;
  created_at: string;
}

const GridImages = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [displayedData, setDisplayedData] = useState<DataType[]>([]);

  const updateDisplayedData = () => {
    setDisplayedData((prevData) => {
      const newData = [...prevData];
      // Remove the first item and add it to the end
      const firstItem = newData.shift();
      if (firstItem) newData.push(firstItem);
      return newData;
    });
  };

  React.useEffect(() => {
    if (data?.length > 0) {
      setDisplayedData(data);
      const interval = setInterval(updateDisplayedData, 3000);
      return () => clearInterval(interval);
    }
  }, [data]);

  const content1 = [
    {
      heading: "<b>ABOUT</b>",
      description:
        "Comprehensive Overview of Sports Development Initiatives in Jharkhand",
      backgroundImage: Aboutus,
      image: Person,
      link: "/aboutUs",
      label: "LEARN MORE",
    },
    {
      heading: "<b> NOTIFICATIONS </b> ",
      miniheading: ` <h4 style="padding-top: 10px;"  > <b> E-Tender  </b> </h4> 
      <h6> <b> 20/05/23 </b> </h6> `,
      description:
        "RFP for Establishment of High Performance Sports Science Centre in Ranchi",
      backgroundImage: mascot_bg,
      link: "/website-notification",
      label: "VIEW MORE",
    },
  ];

  const navigate = useNavigate();

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // fetch websites notification

  async function fetchData(formData = {}) {
    try {
      let res = await getAllWebsiteNotification(formData);
      setData(() => {
        return res?.data?.data?.data?.splice(0, 3);
      });
    } catch (error) {
      console.log(error);
    }
  }
  //
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="grid_green_bg ">
      <div className="header-scroll">
        <p className="scroll_text py-4">Vibrant Jharkhand : Scroll to Unveil</p>

        <KeyboardDoubleArrowDownIcon
          className="bounce-icon"
          style={{ backgroundColor: "transparent", border: "none" }}
        />
      </div>

      <div className=" py-4">
        <div className="text-container">
          <div className="two-card">
            {/* --------------- */}

            <div
              className="sec-section-1 card-hover card-less-hover mb-2 aboutus"
              style={{ backgroundImage: `url(${content1[0].backgroundImage})` }}
            >
              <Link to="/aboutUs" className="link-style">
                <div className="people-sec">
                  <div className="text-section-1">
                    <h1>
                      <b> ABOUT </b>
                    </h1>
                    {content1[0]?.miniheading && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content1[0].miniheading,
                        }}
                      />
                    )}
                    <p
                      style={{ fontWeight: "500", width: "100%" }}
                      className="para"
                    >
                      {" "}
                      <b> {content1[0]?.description}</b>
                    </p>
                    <ActionButton
                      label={content1[0].label}
                      onClick={() => navigate(`${content1[0]?.link}`)}
                    />
                  </div>

                  <div className="person-1">
                    <img src={content1[0]?.image} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            {/* --------------- */}

            <div
              className="sec-section-1 card-hover card-less-hover mb-2 flexsecond-card "
              style={{ backgroundColor: "#fff" }}
            >
              <div className="people-sec">
                <div className="text-section-1 position-relative">
                  <div className="d-flex flex-column" style={{ gap: "1rem" }}>
                    {displayedData.map((ele) => {
                      return (
                        <div>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html:
                                ele?.title && ele?.title?.length < 10
                                  ? ele?.title
                                  : ele?.title?.substring(0, 30) + "...",
                            }}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: new Date(ele?.created_at).toLocaleString(
                                "en-us",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              ),
                            }}
                          />

                          <p style={{ fontWeight: "500", width: "70%" }}>
                            {ele?.message}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className="btn btn-primary viewMore-btn"
                    onClick={() => navigate(content1[1]?.link)}
                  >
                    {content1[1]?.label || "View More"}
                  </button>
                </div>
                <div className="person-1">
                  <img src={content1[1]?.image} alt="" />

                  {/* <table className="table notification-table">
                    <thead>
                      <tr className="py-2">
                        <th scope="col">S.No</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">MESSAGE</th>
                        <th scope="col">Attachment</th>
                      </tr>
                    </thead>

                    <tbody>
                      {displayedData.map((ele, index) => (
                        <tr key={ele.id} className="">
                          <th className="align-middle" scope="row">
                            {index + 1}
                          </th>
                          <td>{ele.title}</td>
                          <td>{ele.message}</td>
                          <td>
                            <img
                              onClick={() =>
                                window.open(ele?.attachment_url, "_blank")
                              }
                              src={ele.attachment_url}
                              alt={ele.title}
                              width={40}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
            {/* --------------- */}
          </div>
        </div>

        <div className="popup py-1 text-container">
          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-3 px-lg-0 ">
              <Link to={"/latest-news"} className="link-style">
                <div className="boxcard card-hover px-0">
                  <div className="card-img">
                    <img src={latest} alt="" />
                  </div>

                  <div className="text-section-1 extra">
                    <h3 className="text-white home_gallery_head">LATEST</h3>
                    <p className="text-white">
                      Cultural Icons, Sporting Titans: Jharkhand's Path to
                      Greatness!
                    </p>
                    <div className="bottom-text d-flex justify-content-between">
                      <div>
                        <p className="text-white">VIEW MORE</p>
                      </div>
                      <div className="arrow">
                        <img
                          className="arrowIcon me-3"
                          src={ArrowIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-10 col-md-10 col-lg-6 px-lg-0 "
              style={{ position: "relative", padding: "0px" }}
            >
              <video
                autoPlay
                loop
                muted
                style={{
                  width: "100%",
                  height: "320px",
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
                className="card-hover"
              >
                <source
                  src="/assets/video/Jharkhand_video.mp4"
                  type="video/mp4"
                />
              </video>
              <img
                src={VideoButton}
                alt="Button"
                style={{
                  position: "absolute",
                  bottom: "6%",
                  right: "4%",
                  margin: "10px", // Adjust margin as needed
                  cursor: "pointer",
                  width: "6%",
                }}
                onClick={() => {
                  // Handle button click event
                  setModalIsOpen(true);
                }}
              />
            </div>
            <div className="col-10 col-md-10 col-lg-3 px-lg-0">
              <Link to={"/infrastructure"} className="link-style">
                <div className="boxcard card-hover">
                  <div className="card-img">
                    <img src={Infrastructure} alt="" />
                  </div>
                  <div className="text-section-1 extra">
                    <h3 className="text-white home_gallery_head">
                      INFRASTRUCTURE
                    </h3>
                    <p className="text-white">
                      Play the Jharkhand Way: Where Sports and Spirit Converge.
                    </p>
                    <div className="bottom-text d-flex justify-content-between">
                      <div>
                        <p className=" view-more">VIEW MORE</p>
                      </div>
                      <div>
                        <img
                          className="arrowIcon me-3"
                          src={ArrowIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="text-container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6" style={{ paddingTop: "0px" }}>
              <Link to={"/athletes"} className="link-style">
                <div className="tom-img card-hover card-less-hover">
                  <div className="people-sec">
                    <div className="text-section-1">
                      <h1>
                        <b>ATHLETES</b>{" "}
                      </h1>
                      <p>
                        Elite athlete profiles, performance data, awards, and
                        future events
                      </p>
                      <ActionButton label="LEARN MORE" />
                    </div>
                    <div className="" style={{ padding: "0px" }}>
                      <img
                        src={AthleteGirl}
                        style={{ width: "280px" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3" style={{ padding: "0px" }}>
              <Link to={"/calendar"} className="link-style">
                <div className="boxcard card-hover">
                  <div className="card-img">
                    <img src={calenderBg} alt="" />
                  </div>
                  <div className="text-section-1 extra">
                    <h3 className="text-white home_gallery_head">CALENDER</h3>
                    <p className="text-white">
                      Jharkhand  Sports: From Fields
                      <br /> to Glory
                    </p>
                    <div className="bottom-text d-flex justify-content-between">
                      <div>
                        <Link to={"/calender"} className="link-style">
                          <p className="view-more">VIEW MORE</p>
                        </Link>
                      </div>
                      <div className="me-3">
                        <img className="arrowIcon" src={ArrowIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3" style={{ padding: "0px" }}>
              <Link to={"/comingsoon"} className="link-style">
                <div className="boxcard card-hover">
                  <div className="card-img">
                    <img src={ThirdGroup2} alt="" />
                  </div>
                  <div className="text-section-1 extra">
                    <h3 className="text-white home_gallery_head">SCHEMES</h3>
                    <p className="text-white">
                      Pinnacle of Performance: Jharkhand's Soars.
                    </p>
                    <div className="bottom-text d-flex justify-content-between">
                      <div>
                        <p className="view-more">VIEW MORE</p>
                      </div>
                      <div>
                        <img
                          className="arrowIcon me-3"
                          src={ArrowIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="py-1 text-container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-3" style={{ padding: "0px" }}>
              <Link to={"/comingsoon"} className="link-style">
                <div className="boxcard card-hover">
                  <div className="card-img">
                    <img src={budgetBg} alt="" />
                  </div>
                  <div className="text-section-1 extra">
                    <h3 className="text-white home_gallery_head">BUDGET</h3>
                    <p className="text-white">
                      Echoes of Culture, Sparks of Excellence: Jharkhand
                      SportsUnveiled!
                    </p>
                    <div className="bottom-text d-flex justify-content-between">
                      <div>
                        <p className="view-more">VIEW MORE</p>
                      </div>
                      <div>
                        <img
                          className="arrowIcon me-3"
                          src={ArrowIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3" style={{ padding: "0px" }}>
              <Link to={"/comingsoon"} className="link-style">
                <div className="boxcard card-hover">
                  <div className="card-img">
                    <img src={grievanceBg} alt="" />
                  </div>
                  <div className="text-section-1 extra">
                    <h3 className="text-white home_gallery_head">GRIEVANCE</h3>
                    <p className="text-white">
                      Crafting Icons, Honouring Roots: Jharkhand Sports
                      Chronicles!"
                    </p>
                    <div className="bottom-text d-flex justify-content-between">
                      <div>
                        <p className="view-more">VIEW MORE</p>
                      </div>
                      <div>
                        <img
                          className="arrowIcon me-3"
                          src={ArrowIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6" style={{ paddingTop: "0px" }}>
              <Link to={"/contact-us"} className="link-style">
                <div className="girl-bg card-hover card-less-hover">
                  <div className="people-sec">
                    <div className="text-section-1">
                      <h1>
                        CONTACT <b>US</b>
                      </h1>
                      <p>
                        Comprehensive Overview of Sports Development Initiatives
                        in Jharkhand
                      </p>
                      <ActionButton label="LEARN MORE" />
                    </div>
                    <div className="">
                      <img src={Contactus} alt="" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {modalIsOpen && (
        <div className="popup-overlay">
          <div className="popup-container">
            <button className="close-button" onClick={closeModal}>
              Close X
            </button>
            <video
              autoPlay
              loop
              muted
              controls
              style={{
                width: "100%",

                objectFit: "fill",
              }}
              className=""
            >
              <source
                src="/assets/video/Jharkhand_video.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridImages;
