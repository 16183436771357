import React from "react";
import Header from "../../components/Header";
import "./styles.scss";
import runningImg from "../../asserts/NewsDetails/runningBg.png";
import templeBG from "../../asserts/NewsDetails/TempleBg.png";
import twitter from "../../asserts/NewsDetails/Icon awesome-twitter-square.png"
import facebook from "../../asserts/NewsDetails/Icon metro-facebook.png";
import link from "../../asserts/NewsDetails/Icon awesome-link.png"

const newsData = [
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
];

const NewsDetails = () => {
  return (
    <div>
      <div className="linear-gradient"></div>
      <div className="news-details-banner">
        <Header />
        <div className="container">
          <div className="heading-text text-center">
            <p className="text-white">NEWS DETAILS</p>
          </div>
        </div>
      </div>
      <div className="news-details-container">
        <div className="left-section-News">
          <div className="news-room-image">
            <p> Uttarakhand </p>
            <h1>NEWS ROOM</h1>
          </div>
          <div className="news-list-container">
            <div className="heading">
              {" "}
              <h3> Previous New Stories </h3>{" "}
            </div>
            <div className="news-list">
              {newsData.map((item, index) => {
                return (
                  <div item={index} className="news-list-card">
                    <div className="img-container">
                      <img src={item?.image} alt="" />
                    </div>
                    <div className="card-text-container">
                      <p className="button-news"> NEWS </p>
                      <p>
                        {" "}
                        <b> {item?.heading} </b>{" "}
                      </p>
                      <p className="card-date-news-details"> {item?.date} </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="right-section-News">
          <div>
            <img src={templeBG} width="700" />
          </div>
          <div className="right-news-detail-container">
              <span>NEWS</span>
              <h3>Efforts on to introduce 4% quota for players in govt jobs: UK Sports Minister</h3>
              <p className="date">MON 19 JUNE 2023</p>
              <p>Under Chief Minister Pushkar Singh Dhami's leadership, the Uttarakhand government endeavors to implement a 4% horizontal reservation for athletes in government jobs. Emphasizing sports alongside education, initiatives aim to instill discipline and stress relief through athletic engagement. Recognizing sports as more than entertainment, career avenues are being opened, supported by various schemes for aspiring sportspersons</p>
              <div className='article-details'>
                <div className="article-left">Enjoy this article? Why not share…</div>
                <div><img src={twitter} width='20' /></div>
                <div><img src={facebook} width='20' /></div>
                <div><img src={link} width='20' /></div>
              </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
