import React, { useRef, useState } from "react";
import "./styles.scss";

function Index() {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  console.log("isFocused",isFocused);

  const handleFocus = () => {
    setIsFocused(!isFocused);
  };

  const handleBlur = () => {
    setIsFocused(!isFocused);
  };
  return (
    <div className={`${isFocused ? "search_container_active":"search_container"}`}>
      <form
        action=""
        className={`search ${isFocused ? "search--expanded" : ""}`}
      >
        <input
          className="search__input"
          type="search"
          placeholder="Search"
          id="searchInput"
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div className="search__icon-container">
          <label
            htmlFor="searchInput"
            className="search__label"
            aria-label="Search"
          >
            <svg viewBox="0 0 1000 1000" title="Search">
              <path
                fill="currentColor"
                d="M408 745a337 337 0 1 0 0-674 337 337 0 0 0 0 674zm239-19a396 396 0 0 1-239 80 398 398 0 1 1 319-159l247 248a56 56 0 0 1 0 79 56 56 0 0 1-79 0L647 726z"
              />
            </svg>
          </label>
          {/* <button className="search__submit" aria-label="Search">
            <svg viewBox="0 0 1000 1000" title="Search">
              <path
                fill="currentColor"
                d="M408 745a337 337 0 1 0 0-674 337 337 0 0 0 0 674zm239-19a396 396 0 0 1-239 80 398 398 0 1 1 319-159l247 248a56 56 0 0 1 0 79 56 56 0 0 1-79 0L647 726z"
              />
            </svg>
          </button> */}
        </div>
      </form>
    </div>
  );
}

export default Index;
