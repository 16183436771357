import React from 'react'

const Academy = ({ Field, ErrorMessage,isReadOnly,organisationList }) => {
   return (
      <>
         <h6 className="text-primary">Training and Facilities:</h6>
       

         <div className="col-lg-3 mb-3">
            <div className="form-group mb-3">
               <label className="text-label">Training Programs Offered</label>
               <Field
                     name="training_program_offer"
                     className="form-control"
                     placeholder="Training Programs Offered"
                     disabled={isReadOnly('program_offered')}
                  />
                  {(!isReadOnly('program_offered') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="training_program_offer" component="div" className="text-danger" />
               
            </div>
         </div>

         <div className="col-lg-3 mb-3">
            <div className="form-group mb-3">
               <label className="text-label">Facilities Available</label>
               <Field
                     name="facilities"
                     className="form-control"
                     placeholder="Facilities Available"
                     disabled={isReadOnly('facilities')}
                  />
                  {(!isReadOnly('facilities') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="facilities" component="div" className="text-danger" />
            </div>
         </div>

         <div className="col-lg-3 mb-3">
            <div className="form-group mb-3">
               <label className="text-label">Achievements</label>
               <Field
                     name="achievements"
                     className="form-control"
                     placeholder="Achievements"
                     disabled={isReadOnly('achievements')}
                  />
                  {(!isReadOnly('achievements') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="achievements" component="div" className="text-danger" />
            </div>
         </div>

         <div className="col-lg-3 mb-3">
            <div className="form-group mb-3">
               <label className="text-label">Affiliated Organization</label>
               <Field
                     name="affiliated_sport_body"
                     className="form-control"
                     placeholder="Affiliated Organizations"
                     disabled={isReadOnly('affiliated_sport_body')}
                  />
                  {(!isReadOnly('affiliated_sport_body') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                  <ErrorMessage name="affiliated_sport_body" component="div" className="text-danger" />
            </div>
         </div>

      </>
   )
}

export default Academy