import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import logo from "../../asserts/Header/Jharkhand Sports Directorate logo_white 1.png";
import jharkhand_logo from "../../asserts/Registration/jk_logo.png";
import VerticalStepper from "../VerticalStepper";

import { LeftStepperValues } from "./json";
import Swal from "sweetalert2";
import { VerticalStepperContext } from "../../Context/VerticalStepperCount/VerticalStepper";
import { LoadingContext } from "../../Context/Loading/LoadingContext";

function LeftRegistration() {
  const {
    currentStep,
    setCurrentStep,
    userData,
    setUserData,
    uploadedDocuments,
    setUploadedDocuments,
    handleBack,
    handleNext,
    setIsPasswordSet,
    isPasswordSet
  } = useContext(VerticalStepperContext);
  const { loading, setLoading } = useContext(LoadingContext);

  // console.log(userData, "leftReg=>");

  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  let loginDetails = JSON.parse(localStorage.getItem("login_Details"));

  const isSupportStaffRegistration =
    location.pathname === "/register_as_support_staff";
  const isOriginalRegistration = location.pathname === "/registration";
  const isAthleteRegistration = location.pathname === "/register_as_athlete";
  const isTechnicalOfficialRegistration =
    location.pathname === "/register_as_technical_official";
  const isRegisterAs = location.pathname === "/register_as";

  // alert(`${userData?.steps}`)
  useEffect(() => {
    if (
      userData?.steps >= 5 &&
      (location.pathname == "/register_as_technical_official" ||
        location.pathname == "/register_as_support_staff") && !isPasswordSet
    ) {
      setIsPasswordSet(true);
    } else if (
      userData?.steps >= 4 &&
      location.pathname === "/register_as_athlete" && !isPasswordSet
    ) {
      setIsPasswordSet(true);
    }
  }, [userData?.steps]);

  useEffect(() => {
    if (!token) {
      setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
      navigate("/registration", { replace: true });
      localStorage.removeItem("currentStep");
      localStorage.removeItem("bankDetails");
      localStorage.removeItem("login_Details");
      localStorage.removeItem("access_token");
    } else if (loginDetails) {
      if (loginDetails?.type !== "individual") {
        setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
        navigate("/registration", { replace: true });
        localStorage.removeItem("currentStep");
        localStorage.removeItem("bankDetails");
        localStorage.removeItem("login_Details");
        localStorage.removeItem("access_token");
      }
    }
  }, []);

  // console.log(loginDetails, "login-details")-+

  useEffect(() => {
    const newValue =
      location.pathname === "/register_as_support_staff"
        ? "register_as_support_staff"
        : location.pathname === "/registration"
        ? "registration"
        : location.pathname === "/register_as_athlete"
        ? "register_as_athlete"
        : location.pathname === "/register_as_technical_official"
        ? "register_as_technical_official"
        : location.pathname === "/register_as"
        ? "register_as"
        : "common";

    // console.log(newValue, "newValu")
    // setCurrentStep((prevStep) => ({
    //   ...prevStep,

    //   value: newValue,
    // }));
    if (newValue == "register_as_support_staff") {
      setCurrentStep((prevStep) => ({
        ...prevStep,
        userType: "Support Staff",
        value: "register_as_support_staff",
      }));
    } else if (newValue == "register_as_athlete") {
      setCurrentStep((prevStep) => ({
        ...prevStep,
        userType: "Athlete",
        value: "register_as_athlete",
      }));
    } else if (newValue == "register_as_technical_official") {
      setCurrentStep((prevStep) => ({
        ...prevStep,
        userType: "Technical Official",
        value: "register_as_technical_official",
      }));
    }
  }, [currentStep.level]);

  useEffect(() => {
    if (
      (window.location.pathname == "/register_as_support_staff" ||
        window.location.pathname == "/register_as_athlete" ||
        window.location.pathname == "/register_as_technical_official") &&
      currentStep.level < 2
    ) {
      setCurrentStep({ ...currentStep, level: 2 });
    } else if (window.location.pathname == "/registration") {
      let currentStep = JSON.parse(localStorage.getItem("currentStep"));
      if (currentStep) {
        window.location.href = `/${currentStep?.value}` || "/registration";
      }
      // setCurrentStep({...currentStep, level: 0, isRegistered: false})
    } else if (window.location.pathname == "/register_as" && !loginDetails) {
      localStorage.setItem(
        "currentStep",
        JSON.stringify({
          level: 2,
          isRegistered: true,
          userType: "register_as",
          value: "register_as",
        })
      );
      setCurrentStep({
        level: 2,
        isRegistered: true,
        userType: "register_as",
        value: "register_as",
      });
    } else if (
      (window.location.pathname == "/register_as_support_staff" &&
        currentStep.level > 6) ||
      (window.location.pathname == "/register_as_technical_official" &&
        currentStep.level > 6)
    ) {
      if (currentStep) {
        let currentStepData = JSON.parse(localStorage.getItem("currentStep"));
        currentStepData = { ...currentStepData, level: 6 };
        setCurrentStep(currentStepData);
        localStorage.setItem("currentStep", JSON.stringify(currentStepData));
      }
    }
  }, [currentStep.level]);

  useEffect(() => {
    if (token) {
      let currentStp = JSON.parse(localStorage.getItem("currentStep"));
      // console.log(' cstp ',  currentStep , currentStp )
      // setCurrentStep({ ...currentStep, currentStp });
    } else {
      // alert("deleted page")
      localStorage.removeItem("user");
      localStorage.removeItem("currentStep");
      // localStorage.removeItem("bankDetails");
      // let currentStep = JSON.parse(localStorage.getItem("currentStep"))

      // console.log(currentStep)
      // currentStep.isRegistered = false;

      // localStorage.setItem("currentStep", JSON.stringify(currentStep));
    }
  }, []);

  useEffect(() => {
    if (userData.status === "final_submit") {
      localStorage.removeItem("currentStep");
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("login_Details");
      localStorage.removeItem("bankDetails");
      navigate("/", { replace: true });
    }
  }, [userData.status, currentStep]);

  const handleLogOut = () => {
    localStorage.removeItem("currentStep");
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("login_Details");
    localStorage.removeItem("bankDetails");
    localStorage.removeItem("status");
    navigate("/login_email", { replace: true });
  };

  const handleLoginClick = () => {
    navigate("/login_email", { replace: true });
  };

  // useEffect(() => {
  //   const handlePopState = () => {
  //     const confirmMessage = "Please Logout to go back.";

  //     alert(confirmMessage);

  //     window.history.pushState(null, "", window.location.pathname);
  //   };
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", () => handlePopState);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handlePopState = () => navigate("/");
  //   window.addEventListener("popstate", handlePopState);
  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  return (
    <div className="py-4">
      <div className="d-flex flex-center">
        <img width="93" src={logo} alt="" className="" />
        <img width="85" src={jharkhand_logo} alt="" className="mx-2" />
      </div>
      <div className="d-flex flex-row-fluid justify-content-center p-10 py-4">
        <div className="stepper-nav">
          {isSupportStaffRegistration ? (
            <VerticalStepper
              values={LeftStepperValues("register_as_support_staff")}
            />
          ) : isAthleteRegistration ? (
            <VerticalStepper
              values={LeftStepperValues("register_as_athlete")}
            />
          ) : isTechnicalOfficialRegistration ? (
            <VerticalStepper
              values={LeftStepperValues("register_as_technical_official")}
            />
          ) : isRegisterAs ? (
            <VerticalStepper values={LeftStepperValues("register_as")} />
          ) : (
            <VerticalStepper values={LeftStepperValues("common")} />
          )}
        </div>
      </div>

      <div>
        {currentStep.isRegistered === false ? (
          <div className="login_field_left_reg">
            <span className="already_account_title">
              Already have an account?
            </span>{" "}
            <button
              className="login_btn mx-2"
              onClick={handleLoginClick}
              disabled={loading}
            >
              Login Here
            </button>
          </div>
        ) : (
          <div className="user-name-logout">
            <div className="user_info">
              <i className="bi bi-person-circle"></i>{" "}
              <span className="user_name ms-2">
                {user?.name || userData?.first_name || "Guest User"}
              </span>
            </div>
            <div className="logout-btn">
              <i class="bi bi-box-arrow-right" onClick={handleLogOut}></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeftRegistration;
