import Header from "../../components/Header";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "../../components/SwipperSlider/index";
import flag from "../../asserts/AthleteProfile/iconimg.png";
import twitter from "../../asserts/AthleteProfile/twitter.png";
import fb from "../../asserts/AthleteProfile/facebook.png";
import insta from "../../asserts/AthleteProfile/instagram.png";
import linkdin from "../../asserts/AthleteProfile/linkedIn.png";
import message from "../../asserts/AthleteProfile/message.png";
import text from "../../asserts/AthleteProfile/Group 1261157670.png";
import rank from "../../asserts/AthleteProfile/Rank.png";

import "./styles.scss";
import OverView from "./OverView/OverView";
import Achievement from "./Achievement/Index";
import Activity from "./Activity/Index";
import Statistics from "./Statistic/Statistics";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const label = localStorage.getItem("label");
    if (label === "Profile") {
      // setValue(0);
    } else if (label === "Rankings") {
      setValue(2);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <div className="athletes_profile-contianaer">
        
        <div className="container"></div>
        <div className="slider-cont">{/* <Slider /> */}</div>
        <div className="flag-Img">
          <img src={flag} />
        </div>
        <div className="left-athlete-content">
          <h3>Salima Tete</h3>
          <p>Country - India</p>
          <p>State - Jharkhand</p>
          <p>Sports - Hockey</p>
          <p>Discipline - Field Hockey</p>
          <p>Age - 22Yr</p>
          <p>Weight - 55KG</p>
          <p>Height - 5.5</p>
          <a href="/head_to_head">
          <button>HEAD TO HEAD</button>
          </a>
          
        </div>

        <div className="social-media-handles">
          <div>
            <img src={twitter} />
          </div>
          <div>
            <img src={fb} />
          </div>
          <div>
            <img src={insta} />
          </div>
          <div>
            <img src={linkdin} />
          </div>
          <div>
            <img src={message} />
          </div>
          <div>
            <img src={text} />
          </div>
        </div>

        <div className="rank-flex">
          <div className="rankImg">
            <img src={rank} />
          </div>
          <div className="rank-number">
            <p>1</p>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#f6f7f9" }}>
        <div className="athletes-profile-tabs-section">
          <Box
            sx={{ bgcolor: "background.paper", width: 500 }}
            style={{ width: "100%" }}
          >
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                style={{ color: "black" }}
              >
                <Tab label="OVERVIEW" {...a11yProps(0)} />
                <Tab label="STATS" {...a11yProps(1)} />
                <Tab label="ACHIEVMENT'S" {...a11yProps(2)} />
                <Tab label=" ACTIVITY " {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              style={{ overflow: "hidden" }}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <OverView />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {/* Stats */}
                <Statistics />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Achievement />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <Activity />
              </TabPanel>
            </SwipeableViews>
          </Box>
        </div>
      </div>
    </div>
  );
}
