import React from "react";
import "./OverView.scss";
import data from "../data";

import { CFormSelect } from "@coreui/react";

const OverView = () => {
  return (
    <div className="overView-comp-athlete">
      <div className="main-heading">
        <div>
          <h1>Overview</h1>
        </div>
        <div className="filters">
          <CFormSelect
            options={[
              "Last 12 Month",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            className="dropdown"
          />
          <CFormSelect
            options={[
              "Category",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            className="dropdown"
          />
          <CFormSelect
            options={[
              "Seasons",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            className="dropdown"
          />
        </div>
      </div>
      <div className="biography">
        <h4>BIOGRAPHY</h4>
        <p>{data?.biography}</p>
      </div>
      <div className="major-titles">
        <h4>Major Titles</h4>
        <div className="title-card-container">
          {data?.cardData.map((card) => {
            return (
              <div className="card">
                <div className="logoContainer">
                  <img src={card?.logo} alt="" />
                </div>
                <div className="dataCotainer">
                  <p className="medal">
                    {" "}
                    <b> MEDAL :</b> {card?.medal}{" "}
                  </p>
                  <p className="eventName">
                    {" "}
                    <b> EVENT : </b> <span>{card?.event}</span>
                  </p>
                  <p className="eventName">
                    {" "}
                    <b> {card?.games} : </b> <span>{card?.year}</span>
                  </p>

                  {/* games */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="Year-of-ranking">
        <h4>Year and Ranking</h4>
        <div className="athlete-ranking-table-content">
          <div className="table2">
            <div
              className={`${`athlete-fopTableHeader`} position-relative py-4 fw-bold body-text`}
            >
              <div> YEAR </div>
              <div> COMBINED RANKING</div>
            </div>
            <div className="bodyContainer">
              {data?.medals.map((item, index) => (
                <div className="athlete-fopDivContainer" key={index}>
                 
                  <div className="m-4">{item?.year}</div>
                  <div className="m-4">{item?.medals}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverView;
