import { useForm } from "react-hook-form";
import "./styles.scss";
import sajhaImg from "../../asserts/Footer/jharkhand logo 1 (1).png";
import jk_img from "../../asserts/Footer/Jharkhand Sports Directorate logo_white 2.png";
import { Link, useNavigate } from "react-router-dom";
import LoginLeft from "../../components/LoginLeft";
import { adminloginApi } from "../../services/insitituteApiService";
import { useState } from "react";
import notify from "../../utils/notification";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
/**
 * @author KTSPL
 * @returns
 */
function Loginwithemail_V2() {
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [showPassword, setShowPassword] = useState(false);
  // const Navigate = useNavigate();
  const [backendError, setBackendError] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const { handleSubmit, register } = useForm();
  const onSubmit = (e) => console.log("data", e);
  const [token, setToken] = useState(localStorage.getItem("access_token"));

  const navigate = useNavigate();
  // const handleLoginClick = (e) => {
  //     e.preventDefault();
  // window.open("http://monitoring-dashboard.khelotech.com", "_blank");
  // };

  const userDataString = localStorage.getItem("login_Details");
  const userData = JSON.parse(userDataString);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: "", password: "" };

    if (!loginData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(loginData.email)) {
      newErrors.email = "Email must be a valid email address";
      isValid = false;
    }

    if (!loginData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (loginData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  // const loginSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!validateForm()) {
  //     return;
  //   }

  //   const data = {
  //     email: loginData?.email,
  //     password: loginData?.password,
  //   };

  //   try {
  //     const response = await adminloginApi(data);
  //     if (response?.data?.status === true) {
  //       notify("success", response.data.message);
  //       const loginDetails = response?.data;
  //       const token = response?.data?.authorisation?.token;
  //       localStorage.setItem("access_token", token);
  //       localStorage.setItem("login_Details", JSON.stringify(loginDetails));
  //       window.dispatchEvent(new Event("login"));
  //       setToken(token);
  //       const userStatus = response.data.user.status;
  //       if (
  //         (userStatus === "reject" && response.data.type === "institute") ||
  //         (userStatus === "final_submit" &&
  //           response.data.type === "institute") ||
  //         (userStatus === "pass" && response.data.type === "institute") ||
  //         (userStatus === "ask_for_resubmit" &&
  //           response.data.type === "institute")
  //       ) {
  //         navigate("/final-status", { replace: true });
  //       } else if (
  //         (userStatus === "reject" && response.data.type === "individual") ||
  //         (userStatus === "final_submit" &&
  //           response.data.type === "individual") ||
  //         (userStatus === "pass" && response.data.type === "individual") ||
  //         (userStatus === "ask_for_resubmit" &&
  //           response.data.type === "individual")
  //       ) {
  //         localStorage.setItem(
  //           "user",
  //           JSON.stringify({
  //             id: response?.data?.user?.id,
  //             name: response?.data?.user?.name,
  //           })
  //         );
  //         localStorage.setItem("status", true);
  //         navigate("/final-submit_individual_registration", { replace: true });
  //       } else if (
  //         response.data.type === "institute" &&
  //         userStatus === "initial"
  //       ) {
  //         navigate("/Individual", { replace: true });
  //       } else if (
  //         response.data.type === "individual" &&
  //         userStatus === "ongoing"
  //       ) {
  //         // console.log("LOginresponse===>", response);
  //         // console.log(
  //         //   "LOginresponse===>",
  //         //   loginDetails?.user?.user_category_main
  //         // );
  //         localStorage.setItem("access_token", token);
  //         localStorage.setItem(
  //           "user",
  //           JSON.stringify({
  //             id: response?.data?.user?.id,
  //             name: response?.data?.user?.name,
  //           })
  //         );

  //         navigate("/auth_registration", { replace: true });
  //       }
  //     } else {
  //       notify("error", response.data.message);
  //     }
  //   } catch (error) {
  //     Swal(error?.response?.data?.messagresponse?.datae, "", "danger");
  //     // setBackendError(error.response.data.error)
  // if (error.response?.data?.error) {
  //   setBackendError(error.response.data.error);
  // } else {
  //   setBackendError({ general: "An unexpected error occurred." });
  // }
  //   }
  // };

  const loginSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const data = {
      email: loginData?.email,
      password: loginData?.password,
    };

    try {
      const response = await adminloginApi(data);
      if (response?.data?.status === true) {
        notify("success", response.data.message);
        const loginDetails = response?.data;

        if (true) {
        }
        const token = response?.data?.authorisation?.token;
        localStorage.setItem("access_token", token);
        localStorage.setItem("login_Details", JSON.stringify(loginDetails));
        window.dispatchEvent(new Event("login"));
        setToken(token);
        const userStatus = response.data.user.status;

        if (
          (userStatus === "reject" && response.data.type === "institute") ||
          (userStatus === "final_submit" &&
            response.data.type === "institute") ||
          (userStatus === "pass" && response.data.type === "institute") ||
          (userStatus === "ask_for_resubmit" &&
            response.data.type === "institute")
        ) {
          navigate("/final-status", { replace: true });
        } else if (
          (userStatus === "reject" && response.data.type === "individual") ||
          (userStatus === "final_submit" &&
            response.data.type === "individual") ||
          (userStatus === "pass" && response.data.type === "individual") ||
          (userStatus === "ask_for_resubmit" &&
            response.data.type === "individual")
        ) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              id: response?.data?.user?.id,
              name: response?.data?.user?.name,
            })
          );
          localStorage.setItem("status", true);
          navigate("/final-submit_individual_registration", { replace: true });
        } else if (
          response.data.type === "institute" &&
          userStatus === "initial"
        ) {
          navigate("/Individual", { replace: true });
        } else if (
          response.data.type === "individual" &&
          userStatus === "ongoing"
        ) {
          localStorage.setItem("access_token", token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              id: response?.data?.user?.id,
              name: response?.data?.user?.name,
            })
          );
          navigate("/auth_registration", { replace: true });
        }
      } else {
        notify("error", response.data.message);
      }
    } catch (error) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Login Failed",
      //   text: error?.response?.data?.message || "An unexpected error occurred.",
      // });
      if (error.response?.data?.error) {
        setBackendError(error.response.data.error);
      } else {
        setBackendError({ general: "An unexpected error occurred." });
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="sign_in_right_container">
      <div className="loginLeft">
        <LoginLeft />
        <div className="jharkhand_logos_login">
          <div>
            <Link to="/">
              <img src={jk_img} width="80" />
            </Link>
          </div>
          <div>
            <Link to="/">
              <img src={sajhaImg} width="75" />
            </Link>
          </div>
        </div>
      </div>
      <div className="loginRight loginRightv2">
        <h1>Log In</h1>
        <p className="sub-heading px-5 mb-4">
          Enter your email and password to access your account
        </p>
        <form onSubmit={loginSubmit}>
          <div className="input_form_container">
            <input
              placeholder="Email id"
              value={loginData.email}
              id="email"
              name="email"
              onChange={handleChange}
            />
            {errors.email && (
              <div className="login_error_message">{errors.email}</div>
            )}
            {backendError.email && (
              <div className="login_error_message">{backendError.email}</div>
            )}
            {/* {backendError && <div className="login_error_message">{backendError}</div>} */}

            <div style={{ position: "relative", width: "100%" }}>
              <input
                placeholder="Password"
                value={loginData.password}
                name="password"
                id="password"
                className="w-100"
                type={showPassword ? "text" : "password"} // Toggle between text and password
                {...register("password")}
                onChange={handleChange}
                style={{ paddingRight: "2.5rem" }} // Add padding to make space for the button
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  left: "50%", // Keep the button inside the input's right edge
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: "0",
                  margin: "0",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                {/* Show the eye icon */}
              </button>
            </div>

            {errors.password && (
              <div className="login_error_message">{errors.password}</div>
            )}
            {backendError.password && (
              <div className="login_error_message">{backendError.password}</div>
            )}

            {!errors.password && !backendError.password && (
              <div className="login_error_message">{backendError}</div>
            )}
          </div>
          <div className="remember_forgot_container">
            <div className="remember_container">
              <input type="checkbox" />
              <label>Remember me</label>
            </div>
            <Link to="/forget-password" className="link-style">
              <p>Forgot Password</p>
            </Link>
          </div>
          <div className="sign_in_btn_container">
            <button className="submit-btn" type="submit">
              Login
            </button>

            <p className="or">OR</p>
            {/* <button onClick={() => navigate("/otp-verification")}>
              Login with OTP
            </button>
            <button className="login-with-adhar" type="submit">
              Login with Aadhar
            </button> */}
            <h6
              className="dont-have-account-login"
              onClick={() => navigate("/registration")}
            >
              Don't have an account ? Register Here
            </h6>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Loginwithemail_V2;
