import olympic from "../../asserts/AthleteProfile/olympicLogo.png";
import ng37 from "../../asserts/AthleteProfile/image 91.png";

let data = {
  biography: `Salima Tete, who is known for her fierce defending, hails from Jharkhand. She was the captain of the Indian junior squad that won silver medal at the 2018 Youth Olympics in Buenos Aires.Salima was a key player in India's FIH Olympic Qualifiers victory over the United States and she has established her spot in the team with notable performances in recent years. She was one of India's standout players at the Tokyo 2020 Olympics and helped her team finished fourth.Salima is also only the second player from Jharkhand to compete in the Olympics after Nikki Pradhan. She was also part of India’s successful campaign at the 2022 Women’s Asia Cup, where they finished third. The youngster also played a significant role in India's third place finish in the FIH Hockey Women's Pro League in 2021-22 and the bronze medal finish at the Commonwealth Games 2022 in Birmingham.`,

  cardData: [
    {
      logo: olympic,
      medal: "BRONZE",
      event: " Hockey Womens",
      "games": " Olympic Games",
      "year" :"4 Tokyo 2020",
    },
    {
        logo: ng37,
        medal: "GOLD",
        event: "ASIAN CHAMPIONS TROPHY 2023",
        "games": " Olympic Games",
        "year" :"2023",

      
      },
  ],
  medals:[{
    year:'2021',
    medals:'362'

  },
  {
    year:'2020',
    medals:'149'
  }
]

  
};
export default data;
