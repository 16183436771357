import "./registrationstyle.scss";
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaUpRightFromSquare } from "react-icons/fa6";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import Loader from "../../../registrationServices/registrationComponent/Loader/Loader";
import RegistrationFinalSubmitCard from "./RegistrationFinalSubmitCard";
import { get_Registration_Listing } from "../../../config/registrationConfigUrl";
import RegisterReSubmitCard from "./RegistrationReSubmit";
import RejectStatusCard from "../../../components/IndividualComponent/RegisterStatus/RejectStatusCard";
import RegistrationPassCard from "../../../components/IndividualComponent/RegisterStatus/RegistrationPassCard";
import { LoadingContext } from "../../../Context/Loading/LoadingContext";

const RegistrationIndividualStatusCard = () => {
  const [organisationList, setOrganizationList] = useState([]);
  const { loading, setLoading } = useContext(LoadingContext);
  const user = JSON.parse(localStorage.getItem("user"));

  const getRegistrationList = async () => {
    setLoading(true);
    try {
      // console.log("####user", user)
      let response = await get_Registration_Listing(user?.id);
      if (response?.data?.status === true) {
        setOrganizationList(response?.data?.data);
        setLoading(false);
      } else {
        if (response?.data?.message) {
          // console.log("error", response.data.message);
        } else {
          // console.log("error", response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
      //   Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    }
  };

  useEffect(() => {
    //info

    getRegistrationList();
  }, []);

  console.log(organisationList, "organization");

  useEffect(() => {
    console.log(organisationList, "otg==>")
    if (organisationList.status == "final_submit") {
      localStorage.setItem("status", true);
    }
  }, []);

  const handleBackHome = () => {
    window.location.href = "/";
  };

  const handleLogOut = () => {
    localStorage.removeItem("currentStep");
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("login_Details");
    localStorage.removeItem("bankDetails");
    localStorage.removeItem("status");
    window.location.href = "/";
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container className="py-5">
          <Row>
            <Col lg={12} className="py-5">
              <Card className="h-100"style={{ border:"1px solid"}}>
                {/* <Card.Header> */}
                  <div className="alertBoxheader">
                    {/* <div class="alertBoxheader"> */}
                    {/* </div> */}

                    <div className="txtAlign">
                      <button
                        type="button"
                        onClick={handleBackHome}
                        className="round-button"
                        title="Home"
                        // style={{ marginRight: "5px", borderRadius:"50%" ,width:"20px",height:"20px"}}
                      >
                        
                        
                       <i><FaHome /></i> 
                        
                        {/* <span >Home</span>  */}
                      </button>
                      <button type="button" onClick={handleLogOut} className="round-button-log" title="Logout">
                     <i style={{ width:"10px",height:"10px"}}><FaUpRightFromSquare />
                     </i> 
                      </button>
                    </div>

                    <div className="regHeader">
                   <aria-label class="nameHeader">Registration Status</aria-label>
                    </div>
                    
                  </div>
                {/* </Card.Header> */}
                <Card.Body className="py-5">
                  <Card.Text className="text-center p-5">
                    {organisationList?.status === "reject" ? (
                      <RejectStatusCard />
                    ) : organisationList?.status === "final_submit" ? (
                      <RegistrationFinalSubmitCard
                        organisationList={organisationList}
                      />
                    ) : organisationList?.status === "pass" ? (
                      <RegistrationPassCard />
                    ) : organisationList?.status === "ask_for_resubmit" ? (
                      <RegisterReSubmitCard />
                    ) : (
                      ""
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default RegistrationIndividualStatusCard;
