import "./styles.scss";
import { CFormSelect } from "@coreui/react";
import flag from "../../../asserts/AthleteProfile/india.png"
import RoundDisplay from "../../HeadtoHead/RoundDisplay";
import { Col, Container, Row } from "react-bootstrap";
import india from "../../../asserts/AthleteProfile/india.png";

let data = [
  {
    title: "J3 Mornington ",
    grade: "Grade 1",
    nation: "IND",
    flag: flag,
    city: "",
    round: "",
    player1: "",
    player2: "",
    win: "",
    lose: "",
    point1: "",
    point2: "",
  }
]

function Index() {
  return (
    <div className="activity-container">

      <div className="activity-header">
        <div>
          <CFormSelect
            options={[
              "Category",
              { label: "Senior", value: "Senior" },
              { label: "Junior", value: "Junior" },
            ]}
            style={{ padding: "7px", width: "150px", marginLeft: "10px" }}
          />
        </div>
        <div>
          <CFormSelect
            options={[
              "Seasons",
              { label: "2023", value: "2023" },
              { label: "2024", value: "2024" },
            ]}
            style={{ padding: "7px", width: "200px", marginLeft: "10px" }}
          />
        </div>
      </div>

      {/* <RoundDisplay/> */}

      <Container>

        <Row>
          <Col lg={12}>
            <h4 className="activity_heading">J3 Mornington </h4>
            <div className="round-display-main mt-2">
              <div className="team-place">
                <p>J4 jharkhand - 26 Jan to 30 Jan 2021</p>
                <p>City/Town: Uttarakhand India</p>
              </div>
              <div className="round-final-container">
                <p>Round: Final</p>
                <div className="round-final">
                  <div className="round-left">
                    <p>Mayank Joshi</p>
                    <div className="country">
                      <img src={india} />
                      <p>Ind</p>
                    </div>
                    <p>W</p>
                  </div>
                  <p className="score score-alignment">6-7</p>
                  <p className="score-alignment">4-6</p>
                  <p className="score score-alignment">2-6</p>
                  <div className="round-right">
                    <p>L</p>
                    <div className="country">
                      <p>Ind</p>
                      <img src={india} />
                    </div>
                    <p>Mayank Joshi</p>
                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={12}>
            <h4 className="activity_heading">J3 Mornington </h4>
            <div className="round-display-main mt-2">
              <div className="team-place">
                <p>J4 jharkhand - 26 Jan to 30 Jan 2021</p>
                <p>City/Town: Uttarakhand India</p>
              </div>
              <div className="round-final-container">
                <p>Round: Final</p>
                <div className="round-final">
                  <div className="round-left">
                    <p>Mayank Joshi</p>
                    <div className="country">
                      <img src={india} />
                      <p>Ind</p>
                    </div>
                    <p>W</p>
                  </div>
                  <p className="score score-alignment">6-7</p>
                  <p className="score-alignment">4-6</p>
                  <p className="score score-alignment">2-6</p>
                  <div className="round-right">
                    <p>L</p>
                    <div className="country">
                      <p>Ind</p>
                      <img src={india} />
                    </div>
                    <p>Mayank Joshi</p>
                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>


        <Row className="mt-3">
          <Col lg={12}>
            <h4 className="activity_heading">J4 Uttarakhand </h4>
            <div className="round-display-main mt-2">
              <div className="team-place">
                <p>J4 jharkhand - 26 Jan to 30 Jan 2021</p>
                <p>City/Town: Uttarakhand India</p>
              </div>
              <div className="round-final-container">
                <p>Round: Final</p>
                <div className="round-final">
                  <div className="round-left">
                    <p>Mayank Joshi</p>
                    <div className="country">
                      <img src={india} />
                      <p>Ind</p>
                    </div>
                    <p>W</p>
                  </div>
                  <p className="score score-alignment">6-7</p>
                  <p className="score-alignment">4-6</p>
                  <p className="score score-alignment">2-6</p>
                  <div className="round-right">
                    <p>L</p>
                    <div className="country">
                      <p>Ind</p>
                      <img src={india} />
                    </div>
                    <p>Mayank Joshi</p>
                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>


        <Row className="mt-3">
          <Col lg={12}>
            <h4 className="activity_heading">J4 Uttarakhand </h4>
            <div className="round-display-main mt-2">
              <div className="team-place">
                <p>J4 jharkhand - 26 Jan to 30 Jan 2021</p>
                <p>City/Town: Uttarakhand India</p>
              </div>
              <div className="round-final-container">
                <p>Round: Final</p>
                <div className="round-final">
                  <div className="round-left">
                    <p>Mayank Joshi</p>
                    <div className="country">
                      <img src={india} />
                      <p>Ind</p>
                    </div>
                    <p>W</p>
                  </div>
                  <p className="score score-alignment">6-7</p>
                  <p className="score-alignment">4-6</p>
                  <p className="score score-alignment">2-6</p>
                  <div className="round-right">
                    <p>L</p>
                    <div className="country">
                      <p>Ind</p>
                      <img src={india} />
                    </div>
                    <p>Mayank Joshi</p>
                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>
      </Container>


    </div>
  );
}

export default Index;
