import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "./formStyle.css"
import { profileUpdate } from '../../services/insitituteApiService';
import swal from "sweetalert";
import notify from '../../utils/notification';
import { LocationContext } from '../../Context/LocationContext';
import Academy from './SportInsititution/Academy';
import Insititute from './SportInsititution/Insititute';
import Others from './SportInsititution/Others';
import Club from './SportInsititution/Club';
import Commonformfeild from './SportInsititution/Commonformfeild';
import Facilities from './SportInsititution/Facilities';
import Association from './SportInsititution/Association';
import { FormCorrectionContext } from '../../Context/FormCorrection/FormCorrectionContext';
import { SportsContext } from '../../Context/SportsContext';



const StepOne = ({ setBtndisable, userData, organisationList, goSteps, ListOganisationById, updateStepCount }) => {

  const { countries, states, cities, fetchStates, fetchCities } = useContext(LocationContext);
  const { correctionListData, fetchCorrectionForm } = useContext(FormCorrectionContext);


  const { sportsList, fetchSportsList } = useContext(SportsContext);

  const checkKeyExistence = (corrections, keyToCheck) => {
    return corrections?.some(correction => correction.key === keyToCheck);
  };
  const isReadOnly = (fieldName) => {
    return organisationList?.status === 'ask_for_resubmit' && !checkKeyExistence(correctionListData?.data?.registration_correction, fieldName);
  };


  const [initialValues, setInitialValues] = useState({
    name_of_legel_entity: '',
    Reg_No: '',
    Date_of_establish: '',
    type_of_sports_institute: '',
    sports_offered: '',
    country: '',
    state: '',
    city: '',
    Zip_Code: '',
    Phone_No: '',
    Email: '',
    Website: '',
    represntive_name: '',
    represntive_role: '',
    represntive_phone: '',
    represntive_email: '',
    facilities: '',
    achievements: '',
    affiliated_sport_body: '',
    additional_member: '',
    additional_information: '',
    club_member_count: '',
    head_coach: '',
    training_program_offer: '',
    programs_offered: '',
    sport_support: '',
    capacity: '',
    available_equipment: '',
    operation_hour: ''
  });

  useEffect(() => {
    ListOganisationById(userData?.user?.id);
  }, [userData?.user?.id]);


  useEffect(() => {
    if (organisationList) {
      setInitialValues({
        name_of_legel_entity: organisationList?.name_of_legel_entity || '',
        Reg_No: organisationList?.registration_number || '',
        Date_of_establish: organisationList?.year_of_operation || '',
        type_of_sports_institute: organisationList?.type_of_sports_institute || '',
        Zip_Code: organisationList?.pincode || '',
        country: organisationList?.country || '',
        state: organisationList?.state || '',
        city: organisationList?.city || '',
        Email: organisationList?.contact_person_email || '',
        Phone_No: organisationList?.contact_person_contact || '',
        affiliated_org: organisationList?.affiliated_sport_body || '',
        represntive_name: organisationList?.represntive_name || '',
        represntive_role: organisationList?.represntive_role || '',
        represntive_phone: organisationList?.represntive_phone || '',
        represntive_email: organisationList?.represntive_email || '',
        club_member_count: organisationList?.club_member_count || '',
        facilities: organisationList?.facilities || '',
        achievements: organisationList?.achievements || '',
        affiliated_sport_body: organisationList?.affiliated_sport_body || '',
        Website: organisationList?.website || '',
        sports_offered: organisationList?.sports_offered || '',

      });
    }
  }, [organisationList]);

  const handleCountryChange = (e, setFieldValue) => {
    const countryId = e.target.value;
    setFieldValue('country', countryId);
    fetchStates(countryId);
  };

  const handleStateChange = (e, setFieldValue) => {
    const stateId = e.target.value;
    setFieldValue('state', stateId);
    fetchCities(stateId);
  };

  const handleCityChange = (e, setFieldValue) => {
    const cityId = e.target.value;
    setFieldValue('city', cityId);
  };

  const getRemarks = (fieldName) => {
    const correction = correctionListData?.data?.registration_correction.find(corr => corr.key === fieldName);
    return correction ? correction.remarks : '';
  };
  const getValidationSchema = (userType) => {
    
    let schema = {
      name_of_legel_entity: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Name of Legal Entity must contain only letters and spaces')
        .required('Name of Legal Entity is required'),

      Date_of_establish: Yup.string().required('Date of Establishment is required'),

      country: Yup.string().required('Country is required'),
      state: Yup.string().required('State is required'),
      city: Yup.string().required('City is required'),

      Zip_Code: Yup.string()
        .matches(/^\d+$/, 'Zip Code must contain only numbers')
        .required('Zip Code is required'),

      Email: Yup.string()
        .email('Invalid email address ')
        .matches(
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          'Email can only contain letters, numbers, and one "@" symbol. Special characters like <>, "", \\, /, :, ;, (), {}, [], |, *, ?, #, ~, $, !, ^ are not allowed'
        )
        .required('Email is required'),


      Phone_No: Yup.string()
        .matches(/^\d+$/, 'Phone Number must contain only numbers')
        .required('Phone Number is required'),


      Website: Yup.string()
        .matches(
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9#]+\/?)*$/,
          'Enter a valid website URL'
        ),

      represntive_name: Yup.string().required('Representative name is required')
        .matches(/^[A-Za-z\s]+$/, 'Representative name must contain only letters and spaces'),
      represntive_role: Yup.string()
        .matches(
          /^[A-Za-z0-9\s.,'-]*$/, 
          'Representative role contains invalid characters'
        )
        .required('Representative role is required'),
      represntive_phone: Yup.string()
      .matches(/^\d{10}$/, 'Representative phone must be exactly 10 digits')
        .required('Representative phone is required'),

      represntive_email: Yup.string()
        .email('Invalid email address')
        .matches(
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          'Email can only contain letters, numbers, and one "@" symbol. Special characters like <>, "", \\, /, :, ;, (), {}, [], |, *, ?, #, ~, $, !, ^ are not allowed'
        )
        .required('Representative email is required'),
    };

    // if (userType === 'Facilitie') {
    //   schema = {
    //     ...schema,
    //     sport_support: Yup.string().required('Sports Supported is required'),
    //   };
    // }



    if (userType !== 'Facilitie') {
      schema = {
        ...schema,
        Reg_No: Yup.string()
          .matches(/^[A-Za-z0-9-]+$/, 'Registration Number must contain only letters, numbers, and hyphens')
          .required('Registration Number is required'),
        sports_offered: Yup.string().required('Sports Offered is required'),
      };
    }

  
 

    return Yup.object(schema);
  };

  const appliedValidationSchema = getValidationSchema(userData?.user?.user_category_type?.category_name);
  // const appliedValidationSchema = organisationList?.status !== 'ask_for_resubmit' ? validationSchema : null;
  console.log("appliedValidationSchema", appliedValidationSchema, organisationList?.status)

  const onSubmit = async (values, { setSubmitting, setErrors }) => {

    const editData = {
      id: userData?.user?.id,
      name_of_legel_entity: values.name_of_legel_entity,
      registration_number: values.Reg_No,
      type_of_sports_institution: values.type_of_sports_institute,
      year_of_operation: values.Date_of_establish,
      sports_offered: values.sports_offered,
      country: values.country,
      state: values.state,
      city: values.city,
      pincode: values.Zip_Code,
      contact_person_contact: values.Phone_No,
      contact_person_email: values.Email,
      website: values.Website,
      represntive_name: values.represntive_name,
      represntive_role: values.represntive_role,
      represntive_phone: values.represntive_phone,
      represntive_email: values.represntive_email,
      club_member_count: values.club_member_count,
      facilities: values.facilities,
      achievements: values.achievements,
      affiliated_sport_body: values.affiliated_sport_body,
      additional_services: values.additional_information,
      head_coach: values.head_coach,
      programs_offered: values.programs_offered,
      sport_support: values.sport_support,
      capacity: values.capacity,
      available_equipment: values.available_equipment,
      operation_hour: values.operation_hour,
      step: goSteps
    };

    try {
      const response = await profileUpdate(editData);
      if (response?.data?.status === true) {
        setBtndisable(false);
        swal(`${response?.data?.message}`);
        updateStepCount(1)
        ListOganisationById(userData?.user?.id);
      } else {
        if (response.data.message) {
          setErrors(response.data.message);
        } else {
          notify('error', response.data.message);
        }
      }
    } catch (error) {
      console.log('error', error);
      swal('Oops', 'An unexpected error occurred. Please try again.', 'error');
    } finally {
      setSubmitting(false);
    }
  };


  let categoryComponent;

  if (userData?.user?.user_category_type?.category_name === "Club") {
    categoryComponent = <Club ErrorMessage={ErrorMessage} Field={Field} isReadOnly={isReadOnly} organisationList={organisationList} />;
  } else if (userData.user?.user_category_type?.category_name === "Academy") {
    categoryComponent = <Academy ErrorMessage={ErrorMessage} Field={Field} isReadOnly={isReadOnly} organisationList={organisationList} />;
  } else if (userData?.user?.user_category_type?.category_name === "Institute") {
    categoryComponent = <Insititute ErrorMessage={ErrorMessage} Field={Field} isReadOnly={isReadOnly} organisationList={organisationList} />;
  } else if (userData?.user?.user_category_type?.category_name === "Facilitie") {
    categoryComponent = <Facilities ErrorMessage={ErrorMessage} Field={Field} isReadOnly={isReadOnly} organisationList={organisationList} />;
  } else if (userData?.user?.user_category_type?.category_name === "Association") {
    categoryComponent = <Association ErrorMessage={ErrorMessage} Field={Field} isReadOnly={isReadOnly} organisationList={organisationList} />;
  }
  else {
    categoryComponent = <Others ErrorMessage={ErrorMessage} Field={Field} isReadOnly={isReadOnly} organisationList={organisationList} />;
  }
  useEffect(() => {
    fetchSportsList();
  }, []);
  useEffect(() => {
    fetchCorrectionForm(userData.user.id);
  }, [userData?.user?.id, fetchCorrectionForm]);
  return (
    <section>
      {/* <div className="card card_box_wrapper">
        <div className="card-header"> */}
        <div className="formDiv">
        <div style={{marginTop:"15px", marginBottom:"40px"}}>
          <span className="formHeader">Organizations Details</span>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={appliedValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="row">
                  <Commonformfeild
                    formData={initialValues}
                    ErrorMessage={ErrorMessage}
                    handleCountryChange={handleCountryChange}
                    handleCityChange={handleCityChange}
                    handleStateChange={handleStateChange}
                    countries={countries}
                    Field={Field}
                    states={states}
                    cities={cities}
                    setFieldValue={setFieldValue}
                    status={organisationList?.status}
                    correctionListData={correctionListData}
                    isReadOnly={isReadOnly}
                    getRemarks={getRemarks}
                    fetchStates={fetchStates}
                    fetchCities={fetchCities}
                    organisationList={organisationList}
                    userDataName={userData.user?.user_category_type?.category_name}
                    sportsList={sportsList}
                  />
                  {categoryComponent}

                </div>
                <div className="text-end">
                  <button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: "125px" }}>
                    Next
                  </button>
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
