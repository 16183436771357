import "./styles.scss";
import { CFormSelect } from "@coreui/react";

function Index() {
  const data = [
    {
      year: 2020,
      titleWon: 1,
      tournament: "J5 Uttarakhand",
      category: "Senior",
      location: "Uttarakhand",
    },
    {
      year: 2021,
      titleWon: 2,
      tournament: "J5 Selangor",
      category: "Senior",
      location: "Uttarakhand",
    },
    {
      year: 2022,
      titleWon: 2,
      tournament: "4th West Asia Fujairah Cup",
      category: "Senior",
      location: "Uttarakhand",
    },
  ];

  return (
    <div className="achievement-container">
      <div className="achievement-header">
        <div>
          <h2>Achievement</h2>
        </div>
        <div className="achievemnt-filters">
          <div>
          <CFormSelect
            options={[
              "Last 12 Month",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            className="dropdown"
          />
          </div>
          <div>
            <CFormSelect
              options={[
                "Category",
                { label: "2023", value: "2023" },
                { label: "2024", value: "2024" },
              ]}
              style={{ padding: "7px", width: "200px", marginLeft: "10px" }}
            />
          </div>
          <div>
        
          <CFormSelect
            options={[
              "Seasons",
              { label: "Reference No.", value: "Reference No." },
              { label: "Date", value: "Date" },
            ]}
            className="dropdown"
          />
          </div>
        </div>
      </div>

      <div className="athlete-achivmnt-heading">
        <p>Stevan Andria Achievement</p>
      </div>

      <div className="e-tender-table-content">
        <div className="achievemnt-table2">
          <div
            className={`${`achievement-TableHeader`} position-relative py-4 fw-bold body-text`}
          >
            <div>YEAR</div>
            <div>TITLE WON</div>
            <div>TOURNAMENT</div>
            <div>CATEGORY</div>
            <div>LOCATION</div>
          </div>
          <div className="bodyContainer">
            {data.map((item, index) => (
              <div className="achievement-DivContainer" key={index}>
                <div>{item.year}</div>
                <div>{item.titleWon}</div>
                <div className="m-4">{item.tournament}</div>
                <div className="m-4">{item.category}</div>
                <div className="m-4">{item.location}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
