import styles from "./styles.module.scss";
import React from "react";
import { useLocation } from "react-router-dom";
import search from "../../asserts/E-tender/search.png";
import pdf from "../../asserts/E-tender/pdf.png";
import greenPdf from "../../asserts/E-tender/green_pdf.png";
import { getAllEtenderWebsiteNotification } from "../../services/EtenderApiService/EtenderApiService";

function Index() {
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [data, setData] = React.useState(null);
  const { hash } = useLocation();

  React.useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function fetchData() {
    try {
      let res = await getAllEtenderWebsiteNotification();
      setData(() =>
        res?.data?.data?.data?.filter((ele) => ele.type !== "tender")
      );
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="etender-container">
        <div className="etender-container">
          <div className="text-center">
            <p className="text-white e-tender-head">TENDER</p>
          </div>
          <div className="e-tender-search-field">
            <div>
              <input placeholder="Search..." className="input" />
            </div>
            <div>
              <img width="100" src={search} />
            </div>
          </div>
        </div>
      </div>

      <div className="e-tender-table-section" id="E-Tender">
        {/* <div className="e-tender-filter-options">
          <div className="sortBy-e-tender">
            <CFormSelect
              options={[
                "Sort By",
                { label: "Reference No.", value: "1" },
                { label: "Desc", value: "2" },
                { label: "Other", value: "3" },
              ]}
              style={{ padding: "7px", backgroundColor: "#F6F7F9" }}
            />
          </div>
          <div className="archived-tender-field">
            <CFormSelect
              options={[
                "Archived Tenders",
                { label: "Refernec No.", value: "1" },
                { label: "Desc", value: "2" },
                { label: "Other", value: "3" },
              ]}
              style={{
                padding: "7px",
                width: "200px",
                backgroundColor: "#F6F7F9",
              }}
            />
          </div>
          <div className="filters-e-tender">
            <CFormSelect
              options={[
                "Filter",
                { label: "Refernec No.", value: "1" },
                { label: "Desc", value: "2" },
                { label: "Other", value: "3" },
              ]}
              style={{
                padding: "7px",
                width: "200px",
                marginLeft: "10px",
                backgroundColor: "#F6F7F9",
              }}
            />
          </div>
        </div> */}

        <div className="e-tender-table-content">
          <div className="table2" id="jharkhand-e-tender-table">
            <div
              className={`${`E-tender-fopTableHeader`} position-relative py-4 body-text`}
            >
              <div>S. No</div>
              <div>TITLE</div>
              <div>Description</div>
              <div>Create At</div>
              <div>Documents</div>
            </div>
            <div className="bodyContainer">
              {data?.map((item, index) => (
                <div
                  className={`E-tender-fopDivContainer ${
                    hoveredItemId === item.id ? "hovered" : ""
                  }`}
                  key={index}
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <div>{item?.id}</div>
                  <div>{item?.title}</div>
                  <div className="m-4">{item?.description}</div>
                  <div className="m-4">
                    {new Date(item?.created_at).toLocaleString("en-us", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </div>
                  <div className="m-4">
                    <img
                      src={hoveredItemId === item.id ? greenPdf : pdf}
                      alt="PDF Icon"
                      width="30"
                      height="auto"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
