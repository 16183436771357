export const calendar_details = [
  {
    dateTime: "Friday 02/05/2023",
    time: "00:30 Your Time",
    date: "03/02/2023",
    title: "U-15 Jharkhand State Wrestling Championship 2023",
    category: "U.15 Boys FS'GR & Girls | State/ Cum Selection Trail",
    location: "Ranchi",
    buyTicket: "BUY TICKETS",
    t1: "PLAN A TRIP",
    t2: "SHOP",
    t3: "ENGAGE",
    t4: "WATCH ONLINE/TV",
    t5: "READ, LISTEN & MORE"
  },
  {
    dateTime: "Saturday 03/05/2023",
    time: "14:00 Your Time",
    date: "04/03/2023",
    title: "U-18 Jharkhand State Wrestling Championship 2023",
    category: "U.18 Boys FS'GR & Girls | State/ Cum Selection Trail",
    location: "Ranchi",
    buyTicket: "BUY TICKETS",
    t1: "PLAN A TRIP",
    t2: "SHOP",
    t3: "ENGAGE",
    t4: "WATCH ONLINE/TV",
    t5: "READ, LISTEN & MORE"
  },
  {
    dateTime: "Sunday 04/05/2023",
    time: "09:00 Your Time",
    date: "05/04/2023",
    title: "Senior Jharkhand State Wrestling Championship 2023",
    category: "Senior Men FS'GR & Women | State/ Cum Selection Trail",
    location: "Ranchi",
    buyTicket: "BUY TICKETS",
    t1: "PLAN A TRIP",
    t2: "SHOP",
    t3: "ENGAGE",
    t4: "WATCH ONLINE/TV",
    t5: "READ, LISTEN & MORE"
  },
  {
    dateTime: "Monday 05/05/2023",
    time: "12:00 Your Time",
    date: "06/05/2023",
    title: "Junior Jharkhand State Wrestling Championship 2023",
    category: "Junior Boys FS'GR & Girls | State/ Cum Selection Trail",
    location: "Ranchi",
    buyTicket: "BUY TICKETS",
    t1: "PLAN A TRIP",
    t2: "SHOP",
    t3: "ENGAGE",
    t4: "WATCH ONLINE/TV",
    t5: "READ, LISTEN & MORE"
  },
  {
    dateTime: "Tuesday 06/05/2023",
    time: "16:00 Your Time",
    date: "07/06/2023",
    title: "Cadet Jharkhand State Wrestling Championship 2023",
    category: "Cadet Boys FS'GR & Girls | State/ Cum Selection Trail",
    location: "Ranchi",
    buyTicket: "BUY TICKETS",
    t1: "PLAN A TRIP",
    t2: "SHOP",
    t3: "ENGAGE",
    t4: "WATCH ONLINE/TV",
    t5: "READ, LISTEN & MORE"
  },
];
