// src/RangeBar.js
import React from "react";

const RangeBar = ({
  totalWins,
  teamAWins,
  teamBWins,
  title,
  leftSideText,
  rightSideText,
}) => {
  const getPercentage = (wins) => (wins / totalWins) * 100;

  const teamAStyle = {
    width: `${getPercentage(teamAWins)}%`,
    backgroundColor: "#05CF93",
    height: "10px",
  };

  const teamBStyle = {
    width: `${getPercentage(teamBWins)}%`,
    backgroundColor: "#ffffff",
    height: "10px",
  };

  return (
    <div className="range-bar-container">
      <p>{title}</p>
      <div className="range-bar-wins">
        <div className="win">
          <p className="count">{teamAWins}</p>
          <p>{leftSideText}</p>
        </div>
        <div className="bar">
          <div style={teamAStyle}></div>
          <div style={teamBStyle}></div>
        </div>
        <div className="lost">
          <p className="count">{teamBWins}</p>
          <p>{rightSideText}</p>
        </div>
      </div>
    </div>
  );
};

export default RangeBar;
