import "./styles.scss";
import india from "../../asserts/AthleteProfile/india.png";

const RoundDisplay = () => {
  return (
    <div className="round-display-main">
      <div className="team-place">
        <p>J4 jharkhand - 26 Jan to 30 Jan 2021</p>
        <p>City/Town: Uttarakhand India</p>
      </div>
      <div className="round-final-container">
        <p>Round: Final</p>
        <div className="round-final">
          <div className="round-left">
            <p>Mayank Joshi</p>
            <div className="country">
              <img src={india} />
              <p>Ind</p>
            </div>
            <p>W</p>
          </div>
          <p className="score score-alignment">6-7</p>
          <p className="score-alignment">4-6</p>
          <p className="score score-alignment">2-6</p>
          <div className="round-right">
            <p>L</p>
            <div className="country">
              <p>Ind</p>
              <img src={india} />
            </div>
            <p>Mayank Joshi</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundDisplay;
