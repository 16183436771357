
import { useEffect } from "react";
import Sliders from "../../components/SwipperSlider"
import "./styles.scss";
import Temple from "../../asserts/AboutUs/Temple.png";
import Jharkhand from "../../asserts/AboutUs/Jharkhand.png"
import Jharkhand2 from "../../asserts/AboutUs/Jharkhand2.png"
import Jharkhand3 from "../../asserts/AboutUs/Jharkhand3.png"
import Uttarakhand1 from "../../asserts/AboutUs/Jharkhand1.png";
import Aboutus1 from "../../asserts/AboutUs/aboutus1.png";
import Aboutus2 from "../../asserts/AboutUs/aboutus2.png";
import Aboutus3 from "../../asserts/AboutUs/aboutus3.png";
import Aboutus4 from "../../asserts/AboutUs/aboutus4.png";
import Aboutus5 from "../../asserts/AboutUs/aboutus5.png";
import Aboutus6 from "../../asserts/AboutUs/aboutus6.png";
import AboutusNew from "../../asserts/AboutUs/Jharkhand_grp.svg"
import IntroductBG from "../../asserts/AboutUs/about-us-vector.png";
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from "react-bootstrap";
import { ContactMail } from "@mui/icons-material";
import map from "../../asserts/AboutUs/world_doted.png";

import Marque from "../../components/Marque/Index";
import React from "react";
import Slider from "react-slick";



const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  const aboutusContent = [

    `Jharkhand has been actively promoting various sports disciplines, including hockey, football, archery, and athletics, among others.`,
    `The state government has established sports academies and training centers to nurture young talent and provide them with world-class facilities and coaching.`,
    `Training of Trainers (ToT) programs have been introduced to enhance the skills and knowledge of coaches and instructors. These programs focus on imparting the latest coaching methodologies, sports science, and techniques to ensure the trainers are well-equipped to guide the athletes effectively.`,
    `Integration of technology has been emphasized in the sports development initiatives. This includes the use of advanced equipment for performance analysis, data tracking, and injury prevention. Additionally, online platforms and mobile applications are being explored to facilitate remote training and monitoring of athletes.`,
    `The state has engaged project management and knowledge partner units to streamline the sports development initiatives and ensure effective implementation. These units provide expertise in areas such as strategic planning, resource management, and monitoring and evaluation of programs.`,
    `Collaborations with national and international sports organizations, as well as corporate entities, have been established to leverage their expertise, resources, and best practices in sports development.`,
    `Emphasis has been placed on creating a robust grassroots-level sports ecosystem, involving schools and local communities. This approach aims to identify and nurture talent from an early age and promote sports culture across the state.`,


  ];

  const Objectives = [
    `<b>Promoting and developing sports activities:</b>The primary objective is to actively promote and develop sports activities within the state of Jharkhand. This involves formulating and implementing policies aligned with the state government's sports policy, with the ultimate goal of improving the overall sports level and performance in the state.`,
    `<b>Instilling health consciousness and physical well-being:</b> The organization aims to create awareness and provide opportunities for people of all age groups to achieve physical fitness through sports, yoga, and other related techniques. This objective focuses on inculcating a sense of health consciousness and promoting physical well-being among the general public.`,
    `<b>Advising the state government on sports development:</b>The organization intends to serve as an advisory body to the state government of Jharkhand on matters related to the growth and development of sports. This includes providing recommendations and guidance to the government and subsequently implementing the proposed policies with the government's support.`,
    `<b>Implementing sports policies:</b> The organization will be responsible for implementing policies proposed by the Jharkhand government, the Government of India, or other organizations related to the development of sports. This objective ensures that the organization stays aligned with national and state-level sports policies and contributes to their effective implementation.`,
    `<b>Developing sports infrastructure:</b>A key objective is to prepare proposals for establishing sports facilities and infrastructure, such as sports fields, accommodations for players, coaches, and authorities. The organization will develop, procure, manage, conserve, and optimally utilize these resources to support sports activities.`,
    `<b>Organizing sports events and activities: </b> The organization aims to organize various sports-related events and activities, including competitions, exhibitions, training camps, and others. These events will be designed to encourage sports participation, support sportspersons, promote physical well-being, and make these opportunities accessible to the public, either through a single-point responsibility or in collaboration with other organizations.`,

  ];

  const utility = [
    `<b>Establishing and managing sports organizations:</b>The organization will be involved in the inception, management, and direction of new and existing sports organizations within the state. This objective ensures that sports organizations are properly established, managed, and guided towards achieving their objectives.`,
    `<b>Coordinating with national and state-level sports associations: </b> The organization will establish coordination and cooperation with the Indian Olympic Association, national sports associations, and other state and district-level organizations. This collaboration is aimed at fostering the development of sports and supporting the betterment of sportspersons across various levels.`,
    `<b>Promoting research in sports-related fields:</b>The organization will encourage, promote, and conduct research in areas related to sports, such as techniques, physiotherapy, biochemistry, psychology, sports diets, and equipment manufacturing. This objective emphasizes the importance of research and innovation in enhancing sports performance and overall athlete development.`,
    `<b>Providing training and facilities:</b> The organization will provide training and facilities for physical education, sports coaching, sports medicine, and other techniques that are an integral part of sports and physical education. This objective focuses on developing and supporting the necessary infrastructure and resources for comprehensive sports education and training.`,

  ];
  const gallerydata = [
    {
      id: 1,
      img: Aboutus3,

    },
    {
      id: 2,
      img: Aboutus4,

    },
    {
      id: 3,
      img: Aboutus5,

    },
    {
      id: 4,
      img: Aboutus6,

    },
    {
      id: 5,
      img: Aboutus3,

    },
    {
      id: 6,
      img: Aboutus4,

    },
    {
      id: 7,
      img: Aboutus5,

    },
    {
      id: 8,
      img: Aboutus6,

    },
  ]





  return (
    <>
      

      {/* HERO SECTION */}
      
   
    
      <Container fluid className="about-container center_title" >
   
        <Container className="">
          <Row>
            <Col lg={12} className="center_title mobile_hero_section">
              <div className="mb-3">
              <h5 className="about_banner_title ">About us</h5>
              </div>
              
              <div>
              <h1 className="banner_headings text-center " >
                Comprehensive Overview of Sports
                Development Initiatives in Jharkhand
              </h1>
              </div>
            
            </Col>
          </Row>

          <Row className="">  
            <Col lg={12} className="center_title ">
              <Sliders className="" />
            </Col>
          </Row>

        </Container>

      </Container>


      {/* INTRODUCTION SECTION */}
      <Container fluid className=" introduction-container ">
        <Container className="py-5">
          <Row className="mb-5">
            <Col lg={12} className="text-center py-lg-5  py-5">
              <div className="introduct_margin_top">
              <h1 className="introduction_title mt-5">Introduction</h1>
              <p className="intro_para">
                Sports development initiatives in Jharkhand encompass a wide range of programs, policies, and efforts aimed at promoting athleticism, nurturing talent, and enhancing the overall sports ecosystem in the state. Here's a comprehensive overview:
              </p>
              </div>
              
            </Col>
          </Row>

          <Row className="about_us_temple_margin_top ">
            <Col lg={6} className="">
              <div className="">
                {aboutusContent?.map((item, index) => {
                  return (
                    <p className="text-white" key={index}>
                      {item}
                    </p>
                  );
                })}
              </div>

            </Col>
            <Col lg={5} className="templebg_img_section" >
              <div className="img-1 ">
                <img src={Temple} alt="" className="temple_img" />

              </div>

            </Col>
          </Row>

        </Container>
      </Container>



      {   /* MUTLTI UTTRAKHAND SECTION */}
      <Container className="uttrakhan_section_wrapper py-5 ">
        <Row className="">
          <Col lg={3} >
            <img src={Uttarakhand1} alt="" className="w-100" />
          </Col>
          <Col lg={8} className="">
            <div>
              <img src={Jharkhand} alt="" className="w-100 mx-lg-3 mb-4" />
            </div>

            <div className="white_image_wrapper">
              <div>
                <img src={Jharkhand2} alt="" className="" />
              </div>

              <div className="jharkhand_browen_temple_wrapper">
                <img src={Jharkhand3} alt="" className="" />
              </div>

            </div>

          </Col>
        </Row>
      </Container>


      {/* OBJECTIVE SECTION */}
      <Container>
        <h1 className="objective_heading ">Objectives:</h1>
        <Row className="">
          <Col lg={6} className=" py-3 m-0">
            {Objectives?.map((item, index) => {
              return <p key={index} dangerouslySetInnerHTML={{ __html: item }} className="objective_para"></p>;
            })}

          </Col>
          <Col lg={6} className="">
            <div className="image-containers">
              <img src={map} alt="Map" className="custom-image w-100" />
            </div>
          </Col>
        </Row>
      </Container>



      <Container>
        <Row>
          <Col lg={12}>
            <img width="100%" src={Aboutus1} alt="" />
          </Col>
        </Row>
      </Container>

      <Container className="py-lg-5">
        <Row>
          <Col lg={6} className="center_title">
            <img width="100%" src={Aboutus2} alt="" className=""/>
          </Col>
          <Col lg={6}>
            {utility?.map((item, index) => {
              return <p key={index} dangerouslySetInnerHTML={{ __html: item }} className="objective_para"></p>;
            })}

          </Col>

        </Row>
      </Container>


      <Container className="mb-4">
        <Row className="slider-container">
        <Slider {...settings}>
          {
            gallerydata?.map((item) => {
              return  (
                <>
                  <Col lg={4} className=" w-100">
                  <img  src={item?.img} alt="" className="slider_img_title"/>
                </Col>
                </>
              )
            })
          }
        
          </Slider>

        </Row>
      </Container>
     
    
   
     

    </>
  );
};

export default AboutUs;
