import "./styles.scss";
import curve from "../../asserts/AthleteProfile/head_curve.png";
import profile1 from "../../asserts/AthleteProfile/player1.png";
import profile2 from "../../asserts/AthleteProfile/player2.png";
import india from "../../asserts/AthleteProfile/india.png";
import Header from "../../components/Header";
import { Select } from "antd";
import RangeBar from "./RangeBar";
import StatsDisplay from "./StatsDisplay";
import RoundDisplay from "./RoundDisplay";

const HeadtoHead = () => {
  const player1 = {
    name: "Tara Kumari",
    country: "India",
    win: 14,
    age: 42,
    born: 1981,
    career_win_loss: "71-46",
    atp_ranking: 57,
    img: profile1,
  };

  const player2 = {
    name: "Sunita Gari",
    country: "India",
    win: 1,
    age: 30,
    born: 1991,
    career_win_loss: "98-44",
    atp_ranking: 57,
    img: profile2,
  };

  const stats = [
    { label: "Tournaments Wrestled", value: 1 },
    { label: "Medals Won", value: 1 },
    { label: "Matches Wrestled", value: 7 },
  ];

  return (
    <div className="head-container-main">
      <div className="head-contianaer">
     
        <div className="background-curve">
          <img src={curve} />
        </div>
        <p className="title mt-4">HEAD TO HEAD COMPARISON</p>
        <div className="players-record-body">
          <div className="players-record">
            <div className="player-deatils">
              <img src={player1?.img} />
              <div className="details">
                <p>{player1?.name}</p>
                <div className="country">
                  <img src={india} />
                  <p>{player1?.country}</p>
                </div>
                <div className="each-detail">
                  <p>Overall Win</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player1?.win}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>Age</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player1?.age}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>Born</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player1?.born}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>Career win-loss</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player1?.career_win_loss}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>ATP Ranking</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player1?.atp_ranking}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="player-deatils">
              <img src={player2?.img} />
              <div className="details">
                <p>{player2?.name}</p>
                <div className="country">
                  <img src={india} />
                  <p>{player2?.country}</p>
                </div>
                <div className="each-detail">
                  <p>Overall Win</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player2?.win}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>Age</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player2?.age}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>Born</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player2?.born}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>Career win-loss</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player2?.career_win_loss}</p>
                  </div>
                </div>
                <div className="each-detail">
                  <p>ATP Ranking</p>
                  <div className="win">
                    <span>-</span>
                    <p>{player2?.atp_ranking}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="player-record-table">
            <p className="player1">{player1?.name}</p>
            <div className="vs">
              <p>VS</p>
            </div>
            <p className="player2">{player2?.name}</p>
          </div>

          <div className="seasons-dropdown">
            <Select
              //   showSearch
              placeholder="Seasons"
              className="dropdown"
              //   optionFilterProp="children"
              //   onChange={onChange}
              //   onSearch={onSearch}
              //   filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />

            <Select
              //   showSearch
              placeholder="Seasons"
              className="dropdown"
              //   optionFilterProp="children"
              //   onChange={onChange}
              //   onSearch={onSearch}
              //   filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>

          <RangeBar
            totalWins={7}
            teamAWins={6}
            teamBWins={1}
            title="Matches"
            leftSideText="Wins"
            rightSideText="Lost"
          />

          <div className="stats-display-container">
            <StatsDisplay stats={stats} />
            <StatsDisplay stats={stats} />
          </div>

          <RangeBar
            totalWins={7}
            teamAWins={6}
            teamBWins={1}
            title="Points"
            leftSideText="Scored"
            rightSideText="Conceded"
          />
          <RoundDisplay />
          <RoundDisplay />
          <RoundDisplay />
          <RoundDisplay />
        </div>
      </div>
    </div>
  );
};

export default HeadtoHead;
