import React, { useState, useEffect} from "react";
import "./styles.scss";
import { CFormInput, CForm } from "@coreui/react";
import Form from "react-bootstrap/Form";
import hockeyImg from "../../asserts/ContactUs/hockeyImg.png";

function Index() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const renderInputBox = (option) => {
    return (
      selectedOption === option && (
        <div style={{ marginTop: "5px" }}>
          <input
            type="text"
            className="input-label-radio"
            placeholder={`Enter ${option} Names`}
          />
        </div>
      )
    );
  };

  return (
    <div>
      <div className="contact-us-container">
        <div className="container">
          <div className="text-center">
            <p className="text-white common-heading">CONTACT US</p>
          </div>
        </div>
      </div>

      <div className="contact-us-contents">
        <div className="contactUs-details">
          <h3>Get In Touch</h3>
          <p>
            Complete this form to express your early interest in receiving
            general news and Games updates.
          </p>
        </div>
        <div className="contactUs-form">
          <h3 className="your-details">Your details</h3>
          <div className="contactUs-input-boxes">
            <div style={{ marginBottom: "5px" }}>
              <label className="label-contact-us" style={{ marginTop: "0px" }}>
                FIRST NAME
              </label>
              <CFormInput
                type="email"
                placeholder="Enter First Name"
                className="fullName-input"
              />
            </div>
            <div>
              <label className="label-contact-us" style={{ marginTop: "0px" }}>
                LAST NAME
              </label>
              <CFormInput
                type="email"
                placeholder="Enter Last Name"
                className="fullName-input"
              />
            </div>
            <div>
              <label className="label-contact-us">EMAIL</label>
              <CFormInput
                type="email"
                placeholder="Enter Email"
                className="fullName-input"
              />
            </div>
            <div>
              <label className="label-contact-us">PHONE NUMBER</label>
              <CFormInput
                type="email"
                placeholder="Enter Phone Number"
                className="fullName-input"
              />
            </div>
          </div>
          <div className="radio-btns">
            <h3 className="radios-heading">Select the Concerned Issues :</h3>
            <div className="radio-container">
              <form>
                <div>
                  <Form.Check
                    type="radio"
                    aria-label="Academies"
                    label="Academies"
                    checked={selectedOption === "Academies"}
                    onChange={handleOptionChange}
                    value="Academies"
                  />
                  {renderInputBox("Academies")}
                </div>
                <div>
                  <Form.Check
                    type="radio"
                    aria-label="Events"
                    label="Events"
                    checked={selectedOption === "Events"}
                    onChange={handleOptionChange}
                    value="Events"
                  />
                  {renderInputBox("Events")}
                </div>
                <div>
                  <Form.Check
                    type="radio"
                    aria-label="Athlete"
                    label="Athlete"
                    checked={selectedOption === "Athlete"}
                    onChange={handleOptionChange}
                    value="Athlete"
                  />
                  {renderInputBox("Athlete")}
                </div>
                <div>
                  <Form.Check
                    type="radio"
                    aria-label="Tenders"
                    label="Tenders"
                    checked={selectedOption === "Tenders"}
                    onChange={handleOptionChange}
                    value="Tenders"
                  />
                  {renderInputBox("Tenders")}
                </div>
                <div>
                  <Form.Check
                    type="radio"
                    aria-label="Others"
                    label="Others"
                    checked={selectedOption === "Others"}
                    onChange={handleOptionChange}
                    value="Others"
                  />
                  {renderInputBox("Others")}
                </div>
              </form>
            </div>
          </div>

          <div className="inputBox">
            <div className="input-file-contact-us-details">
              <div>
                <CFormInput
                  name="lastYearMarksheet"
                  type="file"
                  id="formFile"
                />
              </div>
              <div>
                <button>Upload</button>
              </div>
            </div>
          </div>
          <div className="send-btn">
            <button>SUBMIT</button>
          </div>
        </div>
      </div>

      <div className="img-content">
        <div>
          <img src={hockeyImg} />
        </div>
        <div className="contact-us-state-details">
          <h3>Director Sports,</h3>
          <p>Directorate of Sports & Youth Affairs,</p>
          <p>DoTACS & Y, Gate No. 29 Birsa Munda</p>
          <p>Football Stadium, Morabadi, Ranchi</p>
          <p>Pin- -834008</p>
          <p>Ph- +91 0651 2400740</p>
          <p>Email Id :- kheljharkhand@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default Index;
