import react, { createContext, useContext, useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { updateDetailsAtEveryStep } from "../../config/registrationConfigUrl";
import {
  get_Registration_Listing,
  getCorrectionData,
} from "../../config/registrationConfigUrl";
import Swal from "sweetalert2";
// import { PersonalDetailsContext } from "../../registrationServices/contexts/personalDetailContext/personalDetailsContext";

export const VerticalStepperContext = createContext();
// const location = useLocation();
export const VerticalStepperProvider = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("initial vs",initialStep)
  const initialStep = JSON.parse(localStorage.getItem("currentStep")) || {
    level: 0,
    userType: "default",
    isRegistered: false,
    value: "",
  };
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [userData, setUserData] = useState({
    country: "101",
  });
  const [db_step_regestration, setDb_step_regestration] = useState("2");
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  // console.log("db_step_regestration", db_step_regestration);
  const [correctionData, setCorrectionData] = useState({});
  const [userDocuments, setUserDocuments] = useState({
    id: "",
    tenth: false,
    twelth: false,
    ug: false,
    pg: false,
    phd: false,
    profile_image: false,
    cancelled_passbook_cheque_upload: false,
    other: false,
    tenthDocument: "",
    twelthDocument: "",
    ugDocument: "",
    pgDocument: "",
    phdDocument: "",
    otherDocument: "",
    profile_image_link: "",
    cancelled_passbook_cheque_upload_link: "",
  });

  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     // Prevent the default back action
  //     window.history.pushState(null, null, window.location.pathname);
  //   };

  //   // Push the current state into history, this prevents navigating away
  //   window.history.pushState(null, null, window.location.pathname);

  //   // Listen for back/forward button clicks
  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);

  useEffect(() => {
    // fetch initial  info

    getRegistrationList();
  }, []);

  const token = localStorage.getItem("access_token");
  let currentStp = JSON.parse(localStorage.getItem("currentStep")) || {};
  let loginDetails = JSON.parse(localStorage.getItem("login_Details"));

  function DataToSend({ steps, userType }) {
    for (let i = 0; i < arr?.length; i++) {
      if (steps == arr[i]?.step && userType == arr[i]?.userType) {
        return arr[i]?.data;
      }

      // if (userType == arr[i]?.userType) {
      //   return arr[i]?.data;
      // }
    }
  }

  const handleNext = async () => {
    let res = DataToSend({
      steps: currentStep?.level,
      userType: currentStep?.userType,
    }); //for joining name

    const full_name = [res.first_name, res.middle_name, res.last_name]
      .filter((name) => name) // Removes any empty strings or undefined values
      .join(" "); // Joins the remaining names with a single space

    //adding full name to response
    res.name = full_name;
    // console.log("currentstep handleNext==>", currentStep);

    if (res.status == "ask_for_resubmit") {
      delete res.password;
    }

    if (isPasswordSet) {
      delete res.password;
    }

    // console.log(res, "RESPONSE==>");
    // alert(`${currentStep.level}`)
    try {
      let response = await updateDetailsAtEveryStep(res);
      if (response?.data?.status === true) {
        // console.log(response, "---")
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
       
        let max_step = response?.data?.data?.steps || 2;
        setDb_step_regestration(max_step);
        // setIsPasswordSet(true)

        if (
          (currentStep.userType == "Athlete" && currentStep.level == 7) ||
          (currentStep.userType == "Support Staff" && currentStep.level == 6) ||
          (currentStep.userType == "Technical Official" &&
            currentStep.level == 6)
        ) {
          console.log("Step completed");
        } else {
          setCurrentStep((prevStep) => {
            const newStep = { ...prevStep, level: prevStep.level + 1 };
            localStorage.setItem("currentStep", JSON.stringify(newStep));
            if (currentStep?.userType == "Athlete" && currentStep?.level == 4) {
              setIsPasswordSet(true);
            } else if (
              (currentStep?.userType == "Technical Official" ||
                currentStep?.userType == "Support Staff") &&
              currentStep?.level == 5
            ) {
              setIsPasswordSet(true);
            }
            return newStep;
          });
        }
      } else {
        if (response?.data?.message) {
          console.log("error", response?.data?.error.message);
          // Swal.fire("Oops", `${response?.data?.error.message[0]}`, "error");
        } else {
          console.log("error", response?.data?.error.message);
          // Swal.fire("Oops", `${response?.data?.error.message[0]}`, "error");
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    }
  };

  const processDocuments = (docs) => {
    const updatedDocuments = {
      tenth: false,
      twelth: false,
      ug: false,
      pg: false,
      phd: false,
      profile_image: false,
      cancelled_passbook_cheque_upload: false,
      other: false,
      tenthDocument: "",
      twelthDocument: "",
      ugDocument: "",
      pgDocument: "",
      phdDocument: "",
      otherDocument: "",
      profile_image_link: "",
      cancelled_passbook_cheque_upload_link: "",
    };

    docs.forEach((doc) => {
      switch (doc?.doc_name) {
        case "other":
          updatedDocuments.other = true;
          updatedDocuments.otherDocument = doc.doc_file;
          break;
        case "tenth":
          updatedDocuments.tenth = true;
          updatedDocuments.tenthDocument = doc.doc_file;
          break;
        case "twelth":
          updatedDocuments.twelth = true;
          updatedDocuments.twelthDocument = doc.doc_file;
          break;
        case "ug":
          updatedDocuments.ug = true;
          updatedDocuments.ugDocument = doc.doc_file;
          break;
        case "pg":
          updatedDocuments.pg = true;
          updatedDocuments.pgDocument = doc.doc_file;
          break;
        case "phd":
          updatedDocuments.phd = true;
          updatedDocuments.phdDocument = doc.doc_file;
          break;
        case "profile_image":
          updatedDocuments.profile_image = true;
          updatedDocuments.profile_image_link = doc.doc_file;
          break;
        case "cancelled_passbook_cheque_upload":
          updatedDocuments.cancelled_passbook_cheque_upload = true;
          updatedDocuments.cancelled_passbook_cheque_upload_link = doc.doc_file;
          break;
        default:
          break;
      }
    });

    setUserDocuments(updatedDocuments);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => ({ ...prevStep, level: prevStep.level - 1 }));
  };

  const getRegistrationList = async () => {
    try {
      let response = await get_Registration_Listing(user?.id);

      let max_step = response?.data?.data?.steps || 2;

      let application_status = response?.data?.data?.status;
      let resbmitStep = {};
      if (application_status == "ask_for_resubmit") {
        resbmitStep.steps = 3;
        // console.log("set stepp to 3");
      }

      setDb_step_regestration(max_step);
      if (response?.data?.status !== true) {
      }
      if (loginDetails) {
        // console.log("inside vertical stepper");
        let userType =
          loginDetails?.user?.category_type == "5"
            ? "Athlete"
            : loginDetails?.user?.category_type == "6"
            ? "Support Staff"
            : loginDetails?.user?.category_type == "7"
            ? "Technical Official"
            : "register_as";

        let value;

        if (userType === "Athlete") {
          value = "register_as_athlete";
        } else if (userType === "Support Staff") {
          value = "register_as_support_staff";
        } else if (userType === "Technical Official") {
          value = "register_as_technical_official";
        } else {
          value = "register_as";
        }

        localStorage.setItem(
          "currentStep",
          JSON.stringify({
            level: response?.data?.data?.steps,
            isRegistered: true,
            userType: userType,
            value: value,
          })
        );
      }

      // console.log(response.data, "datas==>")
      // alert(`${response?.data?.data.country}`)
      let country = {};
      if (response?.data?.data?.country === null) {
        country.country = "101";
      }
      setUserData({
        ...userData,
        resbmitStep,
        ...response?.data?.data,
        ...country,
      });

      // if(response?.data?.data?.status == "ask_for_resubmit") {
      //   console.log("ask==>", response?.data?.data?.status)
      //   setUserData({ ...userData, steps: 3});
      //   setCurrentStep((prevStep) => {
      //     const updatedStep = { ...prevStep, level: 3 };

      //     // Store the updated step in localStorage
      //     localStorage.setItem('currentStep', JSON.stringify(updatedStep));

      //     return updatedStep;
      //   });
      // }

      if (response?.data?.data?.registration_docs) {
        processDocuments(response?.data?.data?.registration_docs);
        setUploadedDocuments(response?.data?.data?.registration_docs);
      }

      if (application_status === "ask_for_resubmit") {
        // setUserData((userData)=>{
        //   return  {...userData,step:3}
        // })
        fetchCorrectionforData(user?.id);
      }
      return;
    } catch (error) {
      console.log(error.message, "error");
      // Swal.fire("Oops", "Something went wrong!", "error");
    }
  };

  const fetchCorrectionforData = async (id) => {
    try {
      let response = await getCorrectionData(id);
      if (response?.data?.status !== true) {
        console.log("error", response.data.message);
        // Swal.fire(response?.data?.message || "Error Fetching Correction Data");
        return;
      }
      let dataArray = response?.data?.data?.registration_correction || [];
      console.log("correction cdKey", dataArray);

      // const convertedObject = dataArray.reduce((acc, curr) => {
      //   acc[curr.key] = {
      //     label: curr.label,
      //     value: curr.value,
      //     remark: curr.remark,
      //     key: curr.key,
      //   };
      //   return acc;
      // }, {});

      // const convertedObjectDocs = dataArray.reduce((acc, curr) => {
      //   acc[curr.label] = {
      //     label: curr.label,
      //     value: curr.value,
      //     remark: curr.remark,
      //     key: curr.key,
      //   };
      //   return acc;
      // }, {});

      const convertedObject = dataArray.reduce((acc, curr) => {
        console.log("cdKey", curr.key.startsWith("registration_doc"));
        if (curr.key.startsWith("registration_doc")) {
          // Use `label` as the key if it starts with 'registration_doc'
          acc[curr.label] = {
            label: curr.label,
            value: curr.value,
            remark: curr.remark,
            key: curr.key,
          };
        } else {
          // Use `key` as the key for all other cases
          acc[curr.key] = {
            label: curr.label,
            value: curr.value,
            remark: curr.remark,
            key: curr.key,
          };
        }
        return acc;
      }, {});

      // const convertedObject = dataArray.reduce((acc, curr) => {
      //   acc[curr.key] = {
      //     label: curr.label,
      //     value: curr.value,
      //     remark: curr.remark,
      //     key: curr.key,
      //   };
      //   return acc;
      // }, {});

      // const convertedObjectDocs = dataArray.reduce((acc, curr) => {
      //   acc[curr.label] = {
      //     label: curr.label,
      //     value: curr.value,
      //     remark: curr.remark,
      //     key: curr.key,
      //   };
      //   return acc;
      // }, {});
      console.log("correction key cdkey", convertedObject);
      setCorrectionData({ ...(convertedObject || {}) });
    } catch (error) {
      console.error(error);
    }
  };

  let arr = [
    {
      step: 2,
      userType: "Athlete",
      data: {
        ...userData,
        id: user?.id,
        steps: 2,
      },
    },

    {
      step: 3,
      userType: "Athlete",
      data: {
        ...userData,
        id: user?.id,
        steps: 3,
      },
    },
    {
      step: 4,
      userType: "Athlete",
      data: {
        ...userData,
        id: user?.id,
        steps: 4,
      },
    },
    {
      step: 5,
      userType: "Athlete",
      data: {
        ...userData,
        id: user?.id,
        steps: 5,
      },
    },
    {
      step: 6,
      userType: "Athlete",
      data: {
        id: user?.id,
        steps: 6,
      },
    },
    {
      step: 7,
      userType: "Athlete",
      data: {
        ...userData,
        id: user?.id,
        steps: 7,
        status: "final_submit",
      },
    },

    {
      step: 2,
      userType: "Support Staff",
      data: {
        ...userData,
        id: user?.id,
        steps: 2,
      },
    },
    {
      step: 3,
      userType: "Support Staff",
      data: {
        ...userData,
        id: user?.id,
        steps: 3,
      },
    },
    {
      step: 4,
      userType: "Support Staff",
      data: {
        ...userData,
        id: user?.id,
        steps: 4,
      },
    },
    {
      step: 5,
      userType: "Support Staff",
      data: {
        ...userData,
        id: user?.id,
        steps: 5,
      },
    },
    {
      step: 6,
      userType: "Support Staff",
      data: {
        ...userData,
        id: user?.id,
        status: "final_submit",
        steps: 6,
      },
    },
    {
      step: 2,
      userType: "Technical Official",
      data: {
        ...userData,
        id: user?.id,
        steps: 2,
      },
    },
    {
      step: 3,
      userType: "Technical Official",
      data: {
        ...userData,
        id: user?.id,
        steps: 3,
      },
    },
    {
      step: 4,
      userType: "Technical Official",
      data: {
        ...userData,
        id: user?.id,
        steps: 4,
      },
    },
    {
      step: 5,
      userType: "Technical Official",
      data: {
        ...userData,
        id: user?.id,
        steps: 5,
      },
    },
    {
      step: 6,
      userType: "Technical Official",
      data: {
        ...userData,
        id: user?.id,
        status: "final_submit",
        steps: 6,
      },
    },
  ];

  // console.log(correctionData, "corrr==>");

  return (
    <VerticalStepperContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        userData,
        setUserData,
        userDocuments,
        uploadedDocuments,
        setUploadedDocuments,
        setUserDocuments,
        handleNext,
        handleBack,
        correctionData,
        setCorrectionData,
        db_step_regestration,
        isPasswordSet,
        setIsPasswordSet,
      }}
    >
      {children}
    </VerticalStepperContext.Provider>
  );
};
