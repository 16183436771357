import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./regstyle.scss";
import { FaHome } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { FaUpRightFromSquare } from "react-icons/fa6";
import { FaPerson } from "react-icons/fa6";
import RejectStatusCard from "./RejectStatusCard";
import RegistrationIsSubmitCard from "./RegistrationIsSubmitCard";
import RegistrationPassCard from "./RegistrationPassCard";
import RegisterReSubmitCard from "./RegisterReSubmitCard";
import { InsituteRegistrationContext } from "../../../Context/InsituteRegistrationAllData/InsituteRegistrationAllListData";
import Modal from "react-bootstrap/Modal";
import IndividualProfile from "../IndividualProfile";
import { HiArrowLeft } from "react-icons/hi";

const RegistrationStatusCard = () => {
  const { organisationList, ListOganisationById, loading, setLoading } =
    useContext(InsituteRegistrationContext);

  const userDataString = localStorage.getItem("login_Details");
  const userData = JSON.parse(userDataString);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    ListOganisationById(userData?.user?.id);
  }, [ListOganisationById, userData?.user?.id]);

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={12} className="py-5">
            <Card className="h-100">
              {/* <Card.Header> */}
                {/* <div className="d-flex justify-content-between">
                  <div>
                    <h5>Track Registration Status</h5>
                  </div>

                  <div className="d-flex">
                    <div>
                      <Link to="/">
                        <button className="button_title">
                          <HiArrowLeft style={{ fontSize: "1.2rem" }} /> Back to
                          Home{" "}
                        </button>
                      </Link>
                    </div>
                    <div className="mx-2">
                      <Link>
                        <button className="button_title" onClick={handleShow}>
                          View Profile
                        </button>
                      </Link>
                    </div>
                  </div>
                </div> */}

                    <div className="alertBoxheader">
                    <div className="txtAlign">
                      {/* <button
                        type="button"
                        onClick={handleBackHome}
                        className="round-button"
                        title="Home"
                      >
                       <i><FaHome /></i> 
                      </button> */}

                      <Link to="/">
                        <button className="round-button" title="Back to Home">
                          <i><FaHome /></i> 
                        </button>
                      </Link>



                      <button type="button" onClick={handleShow} className="round-button-log" title="Show Profile">
                     <i style={{ width:"10px",height:"10px"}}><FaPerson />
                     </i> 
                      </button>
                    </div>

                    <div className="regHeader">
                   <aria-label class="nameHeader">Registration Status</aria-label>
                    </div>
                    
                  </div>
              {/* </Card.Header> */}





              <Card.Body className="py-5">
                {loading ? (
                  <div className="text-center">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="card-content">
                    {organisationList?.status === "reject" ? (
                      <RejectStatusCard />
                    ) : organisationList?.status === "final_submit" ? (
                      <RegistrationIsSubmitCard
                        organisationList={organisationList}
                      />
                    ) : organisationList?.status === "pass" ? (
                      <RegistrationPassCard />
                    ) : organisationList?.status === "ask_for_resubmit" ? (
                      <RegisterReSubmitCard />
                    ) : (
                      <p className="text-center">Loading..</p>
                    )}
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <IndividualProfile
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </>
  );
};

export default RegistrationStatusCard;
