import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import swal from "sweetalert";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';
import notify from "../../utils/notification";
import { documentUpdate } from "../../services/insitituteApiService";
import "./style.css"
import { FormCorrectionContext } from "../../Context/FormCorrection/FormCorrectionContext";
import { useNavigate } from "react-router-dom";
import { ShimmerButton } from "react-shimmer-effects";


const StepTwo = ({ setBtnsubmit, userData, setStatus, ListOganisationById, organisationList, goSteps, updateStepCount}) => {
   const { correctionListData ,fetchCorrectionForm} = useContext(FormCorrectionContext);
   const [loading, setLoading] = useState(true);
  
   console.log("status===>",organisationList?.status)
	const navigate = useNavigate();

   const checkKeyExistence = (corrections, keyToCheck) => {
      return corrections?.some(correction => correction.key === keyToCheck);
   };
   const isReadOnly = (fieldName) => {
      return organisationList?.status === 'ask_for_resubmit' && !checkKeyExistence(correctionListData?.data?.registration_correction, fieldName);
   };
   // console.log("isReadOnly")
   const allowedFileTypes = [
      "application/pdf",      // PDF
      "application/msword",   // DOC
      "image/jpeg",           // JPG
      "image/png",            // PNG
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" // DOCX
    ];

    const allowedProfileFileTypes = [
      "image/jpeg",           // JPG
      "image/jpg",           // JPG
      "image/png",            // PNG
    ];

    const MAX_FILE_SIZE = 2 * 1024 * 1024;
   const [initialValues, setInitialValues] = useState({
      registration_certificate: '',
      tax_identification: '',
      affiliation: '',
      facility_owner: '',
      facility_safety: '',
      achievements: '',
      financial_bank: '',
      financial_Recent: '',
      identity_Proof: '',
      profile_image:'',
   });
 const [error,setErrors] = useState("");
 console.log("error==>",error.profile_image);

   const validationSchema = Yup.object().shape({
      registration_certificate: Yup.mixed()
         .required("Registration Certificate is required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      tax_identification: Yup.mixed()
         .required("Tax Identification Document is required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
         profile_image: Yup.mixed()
         .required("Profile update is required")
         .test("fileType", "Only .jpg, .png files are accepted", (value) => {
           return value && allowedProfileFileTypes.includes(value.type);
         }),
      affiliation: Yup.mixed()
         .required("Affiliation Certificates are required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      facility_owner: Yup.mixed()
         .required("Facility Owner Document is required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      facility_safety: Yup.mixed()
         .required("Facility Safety Certificate is required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      achievements: Yup.mixed()
         .required("Proof of Achievements is required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      financial_bank: Yup.mixed()
         .required("Bank Account Details are required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      financial_Recent: Yup.mixed()
         .required("Recent Financial Statements are required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
      identity_Proof: Yup.mixed()
         .required("Identity Proof of Key Personnel is required")
         .test("fileType", "Only .doc, .pdf, .jpg, .png files are accepted", (value) => {
            return value && allowedFileTypes.includes(value.type);
         })
         .test("fileSize", "File size must be less than 2 MB", (value) => {
            return value && value.size <= MAX_FILE_SIZE;
          }),
   });


   const appliedValidationSchema = organisationList?.status !== 'ask_for_resubmit' ? validationSchema : null;

   useEffect(() => {
      setInitialValues({
         registration_certificate: organisationList.registration_docs?.[1]?.doc_name || '',
         registration_certificate: organisationList?.tax_docs?.[0]?.doc_file || '',
         tax_identification: organisationList?.tax_docs?.[0]?.doc_file || '',
         affiliation: organisationList?.affiliation_docs?.[0]?.doc_file || '',
         facility_owner: organisationList?.facility_owner_docs?.[0]?.doc_file || '',
         facility_safety: organisationList?.facility_safety_docs?.[0]?.doc_file || '',
         achievements: organisationList?.achievements_docs?.[0]?.doc_file || '',
         financial_bank: organisationList?.financial_bank_docs?.[0]?.doc_file || '',
         financial_Recent: organisationList?.financial_recent_docs?.[0]?.doc_file || '',
         identity_Proof: organisationList?.identity_proof_docs?.[0]?.doc_file || '',
      });
   }, [organisationList]);

   const handleFormSubmit = async (values, status) => {
      const formData = new FormData();
      for (const key in values) {
         console.log("key",`${key} VALUE ${values[key]}` );
         
         if(values[key]!= ""&& values[key]!= " " && values[key] != "undefined"){
            formData.append(key, values[key]);
         }
         
      }
      formData.append("tax_identification_key", values?.identification_select);
      formData.append("affiliation_key", values?.affiliation_select);
      formData.append("id", userData?.user?.id);
      formData.append("status", "final_submit");
      formData.append("step", goSteps);


      for (let [key, value] of formData.entries()) {
         console.log(`${key}: ${value}`);
      }
      
      try {
         const response = await documentUpdate(formData);
         setBtnsubmit(false);
         if (response?.status === 200) {
            swal("Success", response?.data?.message, "success");
            navigate("/final-status", { state: { organisationList } });
				
            ListOganisationById(userData?.user?.id);
         } else {
            swal("Error", response.data.message, "error");
            if (status === "final_submit") {
					navigate("/final-status", { state: { organisationList } });
				}
         }
      } catch (error) {
         if (error.response && error.response.data && error.response.data.message) {
         const backendErrors = error.response.data.message;
         setErrors(backendErrors);
      } else {
         swal("Error", "An unexpected error occurred.", "error");
      }
      }
   };
 
   useEffect(() => {
      if (correctionListData.status ===  true) {
        fetchCorrectionForm(userData.user.id);
      }
    }, [userData?.user?.id, fetchCorrectionForm]);


    useEffect(() => {
      if (organisationList) {
        const timer = setTimeout(() => {
          setLoading(false);
        }, 1000); // 2000ms = 2 seconds
    
        // Cleanup function to clear the timeout if the component unmounts before the delay finishes
        return () => clearTimeout(timer);
      }
    }, [organisationList]);


    useEffect(() => {
      if (organisationList?.status === "final_submit") {
        navigate("/", { replace: true });
      }
    }, []);
  
   //  if (loading) {
   //    return (
   //      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
   //        <Spinner animation="border" />
   //      </div>
   //    );
   //  }
   
   return (
      <section>
         {/* <div className="card card_box_wrapper">
            <div className="card-header"> */}
            <div className="formDiv">
            <div className="">
               <div className="justify-content-between" style={{marginTop:"15px", marginBottom:"40px"}}>
                  <div>
                     <h4 className="steptwoheader">Upload all Organization-related Documents</h4>
                  </div>

                  <div>
                     <span className="text-danger note">Note * </span> 
                     
                     <span className="noteformat">
                     <br />
                        <span >Excepted file formats : Doc,Docx PDF,Png, jpg, jpeg.<br /> 
                        <span className="text-danger">Max size: 2MB</span></span>
                     </span>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {
                  loading ? (
                     <div className="d-flex justify-content-center align-items-center">
                     {/* <Spinner animation="border" /> */}
                     <h5>Loading...........</h5>
                   </div>
                  ):
                  <Formik
                  initialValues={initialValues}
                  validationSchema={appliedValidationSchema}
                  onSubmit={handleFormSubmit}
               >
                  {({ setFieldValue, values }) => {
                     return (
                        <Form>
                           <div className="row">
                              <div className="col-lg-4 mb-2">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Registration Certificate of the Academy<span className="text-danger">*</span>
                                       <br /></label>
                                       <span className="labelsteptwo">Club/Institute</span>
                                    

                                    <input
                                       type="file"
                                       name="registration_certificate"
                                       className="form-control"
                                       onChange={(e) => setFieldValue("registration_certificate", e.target.files[0])}
                                       disabled={isReadOnly('registration_doc_1')}
                                       placeholder="uploaded"
                                    />
                                     {(!isReadOnly('registration_doc_1') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                    <ErrorMessage name="registration_certificate" component="div" className="text-danger" />
                                    {/* {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[0]?.doc_file && (
                                       renderFilePreview(organisationList.registration_docs[0].doc_file)
                                    )} */}
                                     <div className="mx-1">
                                     {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[0]?.doc_file && (
                                             <a href={organisationList.registration_docs[0].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                     </div>
                                 </div>
                              </div>
                           

                              <div className="col-lg-5 mb-5">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Tax Identification Document<span className="text-danger">*</span>
                                       </label>
                                       <span className=" labelsteptwo"><br />GST/80G Certificate/10AC Certificate</span>
                                    
                                    <div className="d-flex">
                                       <div>
                                          <Field
                                             as="select"
                                             name="identification_select"
                                             className="form-control"
                                             onChange={(e) => setFieldValue("identification_select", e.target.value)}

                                          >
                                             <option value="GST" disabled>GST</option>
                                             <option value="80G Certificate">80G Certificate</option>
                                             <option value="10AC Certificate" >10AC Certificate</option>

                                          </Field>
                                          <ErrorMessage name="identification_select" component="div" className="text-danger" />
                                       </div>

                                       <div>
                                          <input
                                             type="file"
                                             name="tax_identification"
                                             className="form-control mx-3"
                                             onChange={(e) => setFieldValue("tax_identification", e.target.files[0])}
                                             disabled={isReadOnly('registration_doc_2')}
                                          />
                                            {(!isReadOnly('registration_doc_2') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                          <ErrorMessage name="tax_identification" component="div" className="text-danger" />
                                          <div className="mx-4">
                                          {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[1]?.doc_file && (
                                             <a href={organisationList.registration_docs[1].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                          </div>
                                          
                                       </div>
                                    </div>


                                 </div>
                              </div>

                              <div className="col-lg-3 mb-2">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Profile<span className="text-danger">*</span>
                                       </label> <br />
                                       <span className="labelsteptwo">Upload Institute Profile</span>


                                    <input
                                       type="file"
                                       name="profile_image"
                                       className="form-control"
                                       onChange={(e) => setFieldValue("profile_image", e.target.files[0])}
                                       disabled={isReadOnly('registration_doc_10')}
                                       placeholder="uploaded"
                                    />
                                    {error?.profile_image && (
                                    <div className="text-danger">
                                       {error.profile_image[0]}
                                    </div>
                                 )}
                                     {(!isReadOnly('registration_doc_10') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                    <ErrorMessage name="profile_image" component="div" className="text-danger" />
                                    {/* {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[0]?.doc_file && (
                                       renderFilePreview(organisationList.registration_docs[0].doc_file)
                                    )} */}
                                     <div className="mx-1">
                                     {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[9]?.doc_file && (
                                             <a href={organisationList.registration_docs[9].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                     </div>
                                    



                                 </div>
                              </div>
                              

                              <div className="col-lg-6 mb-5">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Affiliation Certificates<span className="text-danger">*</span>
                                       </label> <br />
                                       <span className="labelsteptwo">National Sports Body/State Sports Body/Other relevant accreditations</span>
                                   
                                    <div className="d-flex">
                                       {/* Tax Identification Document: */}
                                       <div>
                                          <Field
                                             as="select"
                                             name="Affiliation_select"
                                             className="form-control"
                                             onChange={(e) => setFieldValue("affiliation_select", e.target.value)}
                                          >
                                             <option value="National Sports Body" >National Sports Body</option>
                                             <option value="State Sports Body">State Sports Body</option>
                                             <option value="other" >Other relevant accreditations</option>

                                          </Field>
                                          <ErrorMessage name="Affiliation_select" component="div" className="text-danger" />
                                       </div>

                                       <div>
                                          <input
                                             type="file"
                                             name="affiliation"
                                             className="form-control mx-3"
                                             onChange={(e) => setFieldValue("affiliation", e.target.files[0])}
                                             disabled={isReadOnly('registration_doc_3')}
                                          />
                                           {(!isReadOnly('registration_doc_3') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                          <div className="mx-4">
                                          {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[2]?.doc_file && (
                                             <a href={organisationList.registration_docs[2].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                         </div>
                                          
                                          <ErrorMessage name="affiliation" component="div" className="text-danger" />
                                       </div>
                                    </div>


                                 </div>
                              </div>



                              <div className="col-lg-6 mb-2">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">Facility Documentation<span className="text-danger">*</span></label>
                                    <div className="d-flex">
                                       <div>
                                          <span className="labelsteptwo">Ownership deed or lease agreement</span>
                                          <input
                                             type="file"
                                             name="facility_owner"
                                             className="form-control"
                                             onChange={(e) => setFieldValue("facility_owner", e.target.files[0])}
                                             disabled={isReadOnly('registration_doc_4')}
                                          />
                                          {(!isReadOnly('registration_doc_4') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}

                                          {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[3]?.doc_file && (
                                             <a href={organisationList.registration_docs[3].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                          <ErrorMessage name="facility_owner" component="div" className="text-danger" />
                                       </div>
                                       <div>
                                          <span className="labelsteptwo mx-3">Safety certificates</span>
                                          <input
                                             type="file"
                                             name="facility_safety"
                                             className="form-control mx-2"
                                             onChange={(e) => setFieldValue("facility_safety", e.target.files[0])}
                                             disabled={isReadOnly('registration_doc_5')}
                                          />
                                           {(!isReadOnly('registration_doc_5') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger mx-3"> Remark feilds</p> :""}
                                           <div className="mx-4">
                                           {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[4]?.doc_file && (
                                             <a href={organisationList.registration_docs[4].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                           </div>
                                          
                                          <ErrorMessage name="facility_safety" component="div" className="text-danger" />
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="col-lg-3 mb-4">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Proof of Achievements<span className="text-danger">*</span>
                                       </label><br />
                                       <span className="labelsteptwo">Certificates of major titles/awards</span>
                                   
                                    <input
                                       type="file"
                                       name="achievements"
                                       className="form-control"
                                       onChange={(e) => setFieldValue("achievements", e.target.files[0])}
                                       disabled={isReadOnly('registration_doc_6')}
                                    />
                                     {(!isReadOnly('registration_doc_6') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                    {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[5]?.doc_file && (
                                       <a href={organisationList.registration_docs[5].doc_file} target="_blank" rel="noopener noreferrer">
                                          View file
                                       </a>
                                    )}
                                    <ErrorMessage name="achievements" component="div" className="text-danger" />
                                 </div>
                              </div>

                              <div className="col-lg-6 mb-2">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Financial Documents<span className="text-danger">*</span>


                                    </label>
                                    <div className="d-flex">
                                       <div>
                                          <label className="">
                                             <span className="labelsteptwo ">Bank account details</span>
                                          </label>
                                          <input
                                             type="file"
                                             name="financial_bank"
                                             className="form-control"
                                             onChange={(e) => setFieldValue("financial_bank", e.target.files[0])}
                                             disabled={isReadOnly('registration_doc_7')}
                                          />
                                            {(!isReadOnly('registration_doc_7') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                          <ErrorMessage name="financial_bank" component="div" className="text-danger" />
                                          {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[6]?.doc_file && (
                                             <a href={organisationList.registration_docs[6].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                       </div>

                                       <div>
                                          <label className="text-label">
                                             <span className="labelsteptwo mx-3">Recent financial statements</span>
                                          </label>
                                          <input
                                             type="file"
                                             name="financial_Recent"
                                             className="form-control mx-3"
                                             onChange={(e) => setFieldValue("financial_Recent", e.target.files[0])}
                                             disabled={isReadOnly('registration_doc_8')}
                                          />
                                            {(!isReadOnly('registration_doc_8') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                          {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[7]?.doc_file && (
                                             <a href={organisationList.registration_docs[7].doc_file} target="_blank" rel="noopener noreferrer">
                                                View file
                                             </a>
                                          )}
                                          <ErrorMessage name="financial_Recent" component="div" className="text-danger" />
                                       </div>
                                    </div>



                                 </div>
                              </div>

                              <div className="col-lg-3 mb-2">
                                 <div className="form-group mb-3">
                                    <label className="text-label steptwolabel">
                                       Identity Proof of Key Personnel<span className="text-danger">*</span>
                                       </label> <br />
                                       <span className="labelsteptwo">Director/Owner</span>
                                    
                                    <input
                                       type="file"
                                       name="identity_Proof"
                                       className="form-control"
                                       onChange={(e) => setFieldValue("identity_Proof", e.target.files[0])}
                                       disabled={isReadOnly('registration_doc_9')}
                                    />
                                      {(!isReadOnly('registration_doc_9') && organisationList?.status === "ask_for_resubmit" )? <p className="text-danger"> Remark feilds</p> :""}
                                    {organisationList?.status === 'ask_for_resubmit' && organisationList.registration_docs?.[8]?.doc_file && (
                                       <a href={organisationList.registration_docs[8].doc_file} target="_blank" rel="noopener noreferrer">
                                          View file
                                       </a>
                                    )}
                                    <ErrorMessage name="identity_Proof" component="div" className="text-danger" />
                                 </div>
                              </div>
                           </div>
                           <div className="text-end">
                          <button className="btn btn-success sw-btn-prev me-1" onClick={() => updateStepCount(0)}>Prev</button>
                           <button type="submit" className="btn btn-success">
                              Submit
                           </button>
                           </div>

                           <div style={{marginTop:"20px"}}>
                              <p style={{fontSize:"14px"}}>  <span className="text-danger">Note:</span> If you go to the previous step, then you will need to re-upload atleast one document here before final submit.</p>
                          
                           </div>
                         
                        </Form>
                     )

                  }}
                  </Formik>
               }
             
            </div>
         </div>
      </section>
   );
};

export default StepTwo;


