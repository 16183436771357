import { useState } from "react";
import logo from "../../asserts/Footer/Jharkhand Sports Directorate logo_white 2.png";
import AppStore from "../../asserts/Footer/app-store.webp";
import PlayStore from "../../asserts/Footer/google-play.webp";
import man from "../../asserts/Footer/man.png";
import chatbot from "../../asserts/Footer/chatbot.png";
import "./style.scss";
import uttharakhand_rajya from "../../asserts/Footer/jharkhand logo 1 (1).png";
import {
  companyInfo,
  footerOptions,
  footerOptionsAbout,
  footerOptionsLatest,
  footerOptionsNotification,
  footerOptionsAthletes,
} from "../../common";
import { useNavigate } from "react-router-dom";
import { IoPlaySharp } from "react-icons/io5";
import { TbPlayerStopFilled } from "react-icons/tb";
import { TbPlayerTrackNextFilled } from "react-icons/tb";

const Footer = () => {
  const navigate = useNavigate();
  const [isChatbotFixed, setIsChatbotFixed] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const documentHeight = document.body.scrollHeight;
    const isBottom = windowHeight + scrollY + 100 >= documentHeight;
    console.log(` {
      windowHeight:${windowHeight},
      scrollY:${scrollY},
      documentHeight:${documentHeight},
      isBottom:${isBottom},
    }`);
    setIsChatbotFixed(!isBottom);
  };

  window.addEventListener("scroll", handleScroll);

  const handleClick = (value: any) => {
    navigate(`/${value}`);
    localStorage.setItem("activePage", value);
    window.scrollTo(0, 0);
  };

  const loginToken =
    localStorage.getItem("login_Details") ||
    localStorage.getItem("currentStep");

  return (
    <div className="footer-main-bg pt-5">
      <div className="container">
        <div className="row footer-main">
          <div className="col-sm-10 col-md-3 col-lg-3 footer-part-1">
            <img src={logo} alt="" style={{ height: "110px" }} />
            <h1 className="footer-head">SPORTS AUTHORITY OF JHARKHAND</h1>

            <div className="onlyMobile">
              <img
                className="footer-uttharakhand_rajya"
                src={uttharakhand_rajya}
                alt=""
              />
            </div>
          </div>
          <div className="col-sm-3 col-md-2 col-lg-2 align-text">
            <h1 className="footer-head">SAJHA</h1>
            {footerOptions
              ?.filter((item) => !(loginToken && item.label === "Registration" || item.label === "Login"))
              .map((item, index) => (
                <p
                  key={index}
                  onClick={() => handleClick(item.value)}
                  className="footer-navigations"
                >
                  {item.label}
                </p>
              ))}
          </div>
          <div className="col-sm-3 col-md-2 col-lg-2 align-text">
            <h1 className="footer-head">ABOUT</h1>
            {footerOptionsAbout?.map((item, index) => (
              <p
                key={index}
                onClick={() => handleClick(item.value)}
                className="footer-navigations"
              >
                {item.label}
              </p>
            ))}
            <h1 className="footer-head">LATEST</h1>
            {footerOptionsLatest?.map((item, index) => (
              <p
                key={index}
                onClick={() => handleClick(item.value)}
                className="footer-navigations"
              >
                {item.label}
              </p>
            ))}
          </div>
          <div className="col-sm-3 col-md-2 col-lg-2 align-text">
            <h1 className="footer-head">NOTIFICATION</h1>
            {footerOptionsNotification?.map((item, index) => (
              <p
                key={index}
                onClick={() => handleClick(item.value)}
                className="footer-navigations"
              >
                {item.label}
              </p>
            ))}
            <h1 className="footer-head">ATHLETES</h1>
            {footerOptionsAthletes?.map((item, index) => (
              <p
                key={index}
                onClick={() => handleClick(item.value)}
                className="footer-navigations"
              >
                {item.label}
              </p>
            ))}
          </div>

          <div className="col-sm-10 col-md-3 col-lg-3 align-text pb-3">
            <div>
              <h1 className="footer-head">ORGANIZATION INFO</h1>
              {companyInfo?.map((item) => (
                <p>{item}</p>
              ))}
            </div>
            <div>
              <h1 className="footer-head">DOWNLOAD THE APP</h1>
              <div style={{ marginTop: "10px" }}>
                <img width="150" height="auto" src={AppStore} alt="" />
              </div>
              <div style={{ marginTop: "10px" }}>
                <img width="150" height="auto" src={PlayStore} alt="" />
              </div>
            </div>
          </div>
          <div className="notMobile">
            <img
              className="footer-uttharakhand_rajya"
              src={uttharakhand_rajya}
              alt=""
            />
          </div>
          <div
            className={`chatbot-section ${
              isChatbotFixed ? "chatbot-fixed" : "chatbot-above-footer"
            }`}
          >
            <div className="accessbility_wrap mb-4 ">
              <img width="35" src={man} alt="man" />
              <div className="char_box_wrapper ">
                <div className="d-flex">
                  <div className="chat_play">
                    <IoPlaySharp />
                  </div>
                  <div className="chat_play">
                    <TbPlayerStopFilled />
                  </div>
                  <div className="chat_play">
                    <TbPlayerTrackNextFilled />
                  </div>
                  <div className="chat_play">
                    <TbPlayerTrackNextFilled />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img width="60" src={chatbot} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
