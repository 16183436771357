import "./styles.scss";
import { useState, useEffect, useContext } from "react";
import photo from "../../../asserts/Registration/photo.png";
import { get_Registration_Listing } from "../../../config/registrationConfigUrl";
import Swal from "sweetalert2";
import { CFormSelect } from "@coreui/react";

import { CFormInput, CFormLabel } from "@coreui/react";
import pdf from "../../../asserts/pdf.png";
import docicon from "../../../asserts/google-docs.png";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";
import { SportsContext } from "../../../Context/SportsContext";
import { getAtomAuthenticationOptions } from "../../apiServices/registerAsApi";

function FormDetails() {
  const {
    handleNext,
    userData,
    setUserData,
    userDocuments,
    setUserDocuments,
    uploadedDocuments,
    setUploadedDocuments,
    currentStep,
    correctionData,
  } = useContext(VerticalStepperContext);
  const { kitSizeList, fetchKitSizes, sportsList, fetchSportsList } =
    useContext(SportsContext);

  // console.log(userData, "user===>");
  // console.log(uploadedDocuments, "user===>");

  const handleViewImage = (fileData) => {
    // Check if the fileData is a PDF
    if (fileData.endsWith(".pdf")) {
      // Open the PDF in a new tab
      window.open(fileData, "_blank");
    } else {
      // Open a new window for the image
      const newWindow = window.open("", "_blank");
      // Set the content of the new window to the image data
      newWindow.document.write(
        `<img src="${fileData}" alt="Document Image" />`
      );
      // Focus the new window
      newWindow.focus();
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const [atomOptions, setAtomOptions] = useState([]);

  const filteredDocuments = uploadedDocuments.filter(
    (document) =>
      document.doc_name !== "profile_image" &&
      document.doc_name !== "cancelled_passbook_cheque_upload"
  );

  const profileImage = uploadedDocuments.find(
    (document) => document.doc_name === "profile_image"
  );
  const cancelledCheque = uploadedDocuments.find(
    (document) => document.doc_name === "cancelled_passbook_cheque_upload"
  );

  // console.log(filteredDocuments, "filtered==>")

  const getRegistrationList = async () => {
    try {
      let response = await get_Registration_Listing(user?.id);
      if (response?.data?.status === true) {
        // console.log(response, "response")
        let country = {};
        if (response?.data?.data?.country === null) {
          country.country = "101";
        }
        setUserData({ ...userData, ...response?.data?.data, ...country });
        // setUserData({ ...userData, ...response?.data?.data });

        console.log(response?.data?.data, "registrationDoc");
        if (response?.data?.data?.registration_docs) {
          setUploadedDocuments(response?.data?.data.registration_docs);
        }
      } else {
        if (response?.data?.message) {
          // Swal.fire(response?.data?.message);
        } else {
          console.log("error", response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
      // Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    }
  };

  const getAtomOptions = async () => {
    try {
      let response = await getAtomAuthenticationOptions();
      if (response?.data?.status === true) {
        setAtomOptions(response?.data?.data);
        // console.log(response, "atomoptions")
      } else {
        if (response?.data?.message) {
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: `${response?.data?.error}`,
          // });
        } else {
          console.log("error", response.data.error);
        }
      }
    } catch (error) {
      console.log(error, "error");
      // Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    }
  };

  useEffect(() => {
    //info
    getRegistrationList();
  }, []);

  useEffect(() => {
    if (
      userData?.status === "ask_for_resubmit" ||
      userData?.status === "ongoing"
    ) {
      let categoryName = "";

      switch (userData?.organization_data?.sub_category_type) {
        case 1:
          categoryName = "Club";
          break;
        case 2:
          categoryName = "Academy";
          break;
        case 3:
          categoryName = "Institute";
          break;
        default:
          break;
      }

      if (categoryName) {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        user.category_name = categoryName;
        localStorage.setItem("user", JSON.stringify(user));
      }
    }
  }, []);

  return (
    <div className="form-personal-details-main-container">
      {/* personal details */}
      <div className="form-personal-details-container">
        <p className="form-personal-details-header">Personal Details Form </p>
      </div>
      <div className="form-personal-details-form">
        <div className="form-personal-details-basic-information">
          <div className="basic-information-1">
            <p className="nameHeading-with-ast">
              First Name <span>*</span>{" "}
            </p>
            <input
              disabled
              name="first_name"
              value={userData?.first_name}
              placeholder="First Name"
            />
          </div>
          <div className="basic-information-2">
            <p className="nameHeading-with-ast">
              Middle Name
              {/* <span>*</span>{" "} */}
            </p>
            <input
              disabled
              name="middle_name"
              value={userData?.middle_name}
              placeholder="Middle Name"
            />
          </div>
          <div className="basic-information-3">
            <p className="nameHeading-with-ast">
              Last Name
              {/* <span>*</span>{" "} */}
            </p>
            <input
              disabled
              name="middle_name"
              value={userData?.last_name}
              placeholder="Last Name"
            />
          </div>
          <div className="basic-information-4">
            <p className="nameHeading-with-ast">Photo </p>
            <img
              width="120"
              height="auto"
              src={profileImage?.doc_file || userData?.profileImage?.data}
              alt="Profile"
            />
            <div>
              <button
                className="viewPhotoBtn "
                disabled={!profileImage?.doc_file}
                onClick={() =>
                  handleViewImage(
                    profileImage?.doc_file || userData?.profileImage?.data
                  )
                }
              >
                View
              </button>
            </div>
          </div>
          <div className="basic-information-5">
            <p className="nameHeading-with-ast">
              Mobile <span>*</span>{" "}
            </p>
            <input
              disabled
              name="mobileNumber"
              value={userData?.mobile}
              placeholder="Enter Mobile"
            />
          </div>
          <div className="basic-information-7">
            <p className="nameHeading-with-ast">
              Email <span>*</span>{" "}
            </p>
            <input
              disabled
              name="email"
              value={userData?.email}
              placeholder="Enter Email"
            />
          </div>
          <div className="basic-information-2">
            {/* <img width="105" height="auto" src={fathersName} alt="" /> */}
            <p className="nameHeading-with-ast">
              Address <span>*</span>{" "}
            </p>
            <input
              name="address"
              value={userData?.address}
              placeholder="Enter Address"
            />
          </div>
        </div>
        <div className="form-personal-details-basic-information " style={{paddingTop: "30px"}}> 
          <div className="basic-information-1">
            <p className="nameHeading-with-ast">
              Father's Name <span>*</span>{" "}
            </p>
            <input
              disabled
              name="first_name"
              value={userData?.father_name}
              placeholder="First Name"
            />
          </div>
          <div className="basic-information-1">
            <p className="nameHeading-with-ast">
              DOB<span>*</span>{" "}
            </p>
            <input
              disabled
              name="first_name"
              value={userData?.dob}
              placeholder="First Name"
            />
          </div>
        </div>
      </div>

      {/* sport section */}
      <div className="form-personal-details-container">
        <p className="form-personal-details-header"> Sports Details </p>
      </div>
      <div className="form-sport-details">
        <div className="form-sport-details-1" style={{ width: "85%" }}>
          {/* <img width='60' height='auto' src={sport} alt="" /> */}
          <p className="nameHeading-with-ast">
            Sport <span>*</span>{" "}
          </p>
          <p
            className="sportsDetBox"
            style={{ backgroundColor: "#f8f9fa", padding: "6px" }}
          >
            {user?.sport_name}
          </p>
          {/* <CFormSelect
            name="sportName"
            disabled
            value={userData?.sport_id}
            aria-label="Select other user Type"
            options={[
              "Select other user Type",
              "Select other user Type",
              ...sportsList?.map((sport) => ({
                label: sport?.sport_name,
                value: sport?.id,
              })),
              
            ]}
            style={{ width: "90%" , backgroundColor: "#f8f9fa", padding: "6px",appearance: "none", WebkitAppearance: "none", MozAppearance: "none"  }}
          /> */}
        </div>
        <div className="form-sport-details-2" style={{ width: "85%" }}>
          {/* <img width='250' height='auto' src={Club_insititude} alt="" /> */}
          <p className="nameHeading-with-ast">
            Club/Institute/Academy <span>*</span>{" "}
          </p>
          <p
            className="sportsDetBox"
            style={{ backgroundColor: "#f8f9fa", padding: "6px" }}
          >
            {" "}
            {user.category_name}
          </p>
          {/* <CFormSelect
            aria-label="Select other user Type"
            disabled
            value={userData.organization_id}
            options={[
              "Select Athlete Level",
              { label: "National", value: "National" },
              { label: "State", value: "State" },
              { label: "District", value: "District" },
            ]}
            style={{ width: "90%" }}
          /> */}
        </div>
        {/* <div style={{ display: "flex" }}> */}
        {currentStep?.userType == "Athlete" && (
          <>
            <div className="form-sport-details-2" style={{ width: "85%" }}>
              {/* <img width='100' height='auto' src={athleteLevel} alt="" /> */}
              <p className="nameHeading-with-ast ">
                Athlete Level <span>*</span>{" "}
              </p>
              <p
                className="sportsDetBox"
                style={{ backgroundColor: "#f8f9fa", padding: "6px" }}
              >
                {userData?.athlete_level}
              </p>
              {/* <p>{userData?.athlete_level}</p> */}
              {/* <CFormSelect
                value={userData?.athlete_level}
                aria-label="Select other user Type"
                name="athlete_level"
                disabled
                options={[
                  "Select Athlete Level",
                  { label: "International", value: "International" },
                  { label: "National", value: "National" },
                  { label: "State", value: "State" },
                  { label: "District", value: "District" },
                ]}
                style={{ width: "90%" }}
              /> */}
            </div>
          </>
        )}

        {currentStep?.userType == "Athlete" && (
          <>
            <div className="form-sport-details-3" style={{ width: "85%" }}>
              {/* <img width='70' height='auto' src={kit} alt="" /> */}
              <p className="nameHeading-with-ast">
                Kit Size <span>*</span>{" "}
              </p>
              <p
                className="sportsDetBox"
                style={{ backgroundColor: "#f8f9fa", padding: "6px" }}
              >
                {userData?.kit_size}
              </p>

              {/* <CFormSelect
                disabled
                value={userData?.kit_size
                }
                aria-label="Select other user Type"
                options={[
                  "Select Kit Size",
                  { label: "XXS", value: "XXS" },
                  { label: "XS", value: "XS" },
                  { label: "S", value: "S" },
                  { label: "M", value: "M" },
                  { label: "L", value: "L" },
                  { label: "XL", value: "XL" },
                  { label: "5L", value: "XXL" },
                ]}
                style={{ width: "90%" }}
              /> */}
            </div>
          </>
        )}
      </div>
      {/* </div> */}
      {/*  Education Level */}
      <div className="form-personal-details-container">
        <p className="form-personal-details-header"> Highest Achievement </p>
      </div>

      <div className="form-bank-details-fields">
        <div className="bd-1">
          <div className="uploaded-documents">
            {filteredDocuments && filteredDocuments.length > 0 ? (
              filteredDocuments.map((document, index) => {
                const fileExtension = document.doc_file
                  .split(".")
                  .pop()
                  .toLowerCase();
                console.log("fileExtension===>", fileExtension);

                let fileIcon;
                if (fileExtension === "pdf") {
                  fileIcon = pdf; // Use the actual path to your PDF icon
                } else if (
                  fileExtension === "doc" ||
                  fileExtension === "docx"
                ) {
                  fileIcon = docicon; // Use the actual path to your DOC icon
                } else {
                  fileIcon = document.doc_file; // If it's an image, use the file itself
                }

                return (
                  <div key={index} className="document">
                    <div className="document-name">{document.doc_name}</div>
                    <div className="document-view">
                      <img
                        src={fileIcon}
                        alt={document.doc_name}
                        className="document-image"
                      />
                      <a
                        href={document.doc_file}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="view-button"
                      >
                        View File
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <p style={{ color: "black" }}>No documents uploaded</p>
            )}
          </div>
        </div>
      </div>

      {/* Bank details */}
      <div className="form-personal-details-container">
        <p className="form-personal-details-header"> Bank Details </p>
      </div>
      <div className="form-bank-details-fields">
        <div className="bd-1">
          <div>
            {/* <img width='80' className="my-3" src={bankName} /> */}
            <p className="nameHeading-with-ast">
              {" "}
              Bank Name <span>*</span>{" "}
            </p>
          </div>
          <div className="bd-1-input">
            <CFormInput
              style={{ backgroundColor: "#f8f9fa" }}
              className="formBntext"
              type="text"
              value={userData?.bank_name}
              disabled
              placeholder="Enter Bank Name"
              aria-label="default input example"
            />
          </div>
        </div>
        <div className="bd-1">
          <div>
            {/* <img width='150' className="my-3" src={accontholderName} /> */}
            <p className="nameHeading-with-ast">
               {" "}
              Account Holder Name <span>*</span>{" "}
            </p>
          </div>
          <div className="bd-1-input">
            <CFormInput
              style={{ backgroundColor: "#f8f9fa" }}
              className="formBntext"
              type="text"
              value={userData?.account_holder_name}
              disabled
              placeholder="Enter Account Holder Name"
              aria-label="default input example"
            />
          </div>
        </div>
        <div className="bd-3">
          <div>
            {/* <img width='130' className="my-3" src={accountNumber} /> */}
            <p className="nameHeading-with-ast">
              Account Number <span>*</span>{" "}
            </p>
          </div>
          <div className="bd-1-input">
            <CFormInput
              style={{ backgroundColor: "#f8f9fa" }}
              className="formBntext"
              type="text"
              placeholder="Enter Account Number"
              value={userData?.bank_account_number}
              disabled
              aria-label="default input example"
            />
          </div>
        </div>
        <div className="bd-4">
          <div>
            {/* <img width='80' className="my-3" src={ifsc} /> */}
            <p className="nameHeading-with-ast">
              IFSC Code <span>*</span>{" "}
            </p>
          </div>
          <div className="bd-1-input">
            <CFormInput
              style={{ backgroundColor: "#f8f9fa" }}
              className="formBntext"
              type="text"
              value={userData?.ifsc_code}
              disabled
              placeholder="Enter IFSC Code"
              aria-label="default input example"
            />
          </div>
        </div>
        <div className="bd-5">
          <div>
            {/* <img width='110' className="my-3" src={branch} /> */}
            <p className="nameHeading-with-ast">
              Branch Name <span>*</span>{" "}
            </p>
          </div>
          <div className="bd-1-input">
            <CFormInput
              style={{ backgroundColor: "#f8f9fa" }}
              className="formBntext"
              type="text"
              value={userData?.branch_name}
              placeholder="Enter Branch Name"
              disabled
              aria-label="default input example"
            />
          </div>
        </div>

        <div className="bd-6">
          <p className="nameHeading-with-ast">
            Uploaded Document (Cheque/Passbook) <span>*</span>
          </p>

          <div className="view-image-container">
            <div className="">
              {cancelledCheque?.doc_file &&
                (() => {
                  const fileExtension = cancelledCheque.doc_file
                    .split(".")
                    .pop()
                    .toLowerCase();
                  let fileIcon;

                  if (fileExtension === "pdf") {
                    fileIcon = pdf; // Replace with the actual path to your PDF icon
                  } else if (
                    fileExtension === "doc" ||
                    fileExtension === "docx"
                  ) {
                    fileIcon = docicon; // Replace with the actual path to your DOC icon
                  } else {
                    fileIcon = cancelledCheque.doc_file; // If it's an image, use the file itself
                  }

                  return (
                    <img
                      src={fileIcon}
                      alt="Cheque/Passbook"
                      
                      className=""
                      style={{width:"250px",height:"150px"}}
                    />
                  );
                })()}
            </div>
            {/* <div> */}
              <button
                className="view-image-button"
                disabled={!cancelledCheque?.doc_file}
                onClick={() =>
                  handleViewImage(
                    cancelledCheque?.doc_file || userData?.canceledCheck?.data
                  )
                }
              >
                View
              </button>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormDetails;
