import React from "react";

import "./styles.scss";
import sportFedration from "../../asserts/photoGallery/grandSchmes.png";
import sportAcademy from "../../asserts/photoGallery/grandSchmes.png";
import hotelInfra from "../../asserts/photoGallery/hostelandInfra.png";
import grantsSch from "../../asserts/photoGallery/grandSchmes.png";
import bookingManagement from "../../asserts/photoGallery/bookingManagement.jpeg";
import search from "../../asserts/photoGallery/search.png";
import LatestSlider from "../../components/LatestSlider/Index";
import Header from "../../components/Header";
import PhotoSlider from "../../components/PhotoSlider";
import s2 from "../../asserts/photoGallery/2.png";
import s3 from "../../asserts/photoGallery/3.png";
import s4 from "../../asserts/photoGallery/4.png";
import s5 from "../../asserts/photoGallery/5.png";
import s6 from "../../asserts/photoGallery/6.png";
import s7 from "../../asserts/photoGallery/7.png";
import s8 from "../../asserts/photoGallery/8.png";
import s9 from "../../asserts/photoGallery/9.png";
import s10 from "../../asserts/photoGallery/10.png";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../asserts/photoGallery/img1.png";
import img2 from "../../asserts/photoGallery/img2.png";
import img3 from "../../asserts/photoGallery/img3.png";

const PhotoGallery = () => {
  const items = [
    {
      id: 1,
      content: "Item 1",
      bgimg: sportFedration,
      hoverName: "Traning program Management",
    },
    {
      id: 2,
      content: "Item 2",
      bgimg: s2,
      hoverName: "Sports federation ",
    },
    {
      id: 3,
      content: "Item 3",
      bgimg: s3,
      hoverName: "sports Academy",
    },
    {
      id: 4,
      content: "Item 4",
      bgimg: s4,
      hoverName: "Hostel and Infrastructure",
    },
    {
      id: 5,
      content: "Item 5",
      bgimg: s5,
      hoverName: " Grants , Schemes and Scholarship ",
    },
    {
      id: 6,
      content: "Item 6",
      bgimg: s6,
      hoverName: "Booking Management",
    },
    {
      id: 7,
      content: "Item 7",
      bgimg: s7,
      hoverName: "Tournament Management ",
    },
    {
      id: 8,
      content: "Item 8",
      bgimg: s8,
      hoverName: "Athlete Management",
    },
    { id: 9, content: "Item 9", bgimg: s9, hoverName: "TSR" },
    {
      id: 10,
      content: "Item 10",
      bgimg: s10,
      hoverName: "Ticketing System",
    },
  ];

  const gallerydata = [
    {
      id: 1,
      img: img1,
      heading: "Academy Management System",
    },
    {
      id: 2,
      img: img2,
      heading: "Academy Management System",
    },
    {
      id: 3,
      img: img3,
      heading: "Academy Management System",
    },
    {
      id: 4,
      img: img1,
    },
    {
      id: 5,
      img: img2,
    },
    {
      id: 6,
      img: img3,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Photo-gallery-container ">
     
      <div className="bgcontainer center_title py-5 ">
        <div className="photo_gallery_container py-5 ">
    
          <div className="mt-5">
            <h3 className="photoGallery-heading mt-5">PHOTO GALLERY</h3>
          </div>
          <div className="photoGallery-search-field">
            <div>
              <input placeholder="Search" className="input" />
            </div>
            <div>
              <img width="100" src={search} />
            </div>
          </div>
          {/* <div className="photo-slider-container border border-danger">
          <PhotoSlider />
        </div> */}
          <Container className="mb-4 py-4">
            <Row className="slider-container">
              <Slider {...settings}>
                {gallerydata?.map((item) => {
                  return (
                    <>
                      <Col
                        lg={6}
                        className=" w-100 mt-5 center_title gallery_sliding_container "
                        style={{
                          backgroundImage: `url(${item?.img})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "52vh",
                        }}
                      >
                        <div className="py-5 gallery_text">
                          <h2 className="gallery_sliding_heading">
                            Academy Management System
                          </h2>
                          <div className="center_title">
                            <button className="discover_btn">Discover</button>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Slider>
              {/* 
              <Slider {...settings}>
                {
                  gallerydata?.map((item) => {
                    return (
                      <>
                        <Col lg={4} className=" w-100 mt-5 text-center " >
                          <div>
                           <img src={item.img} alt="" />
                          </div>
                         
                        </Col>
                      </>
                    )
                  })
                }

              </Slider> */}
            </Row>
          </Container>

        
        </div>

        {/* <div className="slider-container-photoGallery">
            <LatestSlider />
          </div> */}

        {/* <div  className="latest-photos-heading">
          <h4>Latest Photos</h4>
        </div> */}
      </div>
      <div className="photoGallery-gridPage">
        <div className="HeadingSection">
          {" "}
          <h2> Section</h2>{" "}
        </div>

        <div className="container-element container-element-1">
          {items.map((item, index) => (
            <div
              key={item.id}
              className="grid-item"
              style={{ backgroundImage: `url(${item.bgimg})` }}
            >
              <div className="overlay"> {item.hoverName} </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
