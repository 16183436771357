import "./style.scss";

const BottomFooter = () => {
  return (
    <div className="bottom-footer-main">
      <p> COPYRIGHT © DEPARTMENT OF SPORTS GOVERNMENT OF JHARKHAND, INDIA</p>
      <div className="bottom-footer-right">
        <p>VISITORS : 5553232</p>
        <p>TERMS AND CONDITIONS</p>
        <p>PRIVACY POLICY</p>
      </div>
    </div>
  );
};

export default BottomFooter;
