import "./styles.scss";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import athleteBG from "../../../asserts/Registration/athlete-bg.png";
import athlete from "../../../asserts/Registration/athlete.png";
import athleteHover from "../../../asserts/Registration/athleteplayer.png";
import officalBG from "../../../asserts/Registration/official-bg.png";
import official from "../../../asserts/Registration/technical.png";
import supportStaffHover from "../../../asserts/Registration/blue_support_staff.png";
import technicalHover from "../../../asserts/Registration/blue_support_staff.png";
import technical from "../../../asserts/Registration/technical.png";
import { RegisterAsContext } from "../../../registrationServices/contexts/registerContext/registerAsContext";
import { VerticalStepperContext } from "../../../Context/VerticalStepperCount/VerticalStepper";
import { CFormSelect } from "@coreui/react";
import LeftRegistration from "../../LeftRegistration";
import { Col, Container, Row } from "react-bootstrap";
import { getCategorySubTypeById } from "../../../registrationServices/apiServices/basicDetailsApi";
import { getRegisterAsOptions } from "../../../registrationServices/apiServices/registerAsApi";
import { LoadingContext } from "../../../Context/Loading/LoadingContext";
import SkeletonComp from "../../Skeleton/SkeletonComp";

function RegisterAs() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isOfficialHovered, setIsOfficialHovered] = useState(false);
  const [isTechnicalHovered, setIsTechnicalHovered] = useState(false);
  const { registerAs, setRegisterAs } = useContext(RegisterAsContext);
  const [registerAsOptions, setRegisterAsOptions] = useState([]);
  const [subCategory, setSubCategory] = useState(0);
  const { loading, setLoading } = useContext(LoadingContext);

  const {
    currentStep,
    setCurrentStep,
    userData,
    setUserData,
    setUploadedDocuments,
    uploadedDocuments,
  } = useContext(VerticalStepperContext);

  const token = localStorage.getItem("access_token") || null;
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  // Check if the current URL matches '/register_as_athlete'
  const isRegisterAsAthlete = location.pathname === "/register_as_athlete";
  const isRegisterAsSupportStaff =
    location.pathname === "/register_as_support_staff";
  const isRegisterAsTechnicalOfficial =
    location.pathname === "/register_as_technical_official";

  let loginDetails = JSON.parse(localStorage.getItem("login_Details"));

  const getRegisterAsoptions = async () => {
    try {
      setLoading(true);
      let response = await getRegisterAsOptions();
      if (response?.data?.status === true) {
        setRegisterAsOptions(response?.data?.data);
        console.log(response, "register as options");
      } else {
        if (response?.data?.message) {
          Swal.fire(response?.data?.message);
        } else {
          console.log("error", response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
      Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  console.log(loading);
  useEffect(() => {
    getRegisterAsoptions();
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOfficialMouseEnter = () => {
    setIsOfficialHovered(true);
  };

  const handleOfficialMouseLeave = () => {
    setIsOfficialHovered(false);
  };

  const handleTechnicalMouseEnter = () => {
    setIsTechnicalHovered(true);
  };

  const handleTechnicalMouseLeave = () => {
    setIsTechnicalHovered(false);
  };

  async function getCategories(payload) {
    try {
      let res = await getCategorySubTypeById(payload);
      // console.log(res?.data[0]?.id,"response##==>")
      setSubCategory(res?.data[0]?.id);
      // setUserData({...userData, sub_category_type: res?.data[0]?.id})
    } catch (error) {}
  }

  useEffect(() => {
    getCategories(5);
  }, []);

  const handleSupportStaff = (value) => {
    setRegisterAs({ ...registerAs, userType: "Support Staff" });
    setCurrentStep({ ...currentStep, userType: "Support Staff", level: 2 });
    setUserData({
      ...userData,
      category_type: value,
    });
    if (!loginDetails) {
      localStorage.setItem(
        "currentStep",
        JSON.stringify({
          level: 2,
          isRegistered: true,
          value: "register_as_support_staff",
          userType: "Support Staff",
        })
      );
    }
    navigate("/register_as_support_staff", { replace: true });
    // if (userData.user_category_main) {
    //   navigate("/register_as_support_staff");
    // }
  };

  const handleAthleteClick = (value) => {
    // console.log(value, "val");
    setRegisterAs({ ...registerAs, userType: "Athlete" });
    setCurrentStep({ ...currentStep, userType: "Athlete", level: 2 });
    setUserData({
      ...userData,
      category_type: value,
      sub_category_type: subCategory,
    });
    if (!loginDetails) {
      localStorage.setItem(
        "currentStep",
        JSON.stringify({
          level: 2,
          isRegistered: true,
          value: "register_as_athlete",
          userType: "Athlete",
        })
      );
    }
    navigate("/register_as_athlete", { replace: true });
    // if (userData.user_category_main) {
    //   console.log("clicked3")
    //   navigate("/register_as_athlete");
    // }
  };

  const handleTechnicalOfficial = (value) => {
    // console.log(value, "val");
    setRegisterAs({ ...registerAs, userType: "Technical Official" });
    setCurrentStep({
      ...currentStep,
      userType: "Technical Official",
      level: 2,
    });
    setUserData({
      ...userData,
      category_type: value,
    });
    if (!loginDetails) {
      localStorage.setItem(
        "currentStep",
        JSON.stringify({
          level: 2,
          isRegistered: true,
          value: "register_as_tachnical_official",
          userType: "Technical Official",
        })
      );
    }
    navigate("/register_as_technical_official", { replace: true });
    // if (userData.user_category_main) {
    //   navigate("/register_as_technical_official");
    // }
  };

  useEffect(() => {
    if (!token) {
      setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
      navigate("/registration", { replace: true });
      localStorage.removeItem("currentStep");
      localStorage.removeItem("bankDetails");
      localStorage.removeItem("login_Details");
      localStorage.removeItem("access_token");
    } else if (loginDetails) {
      if (loginDetails?.type !== "individual") {
        setCurrentStep({ ...currentStep, level: 0, isRegistered: false });
        navigate("/registration", { replace: true });
        localStorage.removeItem("currentStep");
        localStorage.removeItem("bankDetails");
        localStorage.removeItem("login_Details");
        localStorage.removeItem("access_token");
      }
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        {location.pathname == "/register_as" && (
          <Col lg={3} className="registration-container">
            <LeftRegistration />
          </Col>
        )}
        {!loading ? (
          <Col lg={9} className="">
            <div className="register-as-container">
              <div className="register-as-header-container">
                <p className="registeras-header">Register As</p>
                <p className="register-as-red">
                  Please choose user type carefully. If rejected, your profile
                  will be deleted permanently.
                </p>
              </div>
              <div className="select-option-registerAs">
                <div>
                  <div
                    className={`athlete-inner-registerAs  ${
                      isRegisterAsAthlete
                        ? "athlete-inner-registerAs-active"
                        : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleAthleteClick(registerAsOptions[0]?.id)}
                  >
                    <img
                      width="40"
                      height="auto"
                      src={
                        isHovered || isRegisterAsAthlete
                          ? athleteHover
                          : athlete
                      }
                      alt=""
                    />
                    <span>
                      {registerAsOptions[0]?.category_name || "Athlete"}
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    className={`official-inner-registerAs ${
                      isRegisterAsSupportStaff
                        ? "official-inner-registerAs-active"
                        : ""
                    }`}
                    onMouseEnter={handleOfficialMouseEnter}
                    onMouseLeave={handleOfficialMouseLeave}
                    onClick={() => handleSupportStaff(registerAsOptions[1]?.id)}
                  >
                    <img
                      width="40"
                      height="auto"
                      src={
                        isOfficialHovered || isRegisterAsSupportStaff
                          ? supportStaffHover
                          : official
                      }
                      alt=""
                    />
                    <span>
                      {registerAsOptions[1]?.category_name || "Support Staff"}
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    className={`technical-official-inner-registerAs ${
                      isRegisterAsTechnicalOfficial
                        ? "technical-official-inner-registerAs-active"
                        : ""
                    }`}
                    onMouseEnter={handleTechnicalMouseEnter}
                    onMouseLeave={handleTechnicalMouseLeave}
                    onClick={() =>
                      handleTechnicalOfficial(registerAsOptions[2]?.id)
                    }
                  >
                    <img
                      width="40"
                      height="auto"
                      src={
                        isTechnicalHovered || isRegisterAsTechnicalOfficial
                          ? technicalHover
                          : technical
                      }
                      alt=""
                    />
                    <span>
                      {registerAsOptions[2]?.category_name || "Technical Staff"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ) : (
          <Col>
            <div className="mt-5">
              <SkeletonComp />
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default RegisterAs;

{
  /* <div>
        <p className="other-user-type-register-as">Other User Types</p>
        <div className="register-as">
          <CFormSelect
            aria-label="Select other user Type"
            onChange={handleUserType}
          >
            <option value="" disabled selected>Select User Type</option>
            {registerAsOptions?.slice(3)?.map((option, index) => (
              <option key={option?.id} value={option?.category_name}>
                {option?.category_name}
              </option>
            ))}
          </CFormSelect>
        </div>
      </div> */
}
