import { useState, useContext, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import OrganisationalStructure from "../pages/OrganisationalStructure";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Footer from "../components/Footer";
import BottomFooter from "../components/BottomFooter";
import LoginwithOTP from "../pages/LoginwithOTP";
import LoginwithEmail from "../pages/LoginwithEmail";
import MissionVision from "../pages/MissionVision";
import Registration from "../pages/Registration";
import RegisterAsPage from "../registrationServices/registrationComponent/RegisterAsComponents/RegiserAsSupportStaff/index";
import Infrastructure from "../pages/Infrastructure";
import Etender from "../pages/E-Tender";
import WebsiteNotification from "../pages/WebsiteNotification";
import Calender from "../pages/Calender";
import Athletes from "../pages/Athletes";
import RegisterAsAthlete from "../registrationServices/registrationComponent/RegisterAsComponents/RegisterAsAthletes/index";
import RegisterAsTechnicalOfficial from "../registrationServices/registrationComponent/RegisterAsComponents/RegisterAsTechnicalOfficials/index";
import Loginwithemail_V2 from "../pages/LoginWithEmailV2";
import LoginwithOTP_V2 from "../pages/LoginWithOTPV2";
import LatestNews from "../pages/LatestNews";
import LatestVideos from "../pages/LatestVideos";
import NewsDetails from "../pages/NewsDetails";
import Contactus from "../pages/ContactUs/Index";
import PhotoGallery from "../pages/PhotoGallery";
import AthletesProfile from "../pages/AthletesProfile";
import RightToInfo from "../pages/RightToInfo";
import ComingSoon from "../components/ComingSoonPage/ComingSoon";
import Marque from "../components/Marque/Index";
import Header from "../components/Header";
import Scholarship from "../pages/Scholarship";
import HeadtoHead from "../pages/HeadtoHead";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import OTP_Verification from "../pages/OTPVerification/Index";
import CreateNewPassword from "../pages/CreateNewPassword/Index";
import ProtectedRoute from "../registrationServices/registrationComponent/protectedRoute";
import { VerticalStepperContext } from "../Context/VerticalStepperCount/VerticalStepper";
import IndividualDahboard from "../pages/IndividualDashboard/IndividualDahboard";
import IndividualProfile from "../components/IndividualComponent/IndividualProfile";
import IndividualUpdateProfile from "../components/IndividualComponent/IndividualUpdateProfile";
import registrationStatusCard from "../components/IndividualComponent/RegisterStatus/RegistrationStatusCard";
import RegistrationStatusCard from "../components/IndividualComponent/RegisterStatus/RegistrationStatusCard";
import RegistrationFinalSubmitCard from "../registrationServices/registrationComponent/RegisterStatus/RegistrationFinalSubmitCard";
import RegistrationIndividualStatusCard from "../registrationServices/registrationComponent/RegisterStatus/RegistrationIndividualStatusCard";
import RegisterAs from "../components/VerifyDetails/RegisterAs";
import AuthRegistration from "../registrationServices/registrationComponent/AuthRegistration/AuthRegistration";
import DisableBackButton from "../registrationServices/registrationComponent/DisableRoutes/DisableRoutes";
import Error404 from "../pages/Error404/Error404";

const RootRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // console.log("isAuthenticated", isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const token = localStorage.getItem("access_token");
  const hideComponentsOnRoutes = [
    "/login_email",
    "/registration",
    "/login_otp",
    "/forget-password",
    "/otp-verification",
    "/create-new-password",
    "/register_as_athlete",
    "/register_as_support_staff",
    "/register_as_technical_official",
    "/individual",
    "/final-submit_individual_registration",
    "/auth_registration",
    "/register_as",
    "/final-status",
  ];
  const shouldHideComponents = hideComponentsOnRoutes.includes(
    location.pathname
  );
  const { currentStep, setCurrentStep, handleBack, handleNext } = useContext(
    VerticalStepperContext
  );

  useEffect(() => {
    if (token) {
      let currentStpLocal =
        JSON.parse(localStorage.getItem("currentStep")) || {};
      // console.log("currentStpLocal", currentStpLocal);
      setCurrentStep({ ...currentStpLocal, isRegistered: true });
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("bankDetails");
    }
  }, []);

  //
  useEffect(() => {
    const handlePopState = () => {
      if (isAuthenticated) {
        window.location.reload();
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isAuthenticated]);

  //
  useEffect(() => {
    const token = localStorage.getItem("access_token");

    setIsAuthenticated(!!token);
  }, [localStorage.getItem("access_token")]);

  useEffect(() => {
    // Save the initial state to prevent back navigation
    window.history.pushState(null, "", location.pathname);

    const handlePopState = () => {
      if (location.pathname === "/register_as_athlete") {
        // Push the current state to prevent going back
        window.history.pushState(null, "", location.pathname);
        navigate(1); // Move forward to block going back
      }
    };

    // Add event listener for the popstate event
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.pathname, navigate]);

  const guestRoutes = (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login_otp" element={<LoginwithOTP_V2 />} />
      <Route path="/forget_password" element={<ForgetPassword />} />
      <Route path="/login_email" element={<Loginwithemail_V2 />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/otp-verification" element={<OTP_Verification />} />
      <Route path="/create-new-password" element={<CreateNewPassword />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/home" element={<Home />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route
        path="/organisational_structure"
        element={<OrganisationalStructure />}
      />
      <Route path="/infrastructure" element={<Infrastructure />} />
      <Route path="/scholarship" element={<Scholarship />} />
      <Route path="/e-tender" element={<Etender />} />
      <Route path="/website-notification" element={<WebsiteNotification />} />
      <Route path="/contact-us" element={<Contactus />} />
      <Route path="/calendar" element={<Calender />} />
      <Route path="/athletes" element={<Athletes />} />
      <Route path="/athletes_profile" element={<AthletesProfile />} />
      <Route path="/head_to_head" element={<HeadtoHead />} />
      <Route path="/latest-news" element={<LatestNews />} />
      <Route path="/news-details" element={<NewsDetails />} />
      <Route path="/latest-videos" element={<LatestVideos />} />
      <Route path="/photoGallery" element={<PhotoGallery />} />
      <Route path="/right-to-information" element={<RightToInfo />} />
      <Route path="/comingsoon" element={<ComingSoon />} />
      <Route path="/mission_vision" element={<MissionVision />} />
      {/* <Route path="/*" element={<Error404 />} /> */}
      {/* <Route
        path="/final-submit_individual_registration"
        element={<RegistrationIndividualStatusCard />}
      /> */}
    </Routes>
  );

  const authRoutes = (
    <>
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/home" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route
          path="/organisational_structure"
          element={<OrganisationalStructure />}
        />
        <Route path="/infrastructure" element={<Infrastructure />} />
        <Route path="/scholarship" element={<Scholarship />} />
        <Route path="/e-tender" element={<Etender />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/calendar" element={<Calender />} />
        <Route path="/athletes" element={<Athletes />} />
        <Route path="/athletes_profile" element={<AthletesProfile />} />
        <Route path="/head_to_head" element={<HeadtoHead />} />
        <Route path="/latest-news" element={<LatestNews />} />
        <Route path="/news-details" element={<NewsDetails />} />
        <Route path="/latest-videos" element={<LatestVideos />} />
        <Route path="/photoGallery" element={<PhotoGallery />} />
        <Route path="/right-to-information" element={<RightToInfo />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/mission_vision" element={<MissionVision />} />
        <Route
          path="/final-submit_individual_registration"
          element={<RegistrationIndividualStatusCard />}
        />
        <Route path="/individual" element={<IndividualDahboard />} />
        <Route path="/individual-profile" element={<IndividualProfile />} />
        <Route
          path="/institution-profile-update"
          element={<IndividualUpdateProfile />}
        />
        <Route path="/final-status" element={<RegistrationStatusCard />} />
        <Route path="/auth_registration" element={<AuthRegistration />} />
        <Route path="/register_as" element={<RegisterAs />} />
        <Route path="/register_as_support_staff" element={<RegisterAsPage />} />
        <Route path="/register_as_athlete" element={<RegisterAsAthlete />} />
        <Route
          path="/register_as_technical_official"
          element={<RegisterAsTechnicalOfficial />}
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );

  const NA_Routes = (
    <Routes>
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );

  return (
    <>
      <div>
        {!shouldHideComponents && (
          <div>
            <div className="linear-gradient"></div>
            <Marque />
            <Header />
          </div>
        )}
      </div>

      {isAuthenticated ? authRoutes : guestRoutes}

      {currentPath !== "/login_otp" &&
        currentPath !== "/login_email" &&
        currentPath !== "/registration" &&
        currentPath !== "/register_as_support_staff" &&
        currentPath !== "/infrastructure" &&
        currentPath !== "/register_as_athlete" &&
        currentPath !== "/register_as_technical_official" &&
        currentPath !== "/forget-password" &&
        currentPath !== "/otp-verification" &&
        currentPath !== "/individual" &&
        currentPath !== "/create-new-password" &&
        currentPath !== "/final-submit_individual_registration" &&
        currentPath !== "/auth_registration" &&
        currentPath !== "/register_as" && (
          <>
            <Footer />
            <BottomFooter />
          </>
        )}
    </>
  );
};

export default RootRoutes;
