import React, { useEffect, useState, useContext } from 'react'
import { getStatefromId } from '../../../utils/CountrConverter';
import { Undo } from '@mui/icons-material';
import { SportsContext } from '../../../Context/SportsContext';


const Commonformfeild = ({ sportsList, organisationList, handleCountryChange, handleCityChange, handleStateChange, countries, states, cities, Field, ErrorMessage, setFieldValue, isReadOnly,  userDataName, fetchStates, fetchCities }) => {

console.log("city===>",cities);
    const [categoryName, setCategoryName] = useState("")


    useEffect(() => {
        categoryNameFun(userDataName)
    }, userDataName)

    const categoryNameFun = (userDataName) => {
        if (userDataName === "Club") {
            setCategoryName("Club")
        } else if (userDataName === 'Academy') {
            setCategoryName("Academy");
        } else if (userDataName === 'Institute') {
            setCategoryName("Institute");
        } else if (userDataName === 'Facilitie') {
            setCategoryName("Facilities");
        } else if (userDataName === 'Association') {
            setCategoryName("Association");
        } else {
            setCategoryName("General");
        }

    }
    useEffect(() => {

        if (organisationList?.country != undefined) {
            fetchStates(organisationList?.country);
        }

        if (organisationList?.state != undefined) {
            fetchCities(organisationList?.state);
        }
    }, [organisationList])


    return (
        <>
            {categoryName && <h6 className="txtGap">{categoryName} Information</h6>}
            
            <div className="col-lg-3" style={{marginBottom:"20px"}}>

                <div className="form-group mb-3">
                    <label className="text-label labels"> Name <span className="text-danger">*</span></label>
                    <Field name="name_of_legel_entity" className="form-control" placeholder="Name" disabled={isReadOnly('name_of_legel_entity')} />
                    {(!isReadOnly('name_of_legel_entity') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="name_of_legel_entity" component="div" className="validation-text" />

                </div>
            </div>
            {userDataName === 'Facilitie' ? "" :
                <div className="col-lg-3 ">
                    <div className="form-group mb-3">
                        <label className="text-label labels">Registration Number <span className="text-danger">*</span></label>
                        <Field name="Reg_No" className="form-control" placeholder="Reg. No"
                            disabled={isReadOnly('Reg_No')}
                        />
                        {(!isReadOnly('Reg_No') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}


                        <ErrorMessage name="Reg_No" component="div" className="validation-text" />

                    </div>

                </div>
            }

            <div className="col-lg-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Date of Establishment<span className="text-danger">*</span></label>
                    <Field name="Date_of_establish" className="form-control" placeholder="Date" type="date" disabled={isReadOnly('Date_of_establish')} />
                    {(!isReadOnly('Date_of_establish') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="Date_of_establish" component="div" className="validation-text" />

                </div>
            </div>
            {userDataName === 'Facilitie' ? <div className="form-group mb-3 col-md-3">
                <label className="text-black font-w500">Facility Type
                    <span className="text-danger">*</span></label>
                <Field
                    as="select"
                    name="type_of_sports_institute"
                    className="form-control"

                    disabled={isReadOnly('type_of_sports_institute')}
                >
                    {/* <option value="" disabled>Choose...</option> */}
                    <option value="Indoor">Indoor</option>
                    <option value="Outdoor">Outdoor</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="Others">Others</option>
                </Field>
                <ErrorMessage name="type_of_sports_institute" component="div" className="validation-text" />
            </div> : ""

            }

            {userDataName === 'Facilitie' ? "" :
                <div className="col-lg-3 ">
                    <label className="text-label labels">Sports Offered<span className="text-danger">*</span></label>
                    <Field
                        as="select"
                        name="sports_offered"
                        className="form-control"

                        disabled={isReadOnly('sports_offered')}
                    >
                        <option value="" disabled>Choose...</option>
                        {sportsList.map((data) => (
                            <option key={data.id} value={data.sport_name}>
                                {data.sport_name}
                            </option>
                        ))}


                    </Field>
                    {/* <Field
                     name="sports_offered"
                     className="form-control"
                     placeholder="Sports Offered"
                     disabled={isReadOnly('sports_offered')}
                  /> */}
                    {(!isReadOnly('sports_offered') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="sports_offered" component="div" className="text-danger" />
                </div>
            }


            <div className="form-group mb-3 col-md-3">
                <label className="text-black labels">Country <span className="text-danger">*</span></label>
                <Field
                    as="select"
                    name="country"
                    className="form-control"
                    onChange={(e) => {
                        handleCountryChange(e, setFieldValue);
                    }}
                    disabled={isReadOnly('country')}
                >
                    <option value="" disabled>Choose...</option>
                    {countries?.map((country) => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </Field>
                <ErrorMessage name="country" component="div" className="validation-text" />
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">State<span className="text-danger">*</span></label>
                    <Field
                        as="select"
                        name="state"
                        className="form-control"
                        onChange={(e) => {
                            handleStateChange(e, setFieldValue);
                        }}
                        disabled={isReadOnly('state')}

                    >
                        <option value="" disabled>Choose...</option>
                        {states?.map((item) => (
                            <option key={item?.id} value={item?.id}>{item?.name}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="state" component="div" className="validation-text" />
                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">City<span className="text-danger">*</span></label>
                    <Field
                        as="select"
                        name="city"
                        className="form-control"
                        onChange={(e) => {
                            handleCityChange(e, setFieldValue);
                        }}
                        disabled={isReadOnly('city')}
                    >
                        <option value="" disabled>Choose...</option>
                        {cities?.map((item) => (
                            <option key={item?.id} value={item?.id}>{item?.name}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="city" component="div" className="validation-text" />
                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Zip Code<span className="text-danger">*</span></label>
                    <Field name="Zip_Code" className="form-control" placeholder="Zip Code" disabled={isReadOnly('Zip_Code')} />
                    {(!isReadOnly('Zip_Code') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="Zip_Code" component="div" className="validation-text" />

                </div>
            </div>



            <h6 className="txtGap" style={{marginTop:"30px"}}>Contact Information</h6>




            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Phone Number<span className="text-danger">*</span></label>
                    <Field name="Phone_No" className="form-control" placeholder="Phone" disabled={isReadOnly('contact_person_contact')} />
                    {(!isReadOnly('contact_person_contact') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="Phone_No" component="div" className="validation-text" />
                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Email<span className="text-danger">*</span></label>
                    <Field name="Email" className="form-control" placeholder="Email" disabled={isReadOnly('contact_person_email')} />
                    {(!isReadOnly('contact_person_email') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="Email" component="div" className="validation-text" />

                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Website </label>
                    <Field name="Website" className="form-control" placeholder="Website" disabled={isReadOnly('website')} />
                    {(!isReadOnly('website') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="Website" component="div" className="validation-text" />

                </div>
            </div>

            <h6 className="txtGap" style={{marginTop:"30px"}}>{`${userDataName === 'Facilitie' ? "Facility Manager Information" : "Representative Information"}`}</h6>
            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Name<span className="text-danger">*</span></label>
                    <Field name="represntive_name" className="form-control" placeholder="Name" disabled={isReadOnly('represntive_name')} />
                    {(!isReadOnly('represntive_name') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="represntive_name" component="div" className="validation-text" />

                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Role<span className="text-danger">*</span></label>
                    <Field name="represntive_role" className="form-control" placeholder="Role" disabled={isReadOnly('represntive_role')} />
                    {(!isReadOnly('represntive_role') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="represntive_role" component="div" className="validation-text" />

                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Phone Number<span className="text-danger">*</span></label>
                    <Field name="represntive_phone" className="form-control" placeholder="Phone" disabled={isReadOnly('represntive_phone')} />
                    {(!isReadOnly('represntive_phone') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="represntive_phone" component="div" className="validation-text" />

                </div>
            </div>

            <div className="col-lg-3 mb-3">
                <div className="form-group mb-3">
                    <label className="text-label labels">Email<span className="text-danger">*</span></label>
                    <Field name="represntive_email" className="form-control" placeholder="Email" disabled={isReadOnly('represntive_email')} />
                    {(!isReadOnly('represntive_email') && organisationList?.status === "ask_for_resubmit") ? <p className="text-danger"> Remark feilds</p> : ""}
                    <ErrorMessage name="represntive_email" component="div" className="validation-text" />

                </div>
            </div>
        </>
    )
}

export default Commonformfeild


