import "./styles.scss";
import { CFormInput } from "@coreui/react";

import { useContext, useState, useEffect } from "react";
import { UploadDocument } from "../../../apiServices/educationApi";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import Swal from "sweetalert2";
import { CButton } from "@coreui/react";
import Loader from "../../Loader/Loader";
import { LoadingContext } from "../../../../Context/Loading/LoadingContext";

function EducationDetails() {
  const { userData, userDocuments, setUserDocuments, correctionData } =
    useContext(VerticalStepperContext);
  const { loading, setLoading } = useContext(LoadingContext);

  // console.log(correctionData, "correct==>");

  const user = JSON.parse(localStorage.getItem("user"));

  const [isDisabledButton, setIsDisabledButton] = useState({
    tenth: true,
    twelth: true,
    ug: true,
    pg: true,
    phd: true,
    other: true,
  });

  const [isLoadingUpload, setIsLoadingUpload] = useState({
    tenth: false,
    twelth: false,
    ug: false,
    pg: false,
    phd: false,
    other: false,
  });

  const [isUploaded, setIsUploaded] = useState({
    tenth: false,
    twelth: false,
    ug: false,
    pg: false,
    phd: false,
    other: false,
  });

  const [formData, setFormData] = useState({
    tenth: "",
    twelth: "",
    ug: "",
    pg: "",
    phd: "",
    other: "",
  });

  useEffect(() => {
    if (userData?.registration_docs?.length > 0) {
      setLoading(true);
    }
  }, [userData?.registration_docs?.length]);

  //Last year marksheet
  const handleLastYearFileUpload = async (e) => {
    setIsLoadingUpload({ ...isLoadingUpload, other: true });

    try {
      let response = await UploadDocument(formData.other);
      if (response?.data?.status === true) {
        // console.log(response, "upload document==>");

        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, other: true });
        setIsUploaded({ ...isUploaded, other: true });

        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          other: true,
          otherDocument: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, other: false });
    }
  };

  const handleOtherDocsChange = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, other: true });
      return;
    }

    formData.append("other", file);
    formData.append("id", user?.id);

    setFormData({ ...formData, other: formData });

    if (file) {
      setIsDisabledButton({ ...isDisabledButton, other: false });
    }
  };

  const handleOtherReUpload = async () => {
    setIsUploaded({ ...isUploaded, other: false });
    setUserDocuments({ ...userDocuments, other: false });
  };

  // For 10th Document Upload
  const handleTenthFileUpload = async () => {
    setIsLoadingUpload({ ...isLoadingUpload, tenth: true });
    try {
      let response = await UploadDocument(formData.tenth);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, tenth: true });
        setIsUploaded({ ...isUploaded, tenth: true });

        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          tenth: true,
          tenthDocument: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, tenth: false });
    }
  };

  const handleTenthDocsChange = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, tenth: true });
      return;
    }

    formData.append("tenth", file);
    formData.append("id", user?.id);

    setFormData({ ...formData, tenth: formData });

    if (file) {
      setIsDisabledButton({ ...isDisabledButton, tenth: false });
    }
  };
  const handleTenthReUpload = async () => {
    setIsUploaded({ ...isUploaded, tenth: false });
    setUserDocuments({ ...userDocuments, tenth: false });
  };

  // For 12th Upload Document
  const handleTwelthFileUpload = async () => {
    setIsLoadingUpload({ ...isLoadingUpload, twelth: true });
    try {
      let response = await UploadDocument(formData.twelth);
      console.log(response, "responseform==>");
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, twelth: true });
        setIsUploaded({ ...isUploaded, twelth: true });

        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          twelth: true,
          twelthDocument: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, twelth: false });
    }
  };
  const handletwelthDocsChange = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, twelth: true });
      return;
    }

    formData.append("twelth", file);
    formData.append("id", user?.id);

    setFormData({ ...formData, twelth: formData });

    if (file) {
      setIsDisabledButton({ ...isDisabledButton, twelth: false });
    }
  };

  const handleTwelthReUpload = async () => {
    setIsUploaded({ ...isUploaded, twelth: false });
    setUserDocuments({ ...userDocuments, twelth: false });
  };

  // For Graduate Document
  const handleGraduateFileUpload = async () => {
    setIsLoadingUpload({ ...isLoadingUpload, ug: true });
    try {
      let response = await UploadDocument(formData.ug);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, ug: true });
        setIsUploaded({ ...isUploaded, ug: true });
        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          ug: true,
          ugDocument: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, ug: false });
    }
  };

  const handleGraduateDocsChange = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, ug: true });
      return;
    }

    formData.append("ug", file);
    formData.append("id", user?.id);
    setFormData({ ...formData, ug: formData });
    if (file) {
      setIsDisabledButton({ ...isDisabledButton, ug: false });
    }
  };
  const handleGraduateReUpload = async () => {
    setIsUploaded({ ...isUploaded, ug: false });
    setUserDocuments({ ...userDocuments, ug: false });
  };

  // For Post Graduate Document Upload
  const handlepostGraduateFileUpload = async () => {
    setIsLoadingUpload({ ...isLoadingUpload, pg: true });
    try {
      let response = await UploadDocument(formData.pg);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, pg: true });
        setIsUploaded({ ...isUploaded, pg: true });
        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          pg: true,
          pgDocument: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, pg: false });
    }
  };

  const handlePostGraduateDocsChange = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, pg: true });
      return;
    }

    formData.append("pg", file);
    formData.append("id", user?.id);
    setFormData({ ...formData, pg: formData });
    if (file) {
      setIsDisabledButton({ ...isDisabledButton, pg: false });
    }
  };
  const handlePgReUpload = async () => {
    setIsUploaded({ ...isUploaded, pg: false });
    setUserDocuments({ ...userDocuments, pg: false });
  };

  //Phd Document Upload

  const handlePhdFileUpload = async () => {
    setIsLoadingUpload({ ...isLoadingUpload, phd: true });
    try {
      let response = await UploadDocument(formData.phd);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, phd: true });
        setIsUploaded({ ...isUploaded, phd: true });
        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          phd: true,
          phdDocument: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, phd: false });
    }
  };

  const handlePhdDocsChange = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, phd: true });
      return;
    }

    formData.append("phd", file);
    formData.append("id", user?.id);
    setFormData({ ...formData, phd: formData });
    if (file) {
      setIsDisabledButton({ ...isDisabledButton, phd: false });
    }
  };
  const handlePhdReUpload = async () => {
    setIsUploaded({ ...isUploaded, phd: false });
    setUserDocuments({ ...userDocuments, phd: false });
  };

  const handleViewClick = (docUrl) => {
    window.open(docUrl, "_blank");
  };

  useEffect(() => { }, [userDocuments]);

  console.log(correctionData, "correctionData");
  return (
    <>
      {loading ? (
        <>
          <div className="Education-detail-container">
            <div>
              <p className="education-details-header">Education Details</p>
              <br />
              <span style={{ color: "red" }}>
                Selecting One of the Documents is Compulsory.
              </span>
            </div>
            <div className="education-details-lower-container">
              <div className="education-details-lower-container-1">
                <div>
                  <p className="bold-text">EDUCATION LEVEL</p>
                </div>
                <div>
                  <p className="bold-text">UPLOAD CERTIFICATE</p>
                </div>
              </div>

              {/* // Last year marksheet */}
              <div className="education-details-lower-container-2">
                <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                  <p className="bold-text ">Last Year Marksheet </p>
                </div>
                <div className="input-file-education-details">
                  {/* {alert( correctionData?.["Last Year Marksheet"]?.remark)} */}
                  <div style={{ display: "flex" }}>
                    <div>
                      <CFormInput
                        className="borderchange"
                        name="lastYearMarksheet"
                        type="file"
                        id="formFile"
                        accept="image/*,application/pdf"
                        text={
                          <>
                            Only PNG, JPG, and PDF files are allowed.
                            <br />
                            {userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty(
                                "registration_doc_2"
                              ) && (
                                <span style={{ color: "red" }}>
                             { correctionData?.["Last Year Marksheet"]?.remark}

                                </span>
                              )}
                          </>
                        }
                        onChange={handleOtherDocsChange}
                        disabled={
                          isUploaded?.other ||
                          userDocuments?.other ||
                          (userData.status === "ask_for_resubmit" &&
                            !correctionData?.hasOwnProperty(
                              "Last Year Marksheet"
                            ))
                        }
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <button
                          className="buttonHeight"
                          onClick={handleLastYearFileUpload}
                          disabled={isDisabledButton?.other}
                        >
                          {isUploaded?.other || userDocuments?.other
                            ? "Uploaded"
                            : isLoadingUpload?.other
                              ? "Uploading..."
                              : "Upload"}
                        </button>
                      </div>
                      <div>

                      {userDocuments?.other && (
                        // <div>
                        <CButton
                          className="viewButton"
                          onClick={() =>
                            handleViewClick(`${userDocuments.otherDocument}`)
                          }
                          color="success"
                          variant="outline"
                        >
                          View
                        </CButton>
                        //</div>
                      )}
                      </div>
                      <div>
                        {userDocuments?.other && (
                          //<div>
                          <CButton
                            className="buttonHeight"
                            color="success"
                            variant="outline"
                            onClick={handleOtherReUpload}
                            disabled={
                              userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty(
                                "Last Year Marksheet"
                              )
                            }
                          >
                            Reupload
                          </CButton>
                          // </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* // 10th */}
              <div className="education-details-lower-container-2">
                <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                  <p className="bold-text">10th</p>
                </div>
                <div className="input-file-education-details">
                  <div style={{ display: "flex" }}>
                    <div>
                      <CFormInput
                        className="borderchange"
                        name="10th"
                        onChange={handleTenthDocsChange}
                        type="file"
                        id="formFile"
                        accept="image/*,application/pdf"
                        text={
                          <>
                            Only PNG, JPG, and PDF files are allowed.
                            <br />
                            {userData.status === "ask_for_resubmit" &&
                              correctionData?.hasOwnProperty("tenth") && (
                                <span style={{ color: "red" }}>
                                  {correctionData?.tenth?.remark}
                                </span>
                              )}
                          </>
                        }
                        disabled={
                          isUploaded?.tenth ||
                          userDocuments?.tenth ||
                          (userData.status === "ask_for_resubmit" &&
                            !correctionData?.hasOwnProperty("tenth"))
                        }
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <button
                          className="buttonHeight"
                          onClick={handleTenthFileUpload}
                          disabled={isDisabledButton?.tenth}
                        >
                          {isUploaded?.tenth || userDocuments?.tenth
                            ? "Uploaded"
                            : isLoadingUpload?.tenth
                              ? "Uploading..."
                              : "Upload"}
                        </button>
                      </div>
                      {userDocuments?.tenth && (
                        <div>
                          <CButton
                            className="viewButton"
                            onClick={() =>
                              handleViewClick(`${userDocuments.tenthDocument}`)
                            }
                            color="success"
                            variant="outline"
                          >
                            View
                          </CButton>
                        </div>
                      )}
                      {userDocuments?.tenth && (
                        <div>
                          <CButton
                            color="success"
                            variant="outline"
                            onClick={handleTenthReUpload}
                            disabled={
                              userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty("tenth")
                            }
                          >
                            Reupload
                          </CButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* 12th */}
              <div className="education-details-lower-container-2">
                <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                  <p className="bold-text">12th</p>
                </div>
                <div className="input-file-education-details">
                  <div style={{ display: "flex" }}>
                    <div>
                      <CFormInput
                        className="borderchange"
                        name="12th"
                        onChange={handletwelthDocsChange}
                        type="file"
                        id="formFile"
                        accept="image/*,application/pdf"
                        text={
                          <>
                            Only PNG, JPG, and PDF files are allowed.
                            <br />
                            {userData.status === "ask_for_resubmit" &&
                              correctionData?.hasOwnProperty("twelth") && (
                                <span style={{ color: "red" }}>
                                  {correctionData?.twelth?.remark}
                                </span>
                              )}
                          </>
                        }
                        disabled={
                          isUploaded?.twelth ||
                          userDocuments?.twelth ||
                          (userData.status === "ask_for_resubmit" &&
                            !correctionData?.hasOwnProperty("twelth"))
                        }
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <button
                          className="buttonHeight"
                          onClick={handleTwelthFileUpload}
                          disabled={isDisabledButton?.twelth}
                        >
                          {isUploaded?.twelth || userDocuments?.twelth
                            ? "Uploaded"
                            : isLoadingUpload?.twelth
                              ? "Uploading..."
                              : "Upload"}
                        </button>
                      </div>
                      {userDocuments?.twelth && (
                        <div>
                          <CButton
                            className="viewButton"
                            onClick={() =>
                              handleViewClick(`${userDocuments.twelthDocument}`)
                            }
                            color="success"
                            variant="outline"
                          >
                            View
                          </CButton>
                        </div>
                      )}

                      {userDocuments?.twelth && (
                        <div>
                          <CButton
                            color="success"
                            variant="outline"
                            onClick={handleTwelthReUpload}
                            disabled={
                              userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty("twelth")
                            }
                          >
                            Reupload
                          </CButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* // Graduate */}

              <div className="education-details-lower-container-2">
                <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                  <p className="bold-text">Graduate</p>
                </div>
                <div className="input-file-education-details">
                  <div style={{ display: "flex" }}>
                    <div>
                      <CFormInput
                        className="borderchange"
                        name="graduate"
                        onChange={handleGraduateDocsChange}
                        type="file"
                        id="formFile"
                        accept="image/*,application/pdf"
                        disabled={
                          isUploaded?.ug ||
                          userDocuments?.ug ||
                          (userData.status === "ask_for_resubmit" &&
                            !correctionData?.hasOwnProperty("ug"))
                        }
                        text={
                          <>
                            Only PNG, JPG, and PDF files are allowed.
                            <br />
                            {userData.status === "ask_for_resubmit" &&
                              correctionData?.hasOwnProperty("ug") && (
                                <span style={{ color: "red" }}>
                                  {correctionData?.ug?.remark}
                                </span>
                              )}
                          </>
                        }
                      />
                    </div>

                    <div className="d-flex gap-2">
                      <div>
                        <button
                          className="buttonHeight"
                          onClick={handleGraduateFileUpload}
                          disabled={isDisabledButton?.ug}
                        >
                          {isUploaded?.ug || userDocuments?.ug
                            ? "Uploaded"
                            : isLoadingUpload?.ug
                              ? "Uploading..."
                              : "Upload"}
                        </button>
                      </div>
                      {userDocuments?.ug && (
                        <div>
                          <CButton
                            className="viewButton"
                            onClick={() =>
                              handleViewClick(`${userDocuments.ugDocument}`)
                            }
                            color="success"
                            variant="outline"
                          >
                            View
                          </CButton>
                        </div>
                      )}
                      {userDocuments?.ug && (
                        <div>
                          <CButton
                            color="success"
                            variant="outline"
                            onClick={handleGraduateReUpload}
                            disabled={
                              userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty("ug")
                            }
                          >
                            Reupload
                          </CButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* // Post Graduate */}

              <div className="education-details-lower-container-2">
                <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                  <p className="bold-text">Post Graduate</p>
                </div>
                <div className="input-file-education-details">
                  <div style={{ display: "flex" }}>
                    <div>
                      <CFormInput
                        className="borderchange"
                        name="postGraduate"
                        onChange={handlePostGraduateDocsChange}
                        type="file"
                        id="formFile"
                        accept="image/*,application/pdf"
                        text={
                          <>
                            Only PNG, JPG, and PDF files are allowed.
                            <br />
                            {userData.status === "ask_for_resubmit" &&
                              correctionData?.hasOwnProperty("pg") && (
                                <span style={{ color: "red" }}>
                                  {correctionData?.pg?.remark}
                                </span>
                              )}
                          </>
                        }
                        disabled={
                          isUploaded?.pg ||
                          userDocuments?.pg ||
                          (userData.status === "ask_for_resubmit" &&
                            !correctionData?.hasOwnProperty("pg"))
                        }
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <button
                          className="buttonHeight"
                          onClick={handlepostGraduateFileUpload}
                          disabled={isDisabledButton?.pg}
                        >
                          {isUploaded?.pg || userDocuments?.pg
                            ? "Uploaded"
                            : isLoadingUpload?.pg
                              ? "Uploading..."
                              : "Upload"}
                        </button>
                      </div>
                      {userDocuments?.pg && (
                        <div>
                          <CButton
                            className="viewButton"
                            onClick={() =>
                              handleViewClick(`${userDocuments.pgDocument}`)
                            }
                            color="success"
                            variant="outline"
                          >
                            View
                          </CButton>
                        </div>
                      )}

                      {userDocuments?.pg && (
                        <div>
                          <CButton
                            color="success"
                            variant="outline"
                            onClick={handlePgReUpload}
                            disabled={
                              userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty("pg")
                            }
                          >
                            Reupload
                          </CButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* // PHD Document */}
              <div className="education-details-lower-container-2">
                <div style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                  <p className="bold-text">PhD</p>
                </div>
                <div className="input-file-education-details">
                  <div style={{ display: "flex" }}>
                    <div>
                      <CFormInput
                        className="borderchange"
                        name="phd"
                        onChange={handlePhdDocsChange}
                        type="file"
                        id="formFile"
                        text={
                          <>
                            Only PNG, JPG, and PDF files are allowed.
                            <br />
                            {userData.status === "ask_for_resubmit" &&
                              correctionData?.hasOwnProperty("phd") && (
                                <span style={{ color: "red" }}>
                                  {correctionData?.phd?.remark}
                                </span>
                              )}
                          </>
                        }
                        disabled={
                          isUploaded?.phd ||
                          userDocuments?.phd ||
                          (userData.status === "ask_for_resubmit" &&
                            !correctionData?.hasOwnProperty("phd"))
                        }
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <button
                          className="buttonHeight"
                          onClick={handlePhdFileUpload}
                          disabled={isDisabledButton?.phd}
                        >
                          {isUploaded?.phd || userDocuments?.phd
                            ? "Uploaded"
                            : isLoadingUpload?.phd
                              ? "Uploading..."
                              : "Upload"}
                        </button>
                      </div>
                      {userDocuments?.phd && (
                        <div>
                          <CButton
                            className="viewButton"
                            onClick={() =>
                              handleViewClick(`${userDocuments.phdDocument}`)
                            }
                            color="success"
                            variant="outline"
                          >
                            View
                          </CButton>
                        </div>
                      )}

                      {userDocuments?.phd && (
                        <div>
                          <CButton
                            color="success"
                            variant="outline"
                            onClick={handlePhdReUpload}
                            disabled={
                              userData.status === "ask_for_resubmit" &&
                              !correctionData?.hasOwnProperty("phd")
                            }
                          >
                            Reupload
                          </CButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
}

export default EducationDetails;
