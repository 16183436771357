import "./styles.scss";
import { useContext, useEffect, useState } from "react";

import { CFormSelect } from "@coreui/react";
import Swal from "sweetalert2";
import DataContext from "../../../../helpers/UserDataContext";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
import {
  getAllKitSize,
  getAllSports,
} from "../../../apiServices/sportDetailsApi";
import { SportsContext } from "../../../../Context/SportsContext";
function SportDetails() {
  const { handleNext, userData, setUserData, correctionData } = useContext(
    VerticalStepperContext
  );

  // const [kitSizeList ] = useState([]);
  // const [sportsList , setSportsList ] = useState([]);
  const { kitSizeList, fetchKitSizes, sportsList, fetchSportsList } =
    useContext(SportsContext);
  useEffect(() => {
    fetchKitSizes();
  }, []);
  useEffect(() => {
    fetchSportsList();
  }, []);

  // console.log(
  //   "disable for kitsize",
  //   correctionData,
  //   !correctionData?.hasOwnProperty("kit_size")
  // );
  function handleFormDetails(e) {
    console.log(e.target.value, "sport-details")
    if (e?.target?.name == "kit_size") {
      setUserData((prev) => {
        return {
          ...prev,
          [e.target.name]: Number(e.target.value) || e.target.value,
        };
      });
    }

    if (e?.target?.name === "sport_id") {
      const selectedSportId = e?.target?.value;
      
      // Assuming `sportsList` is available in the scope
      const selectedSport = sportsList.find((sport) => sport.id === parseInt(selectedSportId));
    
      if (selectedSport) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          user = {
            ...user,
            sport_id: selectedSport.id, // Save the ID as well
            sport_name: selectedSport.sport_name, // Save the name from the API response
          };
          localStorage.setItem("user", JSON.stringify(user));
        }
      }
    }
    

    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    // console.log(userData);
  }

  return (
    <div className="Sport-details-container">
      <div className="Sport-details-container">
        <p className="Sport-details-header">Sport Details</p>
      </div>

      <div className="Sport-details">
        <div className="sport-details-1">
          <label className="sport-label">Sport</label>
          <CFormSelect
            onChange={handleFormDetails}
            name="sport_id"
            value={userData?.sport_id}
            aria-label="Select other user Type"
            options={[
              "Select Sport",
              ...sportsList?.map((sport) => ({
                label: sport?.sport_name,
                value: sport?.id,
              })),
            ]}
            style={{ width: "90%" }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("sport_id")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.sport_id?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.sport_id.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.sport_id && (
                <>
                  <span style={{ color: "red" }}>Select Sport</span>
                </>
              ))
            }
          />
        </div>
        <div className="sport-details-2">
          <label className="sport-label">Athlete Level</label>
          <CFormSelect
            onChange={handleFormDetails}
            name="athlete_level"
            value={userData?.athlete_level}
            aria-label="Select other user Type"
            options={[
              "Select Athlete Level",
              { label: "International", value: "International" },
              { label: "National", value: "National" },
              { label: "State", value: "State" },
              { label: "District", value: "District" },
            ]}
            style={{ width: "90%" }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("athlete_level")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.athlete_level?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.athlete_level.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.athlete_level && (
                <>
                  <span style={{ color: "red" }}>Select Athlete Level</span>
                </>
              ))
            }
          />
        </div>
        <div className="sport-details-3">
          <label className="sport-label">Kit Size</label>
          <CFormSelect
            onChange={handleFormDetails}
            name="kit_size"
            value={userData?.kit_size}
            aria-label="Select other user Type"
            options={[
              "Select Kit Size",
              ...kitSizeList?.map((kit) => ({
                label: kit?.size_name,
                value: Number(kit?.size_name) || kit?.size_name,
              })),
            ]}
            style={{ width: "90%" }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("kit_size")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.kit_size?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.kit_size.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.kit_size && (
                <>
                  <span style={{ color: "red" }}>Select Kit Size</span>
                </>
              ))
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SportDetails;
